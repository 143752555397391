import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import validator from "validator";
import RFCValidator from "../../../Lib/Funciones Generales/ValidarRFC";

const MySwal = withReactContent(Swal);

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `proveedores/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpciones = async (query) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `proveedores/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoProveedor = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `proveedores/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este proveedor",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Values = {
          id: String(data.ID),
          activo: String(data.ACTIVO),
          nombre_comercial: String(data.NOMBRE_COMERCIAL),
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO),
          rfc: String(data.RFC),
          razon_social: String(data.RAZON_SOCIAL),
          domicilio: String(data.DOMICILIO),
          id_municipio: String(data.ID_MUNICIPIO),
          id_estado: String(data.ID_ESTADO),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;
  if (!Values.nombre_comercial || Values.nombre_comercial == "") {
    Errores.nombre_comercial = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }
  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (Values.rfc != "") {
    if (!RFCValidator(Values.rfc)) {
      Errores.rfc = true;
      setErrores(Errores);
      error = true;
    }
  }

  // if(Values.razon_social != "")
  // if (!Values.razon_social || Values.razon_social == "") {
  //   Errores.razon_social = true;
  //   setErrores(Errores);
  //   error = true;
  // }

  // console.log(Errores)

  if (!Values.id_estado || Values.id_estado == "" || Values.id_estado == "0") {
    // Errores.id_estado = true;
    //setErrores(Errores);
    //error = true;
  }

  if (
    !Values.id_municipio ||
    Values.id_municipio == "" ||
    Values.id_municipio == "0"
  ) {
    // Errores.id_municipio = true;
    // setErrores(Errores);
    // error = true;
  }

  Values.id_estado = String(Values.id_estado);
  Values.id_municipio = String(Values.id_municipio);

  if (!error) {
    let data = [];
    const body = Values;
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "Proveedores/Insertar"
        : "Proveedores/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `proveedores/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `proveedores/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
