export function GetDate(dias = 0) {
  var fecha = new Date();
  fecha.setDate(fecha.getDate() + dias);
  const year = fecha.getFullYear();
  const month = fecha.getMonth() + 1;
  const day = fecha.getDate();

  return year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
}
export function ConvertTime(fecha) {
  if (typeof fecha == "object" && fecha) {
    let fechaFormateada = "";
    const hh = fecha["$H"];
    const mm = fecha["$m"];
    fechaFormateada =
      (hh < 10 ? "0" + hh : hh) + ":" + (mm < 10 ? "0" + mm : mm) + ":00";
    return fechaFormateada;
  } else {
    return fecha;
  }
}

export function ConvertDate(fecha) {
  if (typeof fecha == "object" && fecha) {
    let fechaFormateada = "";
    let yyyy = fecha["$y"];
    let mm = fecha["$M"] + 1;
    let dd = fecha["$D"];
    fechaFormateada =
      yyyy + "-" + (mm < 10 ? "0" + mm : mm) + "-" + (dd < 10 ? "0" + dd : dd);
    return fechaFormateada;
  } else {
    return fecha;
  }
}


export const isValidDate = (d) => {
  let valido = d instanceof Date && !isNaN(d);
  if (!valido) {
    if (typeof (d) == "string") {
      valido = d.includes("-")
    }
  }
  return valido
}