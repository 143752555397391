import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import NotificacionMenu from "./../assets/NotificacionMenu.svg";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Badge from '@mui/material/Badge';

 const MenuNotificaciones = ({Values, IsLoading})=> {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}  className="me-3">
        <Tooltip title="Notificaciones">
        {
          Values.pass_default? 
        <IconButton    
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
        
          aria-controls={open ? 'account-notificaciones' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          > 
          <>
           <Badge variant="dot" color="error">   
              <img  src={NotificacionMenu} />
              </Badge> </>
        </IconButton>
        :
      
          <IconButton     
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            className="me-3"
            aria-controls={open ? 'account-notificaciones' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            >
                <img  src={NotificacionMenu} />
          </IconButton>
       
 }
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-notificaciones"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {
          Values.pass_default? 
          <Link to="perfil/update" style={{ textDecoration: "none", color: "black"}} >
          <MenuItem>
          <ListItemIcon>
            <GppMaybeIcon className='text-danger' fontSize="small" />
          </ListItemIcon>
          <ListItemText>Es necesario cambiar tu contraseña</ListItemText> 
        </MenuItem>
        </Link>
        :
         <MenuItem>
          Sin notificaciones...
        </MenuItem>
        }
       
      </Menu>
    </React.Fragment>
  );
}

export default   MenuNotificaciones