import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoPerfil } from "../Funciones/Perfiles";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getLocalStorage } from "./../../../Context/Storage";
const MySwal = withReactContent(Swal);

const Perfil = () => {
  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img });
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
    telefono: "",
    contrasena_actual: "",
    nueva_contrasena: "",
    repetir_contrasena: "",
    img: AccEditarImagen,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    apellido_paterno: false,
    apellido_materno: false,
    correo: false,
    telefono: false,
    contrasena_actual: false,
    nueva_contrasena: false,
    repetir_contrasena: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  useEffect(() => {
    console.log(navigate.length);
    document.title = "Perfil";
    setIsLoading(true);
    getInfoPerfil(AccEditarImagen)
      .then((resp) => {
        setValues(resp);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, []);

  const GuardarCliente = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            console.log("I was closed by the timer");
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Regresar
      </Button>

      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">Mi perfil</h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col
                sm={12}
                md={12}
                lg={4}
                className="d-flex flex-wrap align-items-center justify-content-center my-auto"
              >
                <Avatar
                  className="shadow"
                  sx={{ width: 120, height: 120 }}
                  src={img_url}
                />
                <strong
                  className="w-100 text-center px-0 pt-3"
                  style={{ color: "#3ABE88", cursor: "pointer" }}
                  onClick={handleOpenModal}
                >
                  Editar foto
                </strong>
              </Col>
              <Col sm={12} md={12} lg={4}>
                <Stack
                  className="py-3"
                  spacing={2}
                  direction={{ xs: "column", sm: "row" }}
                  style={{}}
                  sm={12}
                  md={12}
                >
                  <Col sm={12} md={12} className="p-3">
                    <TextField
                      fullWidth
                      name="nombre"
                      label="Nombre"
                      variant="outlined"
                      value={Values.nombre}
                      error={Errores.nombre}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={12} className="p-3">
                    <TextField
                      fullWidth
                      name="apellido_paterno"
                      label="Apellido paterno"
                      variant="outlined"
                      value={Values.apellido_paterno}
                      error={Errores.apellido_paterno}
                      onChange={handlInputChange}
                    />
                  </Col>
                </Stack>

                <Stack
                  className="py-3"
                  spacing={2}
                  direction={{ xs: "column", sm: "row" }}
                  style={{}}
                  sm={12}
                  md={12}
                >
                  <Col sm={12} md={12} className="p-3">
                    <TextField
                      fullWidth
                      name="apellido_materno"
                      label="Apellido materno"
                      variant="outlined"
                      value={Values.apellido_materno}
                      onChange={handlInputChange}
                      error={Errores.apellido_materno}
                    />
                  </Col>
                  <Col sm={12} md={12} className="p-3">
                    <TextField
                      fullWidth
                      name="correo"
                      label="Correo electrónico"
                      variant="outlined"
                      helperText={Errores.correo ? "Correo incorrecto" : ""}
                      value={Values.correo}
                      onChange={handlInputChange}
                    />
                  </Col>
                </Stack>
                <Stack
                  className="py-3"
                  spacing={2}
                  direction={{ xs: "column", sm: "row" }}
                  style={{}}
                  sm={12}
                  md={12}
                >
                  <Col sm={12} md={12} className="p-3">
                    <TextField
                      fullWidth
                      name="telefono"
                      label="Teléfono"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      helperText={Errores.telefono ? "Teléfono inválido" : ""}
                      value={Values.telefono == 0 ? "" : Values.telefono}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={12} className="p-3 d-flex">
                    <Link
                      className="m-auto"
                      to={"update/"}
                      style={{ textDecorationLine: "none" }}
                    >
                      <strong
                        className="w-100 text-center px-0 pt-3"
                        style={{ color: "#3ABE88", cursor: "pointer" }}
                      >
                        Cambiar contraseña
                      </strong>
                    </Link>
                  </Col>
                </Stack>
              </Col>
             
            </Row>
          )}
          <Stack
            className="p-4 me-5 w-100"
            spacing={"20"}
            justifyContent="flex-end"
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarCliente()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100%" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                Actualizar datos
              </span>
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={
            getLocalStorage("tipo") == "EMPLEADO"
              ? IMG.TIPO_EMPLEADO
              : IMG.TIPO_PERFIL
          }
          Titulo="Cargar imagen de perfil"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Perfil;
