import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  Snackbar,
  Stack,
  TextField,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormGroup,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import TablaProductos from "./TablaProductos";

import ModalProducto from "../../Productos/Componentes/ModalProducto";
import { getInfoConcepto, guardarConcepto } from "../Funciones/Conceptos";
import "../../../Lib/Funciones Generales/Prototipos";
import Switch from "../../../Includes/Switch";

const MySwal = withReactContent(Swal);

const Concepto = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Lista, setLista] = useState([]);
  //Modal de productos
  const [openModalProductos, setOpenModalProductos] = React.useState(false);
  const handleOpenModalProductos = () => setOpenModalProductos(true);
  const handleCloseModalProductos = () => setOpenModalProductos(false);
  const [Values, setValues] = useState({
    id: id ?? "0",
    descipccion: "",
    tiempo_estimado_min: "",
    comision: "",
    boleancomision: false,
  });
  const [Errores, setErrores] = useState({
    descipccion: false,
    tiempo_estimado_min: false,
    productos: false,
    comision: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (checked, id) => {
    setValues({
      ...Values,
      boleancomision: checked,
      comision: Values.comision,
    });
  };

  const handlInputChange = ({ target }) => {
    let Value = target.value;
    if (target.name == "tiempo_estimado_min") {
      Value = Value.toNumber();
    }
    setValues({
      ...Values,
      [target.name]: Value,
    });
    setErrores({
      ...Errores,
      [target.name]: false,
    });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoConcepto(id)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setLista(resp.productos);
            setValues(resp.concepto);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const IngresarCarrito = (id, desk, costo, precio) => {
    //console.log("agregar prod");
    let siEncontrado = false;

    const arreglo = Lista.map(function (dato) {
      //console.log(dato);
      if (dato.ID_PRODUCTO == id) {
        dato.CANTIDAD += 1;
        siEncontrado = true;
      }
      return dato;
    });
    //console.log(arreglo);

    // Esta función esta bien pero marca un error en consola
    // if (!siEncontrado) {
    //   setLista(
    //     ...Lista.push({ ID_PRODUCTO: id, CANTIDAD: 1, DESCRIPCION: desk })
    //   );
    // }

    // Esta no muestra el error pero es lo mismo
    if (!siEncontrado) {
      setLista([...Lista, { ID_PRODUCTO: id, CANTIDAD: 1, DESCRIPCION: desk, COSTO_DE_COMPRA: costo, PRECIO_DE_VENTA: precio }]);
    }
  };

  const GuardarConcepto = () => {
    setIsGuardando(true);
    try {
      guardarConcepto(Values, Lista, Errores, setErrores)
        .then((data) => {
          //console.log(data);
          if (data.codigo == "200") {
            setIsGuardando(false);
            MySwal.fire({
              title: "Correcto",
              html: data.mensaje,
              icon: "success",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: false,
              timer: 1700,
            }).then((result) => {
              navigate(-1);
            });
          } else {
            setMensaje(data.mensaje);
            setOpen(true);
            setIsGuardando(false);
          }
        })
        .catch((data) => {
          //console.log("Data: ", data);
          console.log(data);
          alert(data.mensaje);
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Conceptos
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar concepto" : "Nuevo concepto"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              {openModalProductos && (
                <ModalProducto
                  fnAgregar={IngresarCarrito}
                  mostrar={openModalProductos}
                  fnCerrar={handleCloseModalProductos}
                  Lista={Lista}
                />
              )}
              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  name="descipccion"
                  label="Descripción"
                  variant="outlined"
                  type={"text"}
                  helperText={
                    Errores.descipccion ? "Descripción no valida" : ""
                  }
                  value={Values.descipccion}
                  error={Errores.descipccion}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  name="tiempo_estimado_min"
                  label="Tiempo estimado en minutos"
                  variant="outlined"
                  type={"text"}
                  value={Values.tiempo_estimado_min}
                  error={Errores.tiempo_estimado_min}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} className="p-3">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Values.boleancomision}
                        name="requiere_comision"
                        onChange={onChange}
                        tituloActivo="No se paga comisión"
                        tituloInactivo="Se paga comisión"
                      />
                    }
                    label="Se paga comisión"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6} className="p-3">
                <FormControl disabled={!Values.boleancomision} fullWidth={true}>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Comision
                  </InputLabel>

                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">%</InputAdornment>
                    }
                    name="comision"
                    value={Values.comision}
                    error={Errores.comision}
                    label="Sueldo Horas Extra"
                    placeholder="Comisión"
                    onChange={handlInputChange}
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 3);
                    }}
                  />
                </FormControl>
              </Col>
              <Col m={12} md={12} justifyContent="flex-end">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="baseline"
                  style={{ marginTop: "60px" }}
                >
                  <Button
                    onClick={handleOpenModalProductos}
                    className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                    variant="contained"
                    al
                    style={{ width: "140px", height: "40px", align: "right" }}
                  >
                    <span className={"px-2"}>Agregar</span>
                    <AddIcon />
                  </Button>
                </Grid>
              </Col>
              <Col sm={12}>
                <TablaProductos
                  Lista={Lista ?? []}
                  IsLoading={IsLoading}
                  setLista={setLista}
                ></TablaProductos>
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={GuardarConcepto}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Concepto;
