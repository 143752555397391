import { stringify } from "query-string";
import validator from "validator";

import { getData, postdData, postUrl } from "../../../Context/backend";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getCategorias = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  const url = `categorias_productos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarCategoria = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  // if (!Values.descripcion || Values.descripcion == "") {
  //   Errores.descripcion = true;
  //   setErrores(Errores);
  //   error = true;
  // }

  if (!error) {
    let data = [];

    let queryParamsObj = {};
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "categorias_productos/Insertar"
        : "categorias_productos/Editar";

    const body = Values;
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarCategoria = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `categorias_productos/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfoCategoria = async (id) => {
  //console.log("Entro a la peticion getInfCategoria");
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `categorias_productos/Consultar?${stringify(queryParamsObj)}`;
  //console.log(url);
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta categoria",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        //console.log(data);
        let Value = {
          id: String(data.ID),
          nombre: String(data.NOMBRE),
          descripcion: String(data.DESCRIPCION),
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const Desactivar = async (id, checked) => {
  // console.log("Activar desactivar");
  // console.log(id, checked);
  //return "ok";
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `categorias_productos/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getOpciones = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `categorias_productos/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
