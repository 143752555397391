import React from "react";
import Chart from "react-apexcharts";
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';

const ChartBarSemana = ({ Totalsemanas }) => {

  if (Totalsemanas == undefined) {
    return (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
    </Box>
    )
}

let Grafica = [];
Totalsemanas = JSON.parse(Totalsemanas)
Totalsemanas = Totalsemanas ? Totalsemanas : []
 

if (Totalsemanas[3]) {
  if(Totalsemanas[3].SEMANA){
    Grafica.push({x:  "hace tres semana", y: Totalsemanas[3].CANTIDAD, fillColor:'#100D1C'})
  } 
}

if (Totalsemanas[2]) {
  if(Totalsemanas[2].SEMANA){
    Grafica.push({x:  "hace dos semana", y: Totalsemanas[2].CANTIDAD, fillColor:'#FBB303'})
  } 
}


if (Totalsemanas[1]) {
  if(Totalsemanas[1].SEMANA){
    Grafica.push({x:  "hace una semana", y: Totalsemanas[1].CANTIDAD, fillColor:'#65748B'})
  } 
}

if (Totalsemanas[0]) {
  if(Totalsemanas[0].SEMANA){
    Grafica.push({x:  "Esta semana", y: Totalsemanas[0].CANTIDAD, fillColor:'#FE8D27'})
  } 
}
 
 
  let options = {
    chart: {
      type: "bar",
    },
    series: [
      {
        name: "Eventos",
        data: Grafica, 
      },
    ],
    fill: {
      colors: ['#100D1C','#FE8D27','#65748B','#FBB303',  ]
    }
  };
  
  return (
    <>
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={300}
      />
    </>
  );
};
export default ChartBarSemana;
