 import {  Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import Empleado from "./Componentes/Empleado";
import RepEmpleadoss from "./Componentes/RepEmpleados";
 
 const routerEmpleados =()=> {
  return (
    <> 
     <Routes>     
        <Route index element={<RepEmpleadoss/>} /> 
        <Route path="/add" element={<Empleado/>} /> 
        <Route path="/edit/:id" element={<Empleado />} /> 
        <Route path="*" element={<Error404 />} />
    </Routes>
    </> 
  );
}

export default  routerEmpleados