import { getData, postdData } from "./../../../Context/backend";
import validator from "validator";
import { setLocalStorageJWT } from "../../../Context/Storage";

///////////////////
/* FUNCIONES GET */
///////////////////

export const getInfoPerfil = async (imgDefault, setTok = false) => {
  let data = [];
  const url = `perfil/Consultar?nwt=${setTok}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];

      let Values = {
        id: String(data.ID),
        pass_default: String(data.DEFAULT_PASS) == "0" ? false : true,
        nombre: String(data.NOMBRE),
        apellido_paterno: String(data.APELLIDO_PATERNO),
        apellido_materno: String(data.APELLIDO_MATERNO),
        correo: String(data.CORREO),
        telefono: String(data.TELEFONO),
        img: String(data.IMG) == "" ? imgDefault : String(data.IMG),
      };
      //   alert(Values.pass_default)
      if (setTok) {
        if (res.data.respuesta.setToken.confirm) {
          setLocalStorageJWT(res.data.respuesta.setToken.token);
        }
      }
      resolve(Values);
    } else {
      reject(res);
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.apellido_paterno || Values.apellido_paterno == "") {
    Errores.apellido_paterno = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.apellido_materno || Values.apellido_materno == "") {
    Errores.apellido_materno = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }
  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!error) {
    if (Values.img.includes("base64")) {
      Values.img = Values.img.split("base64,")[1];
    }

    let data = [];
    const body = Values;
    console.log(body);
    const url = "perfil/EditarDatos";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const actualizarContrasena = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.contrasena_actual || Values.contrasena_actual == "") {
    Errores.contrasena_actual = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!Values.nueva_contrasena || Values.nueva_contrasena == "") {
      Errores.nueva_contrasena = true;
      setErrores(Errores);
      error = true;
    } else {
      if (Values.repetir_contrasena != Values.nueva_contrasena) {
        Errores.repetir_contrasena = true;
        setErrores(Errores);
        error = true;
      }
    }
  }

  if (!error) {
    let data = [];
    const body = Values;
    console.log(body);
    const url = "perfil/CambiarPass";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        setLocalStorageJWT(data.respuesta.nwToken);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
