import { stringify } from "query-string";
import { getData, postUrl } from "../../../../../Context/backend";


export const crearConcepto = () => {
  return {
    ID: 0,
    ID_EVENTO: 0,
    ID_CONCEPTO: 0,
    CANTIDAD: 1,
    DESCRIPCION: '',
    TIEMPO_DE_PRODUCCION: 0,
    PERSONAS_INVOLUCRADAS: 0,
    COSTO_DE_COMPRA: 0,
    PRECIO_DE_VENTA: 0,
    COMISION: 0,
    PRODUCTOS: []
  }
}

export const getListaTemplateConcepto = () => {
  const newConcepto = crearConcepto()
  return [newConcepto];
}



export const getLista = async (evento) => {
  let data = [];
  let queryParamsObj = {
    evento: evento
  };
  const url = `eventos/ListarConceptos?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getProductos = async (id_rel_concepto, id_evento, id_concepto) => {
  let data = [];
  let queryParamsObj = {
    "id_rel_concepto": id_rel_concepto,
    "id_evento": id_evento,
    "id_concepto": id_concepto
  }
  const url = `eventos/listarProductosConceptos?${stringify(queryParamsObj)}`
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
}


export const Eliminar = async (id, id_evento) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      id_evento: id_evento
    };
    const url = `eventos/EliminarConcepto?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};


export const EliminarProducto = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `eventos/EliminarProductoConcepto?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const validarConceptos = (Conceptos, fnCallback) => {
  let error = false
  Conceptos.map(concepto => {
    if (concepto.CANTIDAD == "" || concepto.CANTIDAD == "0" || concepto.CANTIDAD == 0) {
      error = true;
      concepto.CANTIDAD_ERROR = true
      concepto.ERROR = true
    }

    if (concepto.DESCRIPCION.trim() == "") {
      error = true;
      concepto.DESCRIPCION_ERROR = true
      concepto.ERROR = true
    }

    if (concepto.PERSONAS_INVOLUCRADAS == "" || concepto.PERSONAS_INVOLUCRADAS == "0" || concepto.PERSONAS_INVOLUCRADAS == 0) {
      error = true;
      concepto.PERSONAS_INVOLUCRADAS_ERROR = true
      concepto.ERROR = true
    }

    if (concepto.TIEMPO_DE_PRODUCCION == "" || concepto.TIEMPO_DE_PRODUCCION == "0" || concepto.TIEMPO_DE_PRODUCCION == 0) {
      error = true;
      concepto.TIEMPO_DE_PRODUCCION_ERROR = true
      concepto.ERROR = true
    }

    if (concepto.COSTO_DE_COMPRA.toString().trim() == "") {
      error = true;
      concepto.COSTO_DE_COMPRA_ERROR = true
      concepto.ERROR = true
    }

    if (concepto.PRECIO_DE_VENTA.toString().trim() == "") {
      // error = true;
      // concepto.PRECIO_DE_VENTA_ERROR = true
      // concepto.ERROR = true
      concepto.PRECIO_DE_VENTA = 0
    }


    if (concepto.COMISION.toString().trim() == "") {
      error = true;
      concepto.COMISION_ERROR = true
      concepto.ERROR = true
    }



    return concepto
  })

  fnCallback(error)
}