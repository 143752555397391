import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DashboardLateral from "./../assets/DashboardLateral.svg";
import SeriesLateral from "./../assets/SeriesLateral.svg";
import iconoEvento from "./../assets/IconoEventos.svg";
import SettingsIcon from '@mui/icons-material/Settings';
import logo from "./../assets/LogoHorizontal-2.svg";
import {  getLocalStorage} from "./../Context/Storage";


import { Link } from "react-router-dom";

export default function Sidebar({ openMenu }) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const [openConfig, setOpenConfig] = React.useState(false);
  const handleClickConfig = () => {
    setOpenConfig(!openConfig);
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 360 }}
      style={{
        backgroundColor: "#101727",
        height: "100%",
        color: "white",
        zIndex: 1,
        paddingTop: openMenu ? "10px" : "50px",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {openMenu ? (
        <center>
          <Link to="inicio/" style={{ textDecoration: "none" }}>
            <img
              className="mb-4 mt-3"
              src={logo}
              width={140}
              style={{ margin: "auto" }}
            />
          </Link>
        </center>
      ) : (
        <></>
      )}

      <Link
        to="inicio/"
        style={{ textDecoration: "none", color: "white" }}
        className="mb-1 mt-5"
      >
        <ListItemButton className="ps-2">
          <ListItemIcon>
            <img src={DashboardLateral} width={20} style={{ margin: "auto" }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </Link>
      <Link to="eventos/" style={{ textDecoration: "none", color: "white" }}>
        <ListItemButton className="ps-2">
          <ListItemIcon>
            <img
              className="mb-1"
              src={iconoEvento}
              width={20}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          <ListItemText primary="Eventos" />
        </ListItemButton>
      </Link>
 {

getLocalStorage("tipo") == "ADMIN" ?  
<>
      <ListItemButton className="ps-2" onClick={handleClick}>
        <ListItemIcon>
          {" "}
          <img
            className="mb-1"
            src={SeriesLateral}
            width={20}
            style={{ margin: "auto" }}
          />
        </ListItemIcon>
        <ListItemText primary="Catálogos" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

 


      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="categorias/" style={{ textDecoration: "none", color: "white" }}>
            <ListItemButton className="ps-3">
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Categorías" />
            </ListItemButton>
          </Link>
          <Link
            to="clientes/"
            style={{ textDecoration: "none", color: "white" }}
          >
            <ListItemButton className="ps-3">
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Clientes" />
            </ListItemButton>
          </Link>
          {/* <Link
            to="conceptos/"
            style={{ textDecoration: "none", color: "white" }}
          >
            <ListItemButton className="ps-3">
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Conceptos" />
            </ListItemButton>
          </Link> */}
          <Link
            to="empleados/"
            style={{ textDecoration: "none", color: "white" }}
          >
            <ListItemButton className="ps-3">
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Empleados" />
            </ListItemButton>
          </Link>
          <Link
            to="locaciones/"
            style={{ textDecoration: "none", color: "white" }}
          >
            <ListItemButton className="ps-3">
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Locaciones" />
            </ListItemButton>
          </Link>
          <Link
            to="Planners/"
            style={{ textDecoration: "none", color: "white" }}
          >
            <ListItemButton className="ps-3">
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Planners" />
            </ListItemButton>
          </Link>
          <Link
            to="productos/"
            style={{ textDecoration: "none", color: "white" }}
          >
            <ListItemButton className="ps-3">
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Productos" />
            </ListItemButton>
          </Link>
          <Link
            to="Proveedores/"
            style={{ textDecoration: "none", color: "white" }}
          >
            <ListItemButton className="ps-3">
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Proveedores" />
            </ListItemButton>
          </Link>
          <Link to="puestos/" style={{ textDecoration: "none", color: "white" }}>
            <ListItemButton className="ps-3">
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Puestos" />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
    



      {/******************************** CONFIGURACIONES ********************************/}
      <ListItemButton className="ps-2" onClick={handleClickConfig}>
        <ListItemIcon>
          {" "}
          <SettingsIcon width="20" className="m-auto" style={{color: "#FFF"}} />
        </ListItemIcon>
        <ListItemText primary="Configuraciones" />
        {openConfig ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openConfig} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="configCorreos/" style={{ textDecoration: "none", color: "white" }}>
            <ListItemButton className="ps-3">
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Correos" />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>

      </>
      :<></>
       }

    </List>
  );
}
