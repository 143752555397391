import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
import validator from "validator";

export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `conceptos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpciones = async (query) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `conceptos/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `conceptos/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfoConcepto = async (id) => {
  let dataConcep = [];
  let dataProds = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `conceptos/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      dataConcep = JSON.parse(res.data.respuesta.data.concepto);
      //console.log(dataConcep);
      if (dataConcep.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este concepto",
        });
      } else {
        dataConcep = JSON.parse(res.data.respuesta.data.concepto);
        dataProds = JSON.parse(res.data.respuesta.data.productos);
        let Values = {
          productos: dataProds,
          concepto: {
            id: String(dataConcep[0].ID),
            descipccion: String(dataConcep[0].DESCRIPCCION),
            tiempo_estimado_min: String(dataConcep[0].TIEMPO_ESTIM_MIN),
            comision: String(dataConcep[0].COMICION),
            boleancomision: true,
          },
        };
        //console.log(Values);
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const guardarConcepto = async (Values, Lista, Errores, setErrores) => {
  let error = false;
  let errorCantidades = false;

  if (Lista) {
    if (Array.isArray(Lista)) {
      Lista.map((producto) => {
        if (producto.CANTIDAD < 0) {
          producto.ERROR = true;
          error = true;
          errorCantidades = true;
        }
      });
    }
  }


  if (
    validator.isEmpty(Values.descipccion) ||
    !Values.descipccion ||
    Values.descipccion == ""
  ) {
    Errores.descipccion = true;
    setErrores(Errores);
    error = true;
  }
  if
    (
    !validator.isNumeric(Values.tiempo_estimado_min) ||
    Values.tiempo_estimado_min == null
  ) {
    Errores.tiempo_estimado_min = true;
    setErrores(Errores);
    error = true;
  }

  if
    (
    !validator.isNumeric(Values.comision) ||
    Values.comision == null
  ) {
    if (Values.boleancomision) {
      Errores.comision = true;
      setErrores(Errores);
      error = true;
    }
    else {
      Values.comision = 0;
    }

  }
  else {
    Values.comision = parseInt(Values.comision);
  }



  if (Values.tiempo_estimado_min < 0) {
    Errores.tiempo_estimado_min = true;
    setErrores(Errores);
    error = true;

    return new Promise((resolve, reject) => {
      resolve({
        mensaje: "El campo tiempo estimado en minutos no puede ser menor a 0",
      });
    });
  }

  if (Values.comision > 100 || Values.comision < 0) {
    Errores.comision = true;
    setErrores(Errores);
    error = true;
  }

  if (Lista.length == 0) {
    error = true;
  }




  if (!error) {


    const ProductosModificados = []
    Lista.map(x => {
      if (x.ACTUALIZAR == true) {
        ProductosModificados.push({
          ID: x.ID_PRODUCTO,
          PRECIO_DE_VENTA: parseFloat(x.PRECIO_DE_VENTA),
          COSTO_DE_COMPRA: parseFloat(x.COSTO_DE_COMPRA)
        })
      }
    })

    // console.log("PRODUCTOS ACTUALIZADOS: ", ProductosModificados)

    // return;

    let data = [];

    let queryParamsObj = {};
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "conceptos/Insertar"
        : "conceptos/Editar";

    const body = {
      id: Values.id,
      descripccion: Values.descipccion,
      tiempO_ESTIM_MIN: Values.tiempo_estimado_min,
      productos: Lista,
      ProductosModificados: ProductosModificados,
      comision: Values.comision,
    };
    //console.log(body);
    const res = await postdData(url, body);
    /*const res = {
      data: {
        codigo: "200",
      },
    };*/

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    if (validator.isEmpty(Values.descipccion)) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Completa todos los campos requeridos" });
      });
    }
    if (!validator.isNumeric(Values.tiempo_estimado_min)) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Ingresa un numero valido" });
      });
    }
    if (Lista.length == 0) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Agrega al menos un producto" });
      });
    }
    if (errorCantidades) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Las cantidades no son validas" });
      });


    }
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

/*export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `conceptos/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};*/
