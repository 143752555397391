import { Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState, useTransition } from "react";

import Chart from "react-apexcharts";
import { getTiempoFromMinutos, getTiempoFromMinutosToHoras} from "./../../../Lib/Funciones Generales/Generales";

const RadilBar = ({ porcentaje, tiempo, label, color = "#3abe88" }) => {
  /*const [tim, setTim] = useTransition(0);
  let time = 0;
  //console.log("tiempo", tiempo);
  function timer() {
    var n = 0;
    return setInterval(() => {
      //Devolver el intervalId
      //if (n > images.length - 1) {
      //  n = 0;
      //}
      n++;
      time = n;
      setTim((n) => n + 1);
      console.log("letTime: ", time);
      console.log("counter: ", n);
      console.log("state tim: ", tim);
      tiempo = n;
    }, 1000);
  }

  useEffect(() => {
    const timerId = timer();
    return () => {
      //console.log(tim);
      clearInterval(timerId);
    };
  }, []);*/
  const timerRef = React.useRef();

  React.useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  const [remainingTime, setRemainingTime] = React.useState(1500);

  function timer() {
    setRemainingTime((remainingTime) => remainingTime - 1);
    //options.plotOptions.radialBar.dataLabels.value:seconds,
    //options.plotOptions.radialBar.dataLabels.value.formatter = seconds;
  }

  const startTimer = () => {
    clearInterval(timerRef.current); // clear any running interval
    setRemainingTime(1500); // reset state back to 25 minutes
    timerRef.current = setInterval(timer, 1000); // start/restart interval
  };
  const minute = String(Math.floor(remainingTime / 60)).padStart(2, 0);
  const seconds = String(remainingTime % 60).padStart(2, 0);

  useEffect(() => {
    startTimer();
  }, []);

  var options = {
    chart: {
      height: 250,
      type: "radialBar",
    },
    series: [porcentaje],
    colors: [color],
    plotOptions: {
      radialBar: {
        startAngle: -100,
        endAngle: 100,
        track: {
          background: " #C4C4C4FF",
          startAngle: -100,
          endAngle: 100,
        },
        dataLabels: {
          name: {
            offsetY: -20,
            show: true,
            fontSize: "10px",
            color: " #111",
          },
          value: { 
            fontSize: "16px",

            formatter: function (val) {
              //return seconds;
              return " " + getTiempoFromMinutosToHoras(tiempo) + "";
            },
            show: true,
          },
        },
      },
    },

    stroke: {
      lineCap: "butt",
    },
    labels: [label],
  };

  return (
    <>
      {/*<Typography>{seconds}</Typography>*/}
      <Chart
        options={options}
        series={options.series}
        type="radialBar"
        height={300}
      />
    </>
  );
};
export default RadilBar;
