class Lista {

    constructor() {
        this.oList = [];
        this.size = 0;
    }

    add(node) {
        this.size++;
        this.oList.push(node);
    }

    delete(id) {
        var oNode = this.search(id);
        if (oNode.exist) {
            this.size--;
            this.oList.splice(oNode.index, 1);
        }
        return oNode;
    }

    insert(oNode, index) {
        this.oList.splice(index, 0, oNode);
    }

    array() {
        var oArray = [];
        this.oList.forEach(function (oNode) {
            oArray.push(oNode.id);
            //alert(oNode.id)
        });
        return oArray;
    }

    replace(index, node) {
        this.oList[index] = node;
    }

    deleteIndex(index) {
        this.size--;
        var oNode = this.oList[index];
        this.oList.splice(index, 1);
        return oNode.exist;
    }

    search(id) {
        var index = 0, node = null, exist = false;
        this.oList.forEach(function (oNode, oIndex) {
            if (oNode.id == id) {
                node = oNode;
                index = oIndex;
                exist = true;
            }
        });
        return { index: index, node: node, exist: exist };
    }

    show(fn) {
        this.oList.forEach(function (oNode) {
            fn(oNode);
        });
    }

    addArray(oArray, fnReturn) {
        var clList = this;
        oArray.forEach(function (oNode) {
            if (!clList.search(oNode.id).exist) {
                clList.add(oNode);
                fnReturn(oNode.id);
            }
        });
    }

    getIndex(index) {
        return this.oList[index];
    }

    getNext() {
        var next = this.oList[0];
        this.deleteIndex(0);
        return next;
    }

}

export class Mapas {

    constructor() {
        this.init();
    }

    init() {
        this.map = null
        this.siIniciado = false;
        this.insertIndex = -1;
        this.iContadorMarkers = 0;
        this.iContadorLines = 0;
        this.iContadorPolygons = 0;
        this.ClsListaPuntos = new Lista();
        this.ClsListaPoligonos = new Lista();
        this.objLinea = null;
        this.PuntoGeografico = null;
        this.geocoder = new window.google.maps.Geocoder();

        window.google.maps.Polygon.prototype.getBounds = function () {
            let bounds = new window.google.maps.LatLngBounds();
            this.getPaths().forEach(p => {
                p.forEach(element => bounds.extend(element));
            });
            return bounds;
        }
    }



    Iniciar(infoMapa) {
        this.siIniciado = true;
        this.infoMapa = infoMapa;

        // console.log("MAPA CENTRADO")
        this.map = new window.google.maps.Map(document.getElementById(infoMapa.id), {
            center: { lat: parseFloat(infoMapa.lat), lng: parseFloat(infoMapa.lng) },
            zoom: infoMapa.zoom,
            mapId: infoMapa.styleId
        });

        // console.log(infoMapa.lat)
        // console.log(infoMapa)
        // if (isNaN(infoMapa.lat) || isNaN(infoMapa.lng) || !infoMapa.lat || !infoMapa.lng) {
        //      console.log("MAPA NORMAL")
        //     this.map = new window.google.maps.Map(document.getElementById(infoMapa.id), {
        //         center: { lat: 0, lng: 0 },
        //         zoom: infoMapa.zoom,
        //         mapId: infoMapa.styleId
        //     });
        // }
        // else {
        //     console.log("MAPA CENTRADO")
        //     this.map = new window.google.maps.Map(document.getElementById(infoMapa.id), {
        //         center: { lat: parseFloat(infoMapa.lat), lng: parseFloat(infoMapa.lng) },
        //         zoom: infoMapa.zoom,
        //         mapId: infoMapa.styleId
        //     });
        // }

        if (infoMapa.clickMap != undefined) {
            //eval(infoMapa.click)(' => luis ');
            this.map.addListener("click", (e) => {
                // eval(infoMapa.clickMap)({lat: e.latLng.lat(), lng: e.latLng.lng()});
                window.clickMap({ lat: e.latLng.lat(), lng: e.latLng.lng() })
            });
        }

    }


    /* PARAMETROS 
    *  	{
    *	  html: '<'h2> Hola mundo </h2>',
    *	  alto: 200,
    *	  ancho: 500
    *	}
    */
    InsertaMarkerWindow(infoMarker, infoWindow) {
        //alert(JSON.stringify(infoMarker));
        this.CrearMarkerWindow(infoMarker, infoWindow);
    }


    /* PARAMETROS 
    *  	{
    *	  	lat: 20.23423,
    *		lng: -87.23423423,
    *	}
    */
    InsertaMarker(infoMarker) {
        // console.log(infoMarker)
        this.CrearMarker(infoMarker);
    }


    /*
    * CREA EL MARKER Y LO AGREGA AL MAPA
    */
    CrearMarker(infoMarker, fnEjecutarDobleClick, iid = -1) {
        var id = this.iContadorMarkers;
        id = iid != -1 ? iid : id;

        var draggable = false;
        if (infoMarker.draggable != undefined) {
            draggable = infoMarker.draggable;
        }
        var infoNuevoMarker = {
            id: id,
            infoMarker: infoMarker,
            infoMarkerGoogle: new window.google.maps.Marker({
                title: infoMarker.title,
                icon: infoMarker.ico,
                position: { lat: parseFloat(infoMarker.lat), lng: parseFloat(infoMarker.lng) },
                draggable: draggable
            })
        };
        if (!infoMarker.unique) {
            infoNuevoMarker.infoMarkerGoogle.setMap(this.map);
            infoNuevoMarker.infoMarkerGoogle.addListener('dblclick', function () {
                if (fnEjecutarDobleClick != undefined) {
                    fnEjecutarDobleClick(id);
                }
            });
            this.iContadorMarkers++;
            if (this.insertIndex == -1) {
                this.ClsListaPuntos.add(infoNuevoMarker);
            }
            else {
                this.ClsListaPuntos.insert(infoNuevoMarker, this.insertIndex);
                if (this.insertIndex == this.ClsListaPuntos.size) {
                    this.insertIndex = -1;
                }
            }
        } else {
            if (this.PuntoGeografico != null) {
                this.PuntoGeografico.setMap(null);
            };
            this.PuntoGeografico = infoNuevoMarker.infoMarkerGoogle;
            infoNuevoMarker.infoMarkerGoogle.setMap(this.map);
            this.map.setCenter({ lat: infoMarker.lat, lng: infoMarker.lng });
        }

        if (infoMarker.zoom == undefined) {
            //window.map.setZoom(16);	
        }
        else {
            //window.map.setZoom(infoMarker.zoom);	
        }

        if (infoMarker.draggable != undefined) {
            window.google.maps.event.addListener(infoNuevoMarker.infoMarkerGoogle, 'dragend', function (event) {
                // eval(window.ClsMapa.infoMapa.draggableMarker)({lat:this.position.lat(), lng: this.position.lng()});
                window.draggableMarker({ lat: this.position.lat(), lng: this.position.lng() })
            });
        }
    }


    /*
    * CREA EL MARKER INFORMATIVO Y LO AGREGA AL MAPA
    */
    // CrearMarkerWindow(infoMarker, infoWindow, fnEjecutarDobleClick) {
    //     window.ClsMapa.CrearMarker(infoMarker, fnEjecutarDobleClick, infoWindow.id);
    //     var infoNuevoMarker = window.ClsMapa.ClsListaPuntos.oList[window.ClsMapa.ClsListaPuntos.size - 1].infoMarkerGoogle;
    //     var infoNuevoWindow = new window.google.maps.InfoWindow({
    //         size: new window.google.maps.Size(infoWindow.ancho, infoWindow.alto),
    //         content: infoWindow.html
    //     });
    //     infoNuevoMarker.addListener('click', function () {
    //         if (window.infoWindow != undefined) {
    //             window.infoWindow.close();
    //         }
    //         infoNuevoWindow.open(window.map, infoNuevoMarker);
    //         setTimeout(function () {
    //             $('#conductor_' + infoWindow.id).parent().parent().parent().css("padding", '0px');
    //             $('#conductor_' + infoWindow.id).parent().parent().attr("style", "overflow: none");
    //         }, 100);
    //         window.infoWindow = infoNuevoWindow;
    //         window.map.setCenter(infoNuevoMarker.getPosition());
    //     });
    // }


    /*
    * PARA INICIAR EL PROCESO DE DIBUJO
    */
    IniciarDibujoZona(infoMapa) {
        this.Iniciar(infoMapa);
        window.google.maps.event.addListener(window.map, 'click', function (position) {
            window.ClsMapa.CrearLineaZona({ lat: position.latLng.lat(), lng: position.latLng.lng() });
        });
    }


    /*
    * CREA LA LINEA TOMANDO COMO REFERENCIA EL PUNTO DONDE SE DIO CLIC EN EL MAPA
    */
    CrearLineaZona(infoMarker) {
        if (!this.BuscarPuntoEnZona(new window.google.maps.LatLng(infoMarker.lat, infoMarker.lng), window.ZONA_EMPRESA)) {
            this.CrearMarker(infoMarker, window.ClsMapa.EliminarLineaZona);
            this.DibujarLineasZona();
        } else {
            alert("Estas fuera de tu zona");
        }
    }


    /*
    * ELIMINA LA LINEA TOMANDO COMO REFERENCIA EL MARKER QUE SE DIO CLIC
    */
    EliminarLineaZona(id) {
        var info = window.ClsMapa.ClsListaPuntos.delete(id);
        info.node.infoMarkerGoogle.setMap(null);
        window.ClsMapa.DibujarLineasZona();
        window.ClsMapa.insertIndex = info.index;
    }


    /*
    * TRAZA LAS LINEAS TOMANDO COMO REFERENCIAS LOS PUNTOS GUARDADOS EN MEMORIA
    */
    // DibujarLineasZona() {
    //     var ListaPuntos = [];
    //     if (window.ClsMapa.objLinea != null) {
    //         window.ClsMapa.objLinea.setMap(null);
    //     }
    //     window.ClsMapa.ClsListaPuntos.show(function (infoPunto) {
    //         ListaPuntos.push(infoPunto.infoMarkerGoogle.getPosition());
    //     });
    //     var infoLineaGoogle = new window.google.maps.Polyline({
    //         path: ListaPuntos,
    //         geodesic: true,
    //         strokeColor: "#008ecc",
    //         strokeOpacity: 1.0,
    //         strokeWeight: 2,
    //     });
    //     infoLineawindow.google.setMap(window.map);
    //     window.ClsMapa.objLinea = infoLineaGoogle;
    // }


    /* PARAMETROS 
    *  	{
    *		titulo: 'CHAPALITA',
    *		viajes: 23, //MUESTRA LOS VIAJES PERO ES OPCIONAL
    *	  	coordenadas: 	{
    *							{las: 20.123123, lng: -87.234234234 }
    *							{las: 20.123123, lng: -87.234234234 }
    *							{las: 20.123123, lng: -87.234234234 }
    *						}
    *	}
    */
    // InsertarPoligono(infoPolygon) {
    //     var infoPolygonGoogle = this.CrearPoligono(infoPolygon.coordenadas);
    //     var bounds = new window.google.maps.LatLngBounds();
    //     infoPolygon.coordenadas.forEach(function (oCoordenada) {
    //         bounds.extend(oCoordenada);
    //         //console.log(oCoordenada)
    //     });
    //     var mapLabel = new MapLabel({
    //         text: infoPolygon.titulo,
    //         viajes: infoPolygon.viajes,
    //         position: bounds.getCenter(),
    //         map: map,
    //         fontSize: 16,
    //         align: 'center'
    //     });
    //     infoPolygonwindow.google.setMap(window.map);
    //     this.ClsListaPoligonos.add({ id: this.iContadorPolygons, infoPolygonGoogle: infoPolygonGoogle, data: infoPolygon.data });
    //     this.iContadorPolygons++;
    // }


    /*
    * CREA EL AREA DEL POLIGONO TOMANDO COMO REFERENCIA EL ARREGLO DE PUNTOS {lat, lng}
    */
    CrearPoligono(ListaPuntos) {
        return new window.google.maps.Polygon({
            path: ListaPuntos,
            strokeColor: 'rgb(0, 0, 0, 0.4)',
            fillColor: 'rgb(0, 0, 0, 0.4)',
            strokeWeight: 2,
        });
    }


    /*
    * CREA EL PERIMETRO DEL POLIGONO TOMANDO COMO REFERENCIA EL ARREGLO DE PUNTOS {lat, lng}
    */
    InsertarPuntosPoligono(ListaPuntos) {
        var bounds = new window.google.maps.LatLngBounds();
        ListaPuntos.forEach(function (infoPunto) {
            window.ClsMapa.CrearLineaZona(infoPunto);
            bounds.extend(infoPunto);
        });
        return bounds;
    }

    /*
    * VERIFICA SI EN LA FUNCION DE DIBUJO SE ENCUANTRA DENTRO DEL AREA DESIGANADA
    */
    BuscarPuntoEnZona(InfoPunto, InfoZona) {
        if (InfoZona != undefined) {
            return window.google.maps.geometry.poly.containsLocation(InfoPunto, InfoZona);
        }
        return false;
    }


    /*
    * OBTIENES EL ARREGLO DE PUNTOS DEL MAPA
    */
    ObtenerPuntos() {
        var listaPuntos = [];
        this.ClsListaPuntos.show(function (infoPunto) {
            listaPuntos.push(infoPunto.infoMarkerGoogle.getPosition());
        });

        if (listaPuntos.length != 0) {
            if (listaPuntos[0].lat() == listaPuntos[listaPuntos.length - 1].lat() && listaPuntos[0].lng() == listaPuntos[listaPuntos.length - 1].lng()) {
                listaPuntos.pop();
            }
        }
        return listaPuntos;
    }

    /*
    *
    */
    BuscarCoordenadasPorDomicilio(address, fnDevolverInfomacion) {
        this.geocoder.geocode({ 'address': address }, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                fnDevolverInfomacion(results, results[0].geometry.location.lat(), results[0].geometry.location.lng());
            } else {
                fnDevolverInfomacion(undefined, -1, -1);
            }
        });
    }

    /*
    *
    */
    BuscarDomicilioPorCoordenadas(lat, lng, fnDevolverInfomacion) {
        this.geocoder.geocode({ 'latLng': { lat: lat, lng: lng } }, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                fnDevolverInfomacion(results);
            } else {
                fnDevolverInfomacion(null)
            }
        });
    }

}


export const Pin = (lat, lng, draggable = false, unique = false, ico = null) => {
    return {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        draggable: draggable,
        ico: ico,
        unique: unique
    }

}






