import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { TextField } from "@mui/material";
import Loading from "../../../Includes/Loading";
import { format2decimales, getTiempoFromMinutos } from "../../../Lib/Funciones Generales/Generales";

function createData(id, name, cantidad, personas, tiempo, avence) {
  return { id, name, cantidad, personas, tiempo, avence };
}

const TableConceptos = ({ avances, IsLoading, setAvance, setAvances }) => {


  const handleSliderChange = (event, newValue, target) => {
    let TotalAvence = 0;
    let Cont = 0;
    const filteredLibraries = avances.filter((item) => {
      if (item.ID == event.target.name) 
      { 
        let Porce = 0;
        let Cant = 0; 
        Porce = newValue;
        Cant = (Porce * item.CANTIDAD) / 100; 
        if (item.MIN <= newValue) {
          item.PORCENTAJE = parseInt(Porce);
          item.CANT_AVANCE = Cant;
          item.CAMBIO =true;
        } 
      }
      // TotalAvence += item.PORCENTAJE;
      TotalAvence +=  (item.PORCENTAJE * item.POR_PROD)/100 ;
      Cont++;
      return item;
    });
    setAvance(parseFloat(TotalAvence));
    setAvances(filteredLibraries);
  };

  const handlInputChange = ({ target }) => {
    let Value = target.value;
    let TotalAvence = 0;
    let Cont = 0;
    const filteredLibraries = avances.filter((item) => {
    //   console.log(item);
      if (item.ID == target.name) {
        
        let Porce = 0;
        let Cant = 0;
        if (target.id == "por") {
          Porce = Value;
          Cant = (Porce * item.CANTIDAD) / 100;
        }
        if (target.id == "cant") {
          Cant = Value;
          Porce = (Cant * 100) / item.CANTIDAD;
        }
        console.log(Porce)
        if (item.MIN <= Porce && Porce<=100 ) {
          item.PORCENTAJE = parseInt(Porce);
          item.CANT_AVANCE = Cant;
          item.CAMBIO =true;
        }
      }


    // let avencePorcentaje =  (item.CANT_AVANCE * ((item.TIEMPO_TOTAL / item.TIEMPOS_TOTAL) * 100) / item.CANTIDAD).toFixed(2)

      TotalAvence +=  (item.PORCENTAJE * item.POR_PROD)/100 ;
      Cont++;
      return item;
    });
    setAvance(parseFloat(TotalAvence));
    setAvances(filteredLibraries);
  };
 
let TOTAL_CANTIDAD =0;
let TOTAL_PERSONAS =0;
let TOTAL_TIEMPO_PROD =0;
let TOTAL_TIEMPO_HOMBRE =0;


  // useEffect(()=>{ setValues(avances) },[avances]);

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Cantidad</TableCell>
            <TableCell>Concepto</TableCell>
            <TableCell align="left">Personas</TableCell>
            <TableCell align="left">Tiem. Prod</TableCell>
            <TableCell align="left">Tiempo Total (Hrs)</TableCell>
            <TableCell align="left">% Prod</TableCell>
            <TableCell align="left">Avances</TableCell>
            <TableCell align="left">  </TableCell>
            <TableCell align="left"> </TableCell>
            <TableCell align="left">%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {IsLoading ?
            <TableRow  >
              <TableCell colSpan={10}>
                <Loading />
              </TableCell>
            </TableRow>
            :
            avances.map((row) => (

              <TableRow
                key={row.ID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.CANTIDAD}
                </TableCell>
                <TableCell align="left">{row.DESCRIPCCION}</TableCell>

                <TableCell align="left">{row.PERSONAS_INVOLUCRADAS}</TableCell>
                <TableCell align="left">{getTiempoFromMinutos(parseInt(row.TIEMPO_DE_PRODUCCION))}</TableCell>
                <TableCell align="left">{row.TIEMPO_TOTAL.toFixed(2)}   </TableCell>
                <TableCell align="left">{(row.POR_PROD.toFixed(2))}%</TableCell>
                <TableCell align="left">
                  <Box>
                    <Grid container spacing={2} alignItems="center" fullwh>

                      <Grid item xs>
                        <Slider
                          alueLabelDisplay="true"
                          name={row.ID}
                          id={"por"}
                          min={0}
                          max={100}
                          value={typeof row.PORCENTAJE === "number" ? row.PORCENTAJE : 0}
                          onChange={handleSliderChange}
                          aria-labelledby="input-slider"
                        />
                      </Grid>

                    </Grid>
                  </Box>
                </TableCell>

                <TableCell align="left"  style={{width:"80px"}}>
                  <TextField
                   style={{width:"80px"}}
                    fullWidth
                    value={row.PORCENTAJE}
                    size="small"
                    name={row.ID}
                    id={"por"}
                    label="% de avance"
                    onChange={handlInputChange}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 100,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />

                </TableCell>
                <TableCell align="left"  style={{width:"80px"}}>
                  <TextField
                   style={{width:"80px"}}
                    fullWidth 
                    value={typeof row.CANT_AVANCE == "number" ? row.CANT_AVANCE.toFixed(2) : row.CANT_AVANCE}
                    size="small"
                    name={row.ID}
                    id={"cant"}
                    label="Avance (cantidad)"
                    onChange={handlInputChange}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: row.CANTIDAD,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />

                </TableCell>

                <TableCell align="left">{

                  (row.CANT_AVANCE * ((row.TIEMPO_TOTAL / row.TIEMPOS_TOTAL) * 100) / row.CANTIDAD).toFixed(2)
                }%</TableCell>

              </TableRow>
            )
            )
          }

 

{avances.length != 0
                  ? avances.map((concepto) => { 
                      {
                        TOTAL_CANTIDAD+=concepto.CANTIDAD;
                        TOTAL_PERSONAS+=concepto.PERSONAS_INVOLUCRADAS;
                        TOTAL_TIEMPO_PROD+=concepto.TIEMPO_DE_PRODUCCION; 
                        TOTAL_TIEMPO_HOMBRE +=   parseFloat(concepto.TIEMPO_TOTAL);
                      }
                    })
                  : ""}


<TableRow>
  <TableCell className="fw-bold">
    {TOTAL_CANTIDAD}
  </TableCell>
  <TableCell className="fw-bold">
  
  </TableCell>
  <TableCell className="fw-bold">
    {TOTAL_PERSONAS}
  </TableCell>
  <TableCell className="fw-bold">
    {getTiempoFromMinutos(parseInt(TOTAL_TIEMPO_PROD))}
  </TableCell>
  <TableCell className="fw-bold">
    {((TOTAL_TIEMPO_HOMBRE)).toFixed(2)} Horas
  </TableCell>
  
</TableRow>

        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableConceptos;
