import {
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { crearConcepto, Eliminar } from "../Funciones/Conceptos";

import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import AccEliminar from "./../../../../../assets/AccEliminar.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RepProductos from "./RepProductos";
import { useEffect } from "react";

const RepConceptos = ({
  tiempo,
  Conceptos, 
  SetConceptos,
  ProductosModificados,
  SetProductosModificados,
  Evento,
}) => {
  useEffect(() => {
    let Conceptos_ = Conceptos.filter((concepto) => {
      let PERSONAS_INVOLUCRADAS = parseInt(concepto.PERSONAS_INVOLUCRADAS);
      var TIEMPO_DE_PRODUCCION = parseInt(concepto.TIEMPO_DE_PRODUCCION) / 60;
      let CANTIDAD = parseInt(concepto["CANTIDAD"]);
      var TIEMPO_HOMBRE = CANTIDAD *  PERSONAS_INVOLUCRADAS * TIEMPO_DE_PRODUCCION;
      concepto.PERSONAL_REQ = TIEMPO_HOMBRE / tiempo.horas;
      return concepto;
    });
    SetConceptos(Conceptos_);
  }, []);

  const handleChange = ({ name, value, IndexConcepto }) => {
    const concepto = Conceptos[IndexConcepto];
    concepto[name + "_ERROR"] = false;

    if (
      name == "CANTIDAD" ||
      name == "PERSONAS_INVOLUCRADAS" ||
      name == "TIEMPO_DE_PRODUCCION"
    ) {
      console.log(value);
      
    //    value= typeof value === 'string' ? 0 : value ;
        concepto[name] = isNaN(value)  ? 0 :
        
        name == "CANTIDAD" ||
        name == "PERSONAS_INVOLUCRADAS" ? parseInt(value):
        value;  

        let PERSONAS_INVOLUCRADAS = parseInt(concepto["PERSONAS_INVOLUCRADAS"]);
        let CANTIDAD = parseInt(concepto["CANTIDAD"]);
        let TIEMPO_DE_PRODUCCION = parseFloat(concepto["TIEMPO_DE_PRODUCCION"]) / 60;
        let TIEMPO_HOMBRE =  CANTIDAD * PERSONAS_INVOLUCRADAS * TIEMPO_DE_PRODUCCION;
        concepto["PERSONAL_REQ"] = TIEMPO_HOMBRE / tiempo.horas;
 
      
    } else if (name != "DESCRIPCION") {
      concepto[name] = value.toDecimal();
    } else {
      concepto[name] = value;
    }

    if (
      !concepto.CANTIDAD_ERROR &&
      !concepto.DESCRIPCION_ERROR &&
      !concepto.PERSONAS_INVOLUCRADAS_ERROR &&
      !concepto.TIEMPO_DE_PRODUCCION_ERROR &&
      !concepto.COSTO_DE_COMPRA_ERROR &&
      // !concepto.PRECIO_DE_VENTA_ERROR &&
      !concepto.COMISION_ERROR
    ) {
      concepto["ERROR"] = false;
    }
    SetConceptos([...Conceptos]);
  };

  const eliminarConceptoDeTabla = (IndexConcepto) => {
    const newLista = Conceptos.filter(
      (concepto, Index) => Index != IndexConcepto
    );
    SetConceptos(newLista);
  };

  const eliminarConcepto = (ID, IndexConcepto) => {
    new Swal({
      title: "¿Estas seguro de eliminar este concepto?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        if (ID != undefined) {
          if (ID == 0) {
            // Tadavia esta en memoria ram
            eliminarConceptoDeTabla(IndexConcepto);
          } else {
            // Esta guardado en la base de datos
            Eliminar(ID, Evento)
              .then((resp) => {
                new Swal({
                  title: "Exito!",
                  text: "Eliminado correctamente",
                  icon: "success",
                }).then(function () {
                  eliminarConceptoDeTabla(IndexConcepto);
                });
              })
              .catch((resp) => {
                alert("Error al eliminar");
              });
          }
        }
      }
    });
  }; 
  const fnAgregarConcepto = () => {
    const newConcepto = crearConcepto();
    SetConceptos([...Conceptos, newConcepto]);
  }; 
  let TOTAL_PERSONAS = 0;
  let TOTAL_TIEMPO = 0;
  let TOTAL_REQUERIDO = 0;
  let HORAS_HOMBRE = 0;

  return (
    <>
      <Row className="p-3" id="Conceptos">
        {/* <Col m={12} md={12} justifyContent="flex-end" className="mb-4">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="baseline"
                    >
                        <Button
                            onClick={() => setModalConceptos(true)}
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                            variant="contained"
                            al
                            style={{ width: "140px", height: "40px", align: "right" }}
                        >
                            <span className={"px-2"}>Agregar</span>
                            <AddIcon />
                        </Button>
                    </Grid>
                </Col> */}
        <Col sm={12}>
          <TableContainer
            component={Paper}
            style={{ border: "0px", boxShadow: "none" }}
          >
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="let"
                  ></TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="let"
                  >
                    CANTIDAD
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    CONCEPTO
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    PERSONAS
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    T. PRODUCCIÓN
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="center"
                  >
                    PERSONAL REQ.
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    COSTO
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    PRECIO
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    COMISIÓN
                  </TableCell>

                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="right"
                  >
                    ACCIONES
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Conceptos.length != 0 ? (
                  Conceptos.map((concepto, IndexConcepto) => (
                    <>
                      <TableRow
                        key={concepto.ID_CONCEPTO}
                        sx={{ "& > *": { borderBottom: "unset" } }}
                      >
                        <TableCell>
                          <IconButton
                            className={concepto.ERROR ? "mb-4" : ""}
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              concepto.MOSTRAR_PRODUCTOS =
                                !concepto.MOSTRAR_PRODUCTOS;
                              SetConceptos([...Conceptos]);
                            }}
                          >
                            {concepto.MOSTRAR_PRODUCTOS ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell component="th" scope="row" align="left">
                          <TextField
                            size="small"
                            name="CANTIDAD"
                            variant="outlined"
                            value={isNaN( concepto.CANTIDAD ) ? "" : concepto.CANTIDAD }
                            style={{ width: "90px", height: "5%" }}
                            onChange={({ target }) => {
                              handleChange({
                                name: target.name,
                                value: target.value,
                                IndexConcepto: IndexConcepto,
                              });
                            }}
                            error={concepto.CANTIDAD_ERROR}
                            helperText={
                              concepto.CANTIDAD_ERROR ? "No valido" : ""
                            }
                            className={
                              concepto.ERROR && !concepto.CANTIDAD_ERROR
                                ? "mb-4"
                                : ""
                            }
                          />
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <TextField
                            placeholder="Descripción del concepto"
                            size="small"
                            name="DESCRIPCION"
                            variant="outlined"
                            value={concepto.DESCRIPCION}
                            style={{ width: "250px", height: "5%" }}
                            onChange={({ target }) => {
                              handleChange({
                                name: target.name,
                                value: target.value,
                                IndexConcepto: IndexConcepto,
                              });
                            }}
                            error={concepto.DESCRIPCION_ERROR}
                            helperText={
                              concepto.DESCRIPCION_ERROR
                                ? "Descripción invalida"
                                : ""
                            }
                            className={
                              concepto.ERROR && !concepto.DESCRIPCION_ERROR
                                ? "mb-4"
                                : ""
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" align="left">
                          <TextField
                            size="small"
                            name="PERSONAS_INVOLUCRADAS"
                            variant="outlined" 
                            value={isNaN( concepto.PERSONAS_INVOLUCRADAS ) ? "" : concepto.PERSONAS_INVOLUCRADAS }
                            style={{ width: "90px", height: "5%" }}
                            onChange={({ target }) => {
                              handleChange({
                                name: target.name,
                                value: target.value,
                                IndexConcepto: IndexConcepto,
                              });
                            }}
                            error={concepto.PERSONAS_INVOLUCRADAS_ERROR}
                            helperText={
                              concepto.PERSONAS_INVOLUCRADAS_ERROR
                                ? "No valido"
                                : ""
                            }
                            className={
                              concepto.ERROR &&
                              !concepto.PERSONAS_INVOLUCRADAS_ERROR
                                ? "mb-4"
                                : ""
                            }
                          />
                        </TableCell>

                        <TableCell component="th" scope="row" align="left">
                          <TextField
                            size="small"
                            name="TIEMPO_DE_PRODUCCION"
                            variant="outlined" 
                            value={isNaN( concepto.TIEMPO_DE_PRODUCCION ) ? "" : concepto.TIEMPO_DE_PRODUCCION }
                            style={{ width: "90px", height: "5%" }}
                            onChange={({ target }) => {
                              handleChange({
                                name: target.name,
                                value: target.value,
                                IndexConcepto: IndexConcepto,
                              });
                            }}
                            error={concepto.TIEMPO_DE_PRODUCCION_ERROR}
                            helperText={
                              concepto.TIEMPO_DE_PRODUCCION_ERROR
                                ? "No valido"
                                : ""
                            }
                            className={
                              concepto.ERROR &&
                              !concepto.TIEMPO_DE_PRODUCCION_ERROR
                                ? "mb-4"
                                : ""
                            }
                          />
                        </TableCell>

                        <TableCell component="th" scope="row" align="center">
                          <p>
                            {concepto.PERSONAL_REQ
                              ? concepto.PERSONAL_REQ.toFixed(2)
                              : 0}
                          </p>
                        </TableCell>
                        <TableCell component="th" scope="row" align="left">
                          <TextField
                            size="small"
                            name="COSTO_DE_COMPRA"
                            variant="outlined"
                            value={concepto.COSTO_DE_COMPRA}
                            style={{ width: "100px", height: "5%" }}
                            onChange={({ target }) => {
                              handleChange({
                                name: target.name,
                                value: target.value,
                                IndexConcepto: IndexConcepto,
                              });
                            }}
                            error={concepto.COSTO_DE_COMPRA_ERROR}
                            helperText={
                              concepto.COSTO_DE_COMPRA_ERROR ? "No valido" : ""
                            }
                            className={
                              concepto.ERROR && !concepto.COSTO_DE_COMPRA_ERROR
                                ? "mb-4"
                                : ""
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" align="left">
                          <TextField
                            size="small"
                            name="PRECIO_DE_VENTA"
                            variant="outlined"
                            value={concepto.PRECIO_DE_VENTA}
                            style={{ width: "100px", height: "5%" }}
                            onChange={({ target }) => {
                              handleChange({
                                name: target.name,
                                value: target.value,
                                IndexConcepto: IndexConcepto,
                              });
                            }}
                            error={concepto.PRECIO_DE_VENTA_ERROR}
                            helperText={
                              concepto.PRECIO_DE_VENTA_ERROR ? "No valido" : ""
                            }
                            className={
                              concepto.ERROR && !concepto.PRECIO_DE_VENTA_ERROR
                                ? "mb-4"
                                : ""
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" align="left">
                          <TextField
                            size="small"
                            name="COMISION"
                            variant="outlined"
                            value={concepto.COMISION}
                            style={{ width: "90px", height: "5%" }}
                            onChange={({ target }) => {
                              handleChange({
                                name: target.name,
                                value: target.value,
                                IndexConcepto: IndexConcepto,
                              });
                            }}
                            error={concepto.COMISION_ERROR}
                            helperText={
                              concepto.COMISION_ERROR ? "No valido" : ""
                            }
                            className={
                              concepto.ERROR && !concepto.COMISION_ERROR
                                ? "mb-4"
                                : ""
                            }
                          />
                        </TableCell>

                        <TableCell align="right">
                          <Tooltip title="Eliminar" placement="top">
                            <IconButton
                              className={concepto.ERROR ? "mb-4" : ""}
                              onClick={() =>
                                eliminarConcepto(concepto.ID, IndexConcepto)
                              }
                            >
                              <img src={AccEliminar} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={10}
                          className="bg-gray"
                        >
                          <Collapse
                            in={concepto.MOSTRAR_PRODUCTOS}
                            timeout="auto"
                            unmountOnExit
                          >
                            <RepProductos
                              IndexConcepto={IndexConcepto}
                              Productos={concepto.PRODUCTOS}
                              Conceptos={Conceptos}
                              SetConceptos={SetConceptos}
                              ProductosModificados={ProductosModificados}
                              SetProductosModificados={SetProductosModificados}
                            />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      colSpan={9}
                      align="center"
                    >
                      Sin conceptos
                    </TableCell>
                  </TableRow>
                )}

                {Conceptos.length != 0
                  ? Conceptos.map((concepto) => {
                      {
                        TOTAL_PERSONAS += parseInt(concepto.PERSONAS_INVOLUCRADAS);
                      }
                     
                      {
                        TOTAL_TIEMPO += parseFloat(concepto.TIEMPO_DE_PRODUCCION);
                        HORAS_HOMBRE +=
                        parseInt( concepto.CANTIDAD) *   parseInt(concepto.PERSONAS_INVOLUCRADAS) *
                          (concepto.TIEMPO_DE_PRODUCCION / 60);

                          TOTAL_REQUERIDO+=concepto.PERSONAL_REQ;
                          
                      }
                    })
                  : ""}
                <TableRow>
                  <TableCell className="fw-bold">TOTALES</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>{TOTAL_PERSONAS} Personas</TableCell>
                  <TableCell>
                    {TOTAL_TIEMPO} ({(TOTAL_TIEMPO / 60).toFixed(2)})Hrs
                  </TableCell>
                  <TableCell align="center">
                    {TOTAL_REQUERIDO.toFixed(2)}
                  </TableCell>
                  <TableCell colSpan={4}></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="fw-bold" colSpan={2}>
                    TOTAL HORAS HOMBRE
                  </TableCell>
                  <TableCell>{HORAS_HOMBRE.toFixed(2)}</TableCell>
                  <TableCell colSpan={7}></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="fw-bold" colSpan={2}>
                    TOTAL PERSONAL REQUERIDO
                  </TableCell>
                  <TableCell>{(HORAS_HOMBRE / tiempo.horas).toFixed(2)}</TableCell>
                  <TableCell colSpan={7}></TableCell>
                </TableRow>

                <TableRow style={{ border: "0px" }}>
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan={10}
                    align="right"
                    style={{ border: "0px" }}
                  >
                    <Button
                      style={{ color: "#191919" }}
                      onClick={fnAgregarConcepto}
                      className="btn  btn-exp-PDF font-AvenirMedium"
                      variant="text"
                    >
                      <AddIcon className="me-2" /> Agregar concepto
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>

      {/* {
                openModalConceptos && (
                    <ModalConcepto
                        mostrar={openModalConceptos}
                        fnCerrar={fnCerrarModal}
                        fnAgregar={fnAgregar}
                        ConceptosAgregados={Conceptos} />
                )
            } */}
    </>
  );
};

export default RepConceptos;
