import { stringify } from "query-string";
import { getData, postUrl } from "../../../../../Context/backend";

export const getLista = async (evento) => {
  let data = [];
  let queryParamsObj = {
    evento: evento
  };
  const url = `eventos/ListarEmpelados?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `eventos/EliminarPersona?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `eventos/DesactivarPersona?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};