import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { BasicTimePicker } from "../../../../../Includes/TimePicker";
import {
  ConvertDate,
  ConvertTime,
  GetDate,
} from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import { BasicDatePicker } from "../../../../../Includes/DatePicker";
import { crearDia, Eliminar, validarHorario } from "../Funciones/Horarios";
import { Col, Row } from "react-bootstrap";

import AccEliminar from "./../../../../../assets/AccEliminar.svg";
import Loading from "../../../../../Includes/Loading";
import AddIcon from "@mui/icons-material/Add";
import HelperError from "../../../../../Includes/HelperError";
import Swal from "sweetalert2";

String.prototype.getDiaSemana = function () {
  const date = new Date(this.valueOf().substring(0, 10));
  const dias = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];
  return dias[date.getDay()];
};

const RepHorarios = ({ FechaEvento, Horarios, SetHorarios }) => {
  const HORAS = 1000 * 60 * 60;
  const setDate = (IndexHorario, fecha, name) => {
    Horarios[IndexHorario][name] = ConvertDate(fecha);
    validarHorario(Horarios, FechaEvento, Horarios[IndexHorario], IndexHorario);
    SetHorarios([...Horarios]);
  };

  const setTime = (IndexHorario, hora, name) => {
    Horarios[IndexHorario][name] = ConvertTime(hora);
    validarHorario(Horarios, FechaEvento, Horarios[IndexHorario], IndexHorario);
    SetHorarios([...Horarios]);
  };

  const fnAgregarDia = () => {
    const newHorario = crearDia();
    validarHorario(Horarios, FechaEvento, newHorario, -1);
    SetHorarios([...Horarios, newHorario]);
  };

  const eliminarHorarioDeTabla = (IndexHorario) => {
    const newLista = Horarios.filter((horario, Index) => Index != IndexHorario);
    SetHorarios(newLista);
  };

  const eliminarHorario = (ID, IndexHorario) => {
    new Swal({
      title: "¿Estas seguro de eliminar este día?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        if (ID != undefined) {
          if (ID == 0) {
            // Tadavia esta en memoria ram
            eliminarHorarioDeTabla(IndexHorario);
          } else {
            // Esta guardado en la base de datos
            Eliminar(ID)
              .then((resp) => {
                new Swal({
                  title: "Exito!",
                  text: "Eliminado correctamente",
                  icon: "success",
                }).then(function () {
                  eliminarHorarioDeTabla(IndexHorario);
                });
              })
              .catch((resp) => {
                alert("Error al eliminar");
              });
          }
        }
      }
    });
  };

  return (
    <Row className="p-3">
      <Col sm={12}>
        <TableContainer
          component={Paper}
          style={{ border: "0px", boxShadow: "none" }}
        >
          <Table aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell className="font-Avenir font-AvenirBold" align="left">
                  DÍA
                </TableCell>
                <TableCell className="font-Avenir font-AvenirBold" align="left">
                  FECHA DE LA JORNADA
                </TableCell>
                <TableCell className="font-Avenir font-AvenirBold" align="left">
                  INICIO DE LA JORNADA
                </TableCell>
                <TableCell className="font-Avenir font-AvenirBold" align="left">
                  FIN DE LA JORNADA
                </TableCell>
                <TableCell className="font-Avenir font-AvenirBold" align="left">
                  TIEMPO
                </TableCell>
                <TableCell
                  className="font-Avenir font-AvenirBold"
                  align="right"
                >
                  ACCIONES
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Horarios.length != 0 ? (
                Horarios.map((horario, IndexHorario) => {
                  const ErrorHorario =
                    horario.ERROR_HORARIO || horario.error ? true : false;
                  const ErrorInicio =
                    horario.ERROR_INICIO || horario.error ? true : false;
                  const ErrorFin =
                    horario.ERROR_FIN || horario.error ? true : false;

                  let totalTiempoHrs = 0;
                  const fin = new Date(
                    horario.FECHA_HORARIO + " " + horario.FIN + ":00"
                  );
                  const inicio = new Date(
                    horario.FECHA_HORARIO + " " + horario.INICIO + ":00"
                  );
                  totalTiempoHrs += (fin - inicio) / HORAS;

                  return (
                    <TableRow key={IndexHorario}>
                      <TableCell component="th" scope="row" align="left">
                        {horario.FECHA_HORARIO != "" &&
                          horario.FECHA_HORARIO != null && (
                            <p className={horario.error ? "mb-5" : "m-0"}>
                              {horario.FECHA_HORARIO.getDiaSemana()}
                            </p>
                          )}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        <div className="py-2">
                          <BasicDatePicker
                            // fechaMin={FechaActual}
                            fechaMax={ConvertDate(FechaEvento)}
                            size="small"
                            label={"Fecha"}
                            fecha={horario.FECHA_HORARIO}
                            setFecha={(fecha) => {
                              setDate(IndexHorario, fecha, "FECHA_HORARIO");
                            }}
                          />
                          {
                            <HelperError
                              Error={ErrorHorario}
                              Mensaje={horario.MENSAJE_HORARIO}
                            />
                          }
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        <BasicTimePicker
                          label="Inicio"
                          hora={horario.INICIO}
                          setTime={(hora) => {
                            setTime(IndexHorario, hora, "INICIO");
                          }}
                        />
                        {
                          <HelperError
                            Error={ErrorInicio}
                            Mensaje={horario.MENSAJE_INICIO}
                          />
                        }
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        <BasicTimePicker
                          label="Fin"
                          hora={horario.FIN}
                          setTime={(hora) => {
                            setTime(IndexHorario, hora, "FIN");
                          }}
                        />
                        {
                          <HelperError
                            Error={ErrorFin}
                            Mensaje={horario.MENSAJE_FIN}
                          />
                        }
                      </TableCell>

                      <TableCell component="th" scope="row" align="left">
                        {totalTiempoHrs} Horas
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip title="Eliminar" placement="top">
                          <IconButton
                            className={horario.error ? "mb-4" : ""}
                            onClick={() =>
                              eliminarHorario(horario.ID, IndexHorario)
                            }
                          >
                            <img src={AccEliminar} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell component="th" scope="row" colSpan={5}>
                    Sin horarios
                  </TableCell>
                </TableRow>
              )}
              <TableRow style={{ border: "0px" }}>
                <TableCell
                  component="th"
                  scope="row"
                  colSpan={5}
                  align="right"
                  style={{ border: "0px" }}
                >
                  <Button
                    style={{ color: "#191919" }}
                    onClick={fnAgregarDia}
                    className="btn  btn-exp-PDF font-AvenirMedium"
                    variant="text"
                  >
                    <AddIcon className="me-2" /> Agregar día
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
};

export default RepHorarios;
