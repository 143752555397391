import { stringify } from "query-string";
import { ENTORNO_DE_PRUEBAS, getData, postdData, postUrl } from "../../../Context/backend";
import validator from "validator";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `planners/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoPlanners = async (id, imgDefault) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `planners/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este producto",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        console.log(data);
        let Values = {
          id: String(data.ID),
          nombre: String(data.NOMBRE),
          apellido_paterno: String(data.APELLIDO_PATERNO),
          apellido_materno: String(data.APELLIDO_MATERNO),
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO),
          id_admin: String(data.ID_ADMIN),
          activo: String(data.ACTIVO),
          img:
            String(data.IMG) == ""
              ? imgDefault
              : String(data.IMG)
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;
  console.log(Values);

  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores;
    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }
  if (!Values.apellido_paterno || Values.apellido_paterno == "") {
    let Errores2 = Errores;
    Errores2.apellido_paterno = true;
    setErrores(Errores2);
    error = true;
  }
  if (!Values.apellido_materno || Values.apellido_materno == "") {
    let Errores2 = Errores;
    Errores2.apellido_materno = false;
    setErrores(Errores2);
    error = false;
  }
  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }
  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!error) {


    if (Values.img.includes("base64")) {
      Values.img = Values.img.split("base64,")[1];
    }
    else {
      Values.img = "";
    }

    if (Values.id == "0" || Values.id == 0 || Values.id == "" && Values.img != "") {
      Values.img_nueva = {
        id: "",
        tipo: IMG.TIPO_PLANNER,
        img64: Values.img,
        pruebas: ENTORNO_DE_PRUEBAS
      }
      Values.img = "";
    }

    let data = [];
    const body = Values;
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "planners/Insertar"
        : "planners/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `planners/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `planners/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
