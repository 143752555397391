import { Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Badge, Button, Col, Row, Table } from 'react-bootstrap';
import { getOpcionesModal } from "../Funciones/Productos";

import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import Loading from "../../../Includes/Loading";
import CloseIcon from '@mui/icons-material/Close';
import Categoria from "../../Categorias/Componentes/SelectCategorias";
import Producto from "./Producto";

const ModalProducto = ({
    fnCerrar,
    mostrar = false,
    fnAgregar = (id, descripcion) => { },
    Lista = [],
}) => {
    const [Productos, setProductos] = useState({
        lista: [],
        cargando: false,
        query: "",
        categoria: 0
    });
    const [itemCant, setItemCant] = useState([0]);

    const consultar = (valor, consultarTop, animar, categoria) => {

        getOpcionesModal(valor, consultarTop ? 10 : 0, categoria)
            .then((resp) => {
                let list = JSON.parse(resp.data);
                setProductos({ ...Productos, cargando: false, lista: list });
                //actualizarToolTip();
            })
            .catch((resp) => {
                setProductos({ ...Productos, cargando: false, lista: [] });
                //actualizarToolTip();
            });
    };

    const escribir = ({ target }) => {
        setProductos({ ...Productos, query: target.value, cargando: true });
    };

    useEffect(() => {
        setProductos({ ...Productos, cargando: true });
        clearInterval(window.intervalo)
        window.intervalo = setInterval(function () {
            consultar(Productos.query, true, true, Productos.categoria);
            clearInterval(window.intervalo)
        }, 1000)
    }, [Productos.categoria, Productos.query]);

    const actualizarToolTip = () => {
        //setItemCant([]);
        let arrNew = [];
        let boleanIndicador = true;
        let iNdicador = 0;
        setItemCant([]);
        //console.log(lista.length);
        Productos.lista.forEach((item, i) => {
            //console.log(item);
            boleanIndicador = true;
            iNdicador = 0;
            //console.log(`lista ${i + 1}`, boleanIndicador);

            if (Lista.length == 0) {
                arrNew.push(0);
            } else {
                Lista.forEach((it, o) => {
                    //console.log(`Lista ${o + 1}`, boleanIndicador);

                    //console.log("IDPROD: ", item.ID, "ID: ", it.ID_PRODUCTO);
                    if (boleanIndicador) {
                        //console.log("desk: ", item.DESCRIPCION, "desk: ", it.DESCRIPCION);
                        if (it.ID_PRODUCTO == item.ID) {
                            arrNew.push(it.CANTIDAD);
                            //console.log("agregue un elemento en ", it.CANTIDAD);
                            boleanIndicador = false;
                        } else {
                            iNdicador = o + 1;
                            if (boleanIndicador && iNdicador == Lista.length) {
                                arrNew.push(0);
                                ///console.log("agregue un elemento en 0");
                                boleanIndicador = false;
                            }
                        }
                    }
                });
            }
        });

        setItemCant(arrNew);
        //console.log(itemCant);
        //console.log(Lista);
    };

    const onclic = (INDEX, id, desc, costo, precio) => {
        let newData = [];
        itemCant.forEach((item, i) => {
            //console.log("item: ", i, "INDEX: ", INDEX);
            if (i == INDEX) {
                const data = Number(item + 1);
                console.log(data);
                newData.push(data);
            } else {
                const data = Number(item);
                newData.push(data);
            }
        });
        setItemCant(newData);
        fnAgregar(id, desc, costo, precio);
    };

    useEffect(() => {
        actualizarToolTip();
        // console.log(itemCant);
        //console.log(Lista);
        //console.log(lista);
    }, [Productos.cargando]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={mostrar}
            onClose={fnCerrar}
        >

            <div className="position-absolute" style={{ "right": 0 }} >
                <IconButton onClick={fnCerrar} >
                    <CloseIcon />
                </IconButton>
            </div>

            <DialogTitle>
                <Row className="me-2 mt-4">
                    <Col sm={12} md={8}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">
                                Producto
                            </InputLabel>
                            <OutlinedInput
                                value={Productos.query}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                label="Producto"
                                placeholder="Buscar producto por código o nombre..."
                                onChange={escribir}
                            />
                        </FormControl>
                    </Col>
                    <Col sm={12} md={4}>
                        <Categoria
                            Opciones={[{ ID: 0, DESCRIPCION: "Todas" }]}
                            Value={0}
                            handlInputChange={({ target }) => {
                                //consultar(Producto.query, true, true, target.value)
                                setProductos({ ...Productos, categoria: target.value })
                            }}
                        />
                    </Col>
                </Row>
            </DialogTitle>

            <DialogContent className="p-0 m-0" id="ModalConceptos">
                <Col sm={12} className="px-3" >
                    <Table aria-label="custom table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    CATEGORÍA
                                </TableCell>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    CÓDIGO
                                </TableCell>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    NOMBRE
                                </TableCell>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    COSTO
                                </TableCell>
                                <TableCell className="font-Avenir font-AvenirBold" align="right">
                                    <div style={{ width: "120px" }}></div>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody >
                            {
                                !Productos.cargando ? (
                                    <>
                                        {
                                            Productos.lista && Productos.lista.length == 0 && (
                                                <TableRow>
                                                    <TableCell component="th" scope="row" colSpan={5} className="py-4">
                                                        Productos no encontrados
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                        {
                                            Productos.lista && Productos.lista.map((row, i) => (
                                                <TableRow key={row.ID}>
                                                    <TableCell component="th" scope="row">
                                                        {row.CATEGORIA}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.CODIGO}
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        {row.DESCRIPCION}
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        $ {row.COSTO_DE_COMPRA.toLocaleString("en", { minimumFractionDigits: 2 })}
                                                    </TableCell>
                                                    <TableCell component="td" scope="row" align="right">
                                                        <Tooltip
                                                            title={"Productos agregados: " + itemCant[i]}
                                                            placement="top"
                                                        >
                                                            <Button
                                                                onClick={() =>
                                                                    onclic(i, row.ID, row.DESCRIPCION, row.COSTO_DE_COMPRA, row.PRECIO_DE_VENTA)
                                                                }
                                                                className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                                                                variant="outlined"
                                                            >
                                                                Seleccionar
                                                            </Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell component="th" scope="row" colSpan={5} className="py-4">
                                            <Loading />
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </Col>
            </DialogContent>
            <DialogActions>
                <Stack
                    className="p-3"
                    spacing={2}
                    direction={{ xs: "column", sm: "row" }}
                >
                    <Button
                        onClick={fnCerrar}
                        className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                        variant="outlined"
                    >
                        Cerrar
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

export default ModalProducto