import { Avatar, Button, Card, CardContent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";
import { Col, Row } from "react-bootstrap";
import { json, Link } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import EventIcon from "@mui/icons-material/Event";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PersonIcon from "@mui/icons-material/Person";
import RadialBar from "./Charts/RadialBar";
import CircularStatic from "./Charts/Progress";
import ChartBarSemana from "./Charts/ChartBarSemana";
import ChartBarMes from "./Charts/ChartBarMes";
import { GetdataDashboard } from "./Funciones/Dashboard";
import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import "../Lib/Styles/Styles.css";
import Alert from '@mui/material/Alert';

import PruebaResponsive from "../PruebaResponsive";

const Dashboard = ({ Values}) => {
  const [IsLoading, setIsLoading] = useState(true);

  const [graficas, setgraficas] = useState([]);

  const GetDataDash = (setgraficas) => {
    setIsLoading(true);
    GetdataDashboard()
      .then((resp) => {
        console.log("DASHBOARD: ", resp.data);
        setgraficas(resp.data);
        setIsLoading(false);
      })
      .catch((resp) => {
        setgraficas([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Dashboard`;
    GetDataDash(setgraficas);
  }, []);
  // alert(Values.pass_default)

  return (
    <>
      <h1>Dashboard</h1>
      {/* <PruebaResponsive /> */}
      <Row>
        <Col sm={12}>
        {
            Values.pass_default  ?
        <Alert className="" severity="error">En necesario cambiar tu contraseña</Alert>
        : <></>
}
       
        </Col>
        <Col sm={12}>
          <Stack
            justifyContent="space-between"
            flexWrap={"wrap"}
            direction={{ xs: "column", sm: "row" }}
          >
            <Paper
              className="mt-2 p-3 d-flex justify-content-between flex-column text-white me-2"
              elevation={5}
              style={{
                width: "250px",
                height: "150px",
                backgroundColor: "#100D1C",
              }}
            >
              <div>
                <h5 className="font-AvenirMedium  text-center">
                  Eventos de la semana{" "}
                </h5>
              </div>
              <div className="d-flex justify-content-between flex-row align-content-center algin-align-items-center">
                <EventIcon
                  fontSize="large"
                  className="align-self-center ext-white"
                />
                <h1 className="text-end font-AvenirBold ext-white">
                  {
                    IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      graficas["totalEventosSemana"]
                    )
                  }
                </h1>
              </div>
              <div className="text-end">
                <Link
                  to="../eventos/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button
                    className="font-Avenir btn text-white pe-0 text-end pe-0 text-end"
                    size="small"
                  >
                    Ir a eventos
                    <KeyboardArrowRightIcon className="ms-1" />{" "}
                  </Button>
                </Link>
              </div>
            </Paper>

            <Paper
              className="mt-2 p-3 d-flex justify-content-between flex-column text-white me-2"
              elevation={5}
              style={{
                width: "250px",
                height: "150px",
                backgroundColor: "#FE8D27",
              }}
            >
              <div>
                <h5 className="font-AvenirMedium text-center">
                  Total de eventos
                </h5>
              </div>
              <div className="d-flex justify-content-between flex-row align-content-center algin-align-items-center">
                <EventIcon
                  fontSize="large"
                  className="align-self-center ext-white"
                />

                <h1 className="text-end font-AvenirBold ext-white">
                  {
                    IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      graficas["totalEventos"]
                    )
                  }
                </h1>
              </div>
              <div className="text-end">
                <Link
                  to="../eventos/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button
                    className="font-Avenir btn text-white pe-0 text-end"
                    size="small"
                  >
                    Ir a eventos
                    <KeyboardArrowRightIcon className="ms-1" />{" "}
                  </Button>
                </Link>
              </div>
            </Paper>

            <Paper
              className="mt-2 p-3 d-flex justify-content-between flex-column text-white me-2"
              elevation={5}
              style={{
                width: "250px",
                height: "150px",
                backgroundColor: "#65748B",
              }}
            >
              <div>
                <h5 className="font-AvenirMedium text-center">
                  Total de planners
                </h5>
              </div>
              <div className="d-flex justify-content-between flex-row align-content-center algin-align-items-center">
                <PersonIcon
                  fontSize="large"
                  className="align-self-center ext-white"
                />
                <h1 className="text-end font-AvenirBold ext-white">
                  {
                    IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      graficas["totalPlanners"]
                    )
                  }
                </h1>
              </div>
              <div className="text-end">
                <Link
                  to="../planners/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button
                    className="font-Avenir btn text-white pe-0 text-end"
                    size="small"
                  >
                    Ir a planners
                    <KeyboardArrowRightIcon className="ms-1" />{" "}
                  </Button>
                </Link>
              </div>
            </Paper>

            <Paper
              className="mt-2 p-3 d-flex justify-content-between flex-column text-white me-2"
              elevation={5}
              style={{
                width: "250px",
                height: "150px",
                backgroundColor: "#FBB303",
              }}
            >
              <div>
                <h5 className="font-AvenirMedium text-center">
                  Total de empleados
                </h5>
              </div>
              <div className="d-flex justify-content-between flex-row align-content-center algin-align-items-center">
                <PersonIcon
                  fontSize="large"
                  className="align-self-center ext-white"
                />
                <h1 className="text-end font-AvenirBold ext-white">
                  {
                    IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      graficas["totalEmpleados"]
                    )
                  }
                </h1>
              </div>
              <div className="text-end">
                <Link
                  to="../empleados/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button
                    className="font-Avenir btn text-white pe-0 text-end"
                    size="small"
                  >
                    Ir a empleados
                    <KeyboardArrowRightIcon className="ms-1" />{" "}
                  </Button>
                </Link>
              </div>
            </Paper>
          </Stack>
        </Col>

        <Col xs={12} md={12} className="mt-4">
          <Card elevation={3}>
            <CardContent className="p-0">
              <div className="p-3">
                <h6 className="mb-0 text-left font-AvenirBold">
                  Eventos Activos
                </h6>
              </div>
              <hr
                style={{
                  backgroundColor: "#DFDEE0",
                  height: "1px",
                  opacity: "1",
                }}
                className="m-0 p-0"
              />

              <Row className="p-3" >
                {
                  IsLoading ? (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    // <code> <pre> { console.log("Datos:",JSON.stringify(graficas))  } </pre> </code>
                    graficas["totalEventosActivos"] && (
                      JSON.parse(graficas["totalEventosActivos"]).map((event) => (
                        <Col xs={12} onClick={() => {
                          window.location.href = "../eventos/edit/" + event.ID
                        }}>

                          <div className="d-flex justify-content-justify-content-between w-100 flex-flex-column align-content-center algin-align-items-center">
                            <div>
                              <CircularStatic Iniciales={"FC"} porcentaje={event.AVANCE.toFixed(0)} />
                            </div>

                            <div className="ms-3 w-100">
                              <h6
                                className="mb-0 text-left font-AvenirMedium"
                                style={{ color: "#151916" }}
                              >
                                {event.NOMBRE_EVENTO}
                              </h6>
                              <span
                                className="mb-0 text-left font-Avenir"
                                style={{ color: "#65748B" }}
                              >
                                {event.NOMBRE_LOCACION}
                              </span>
                            </div>

                            <div style={{ width: "150px" }}>
                              <Avatar
                                sx={{ width: 24, height: 24, fontSize: 13 }}
                                className="m-auto"
                              >
                                P
                              </Avatar>
                              <Typography
                                className="text-truncate text-center"
                                variant="caption"
                                display="block"
                              >
                                {event.NOMBRE_PLANNER}  
                              </Typography>
                            </div>
                          </div>
                          <hr />
                        </Col>
                      ))
                    )
                  )
                }
              </Row>
            </CardContent>
          </Card>
        </Col>
        <Col xs={12} md={6} className="mt-4">
          <Card elevation={3}>
            <CardContent className="p-0">
              <div className="p-3">
                <h6 className="mb-0 text-left font-AvenirBold">
                  Eventos por semana
                </h6>
              </div>
              <hr
                style={{
                  backgroundColor: "#DFDEE0",
                  height: "1px",
                  opacity: "1",
                }}
                className="m-0 p-0"
              />
              <div lassName="p-3 m-3">
                <Row className="p-1 m-2">
                  <Col>
                    <ChartBarSemana
                      Totalsemanas={graficas["totalEventos4Semana"]}
                    />
                  </Col>
                </Row>
              </div>
            </CardContent>
          </Card>
        </Col>
        <Col xs={12} md={6} className="mt-4">
          <Card elevation={3}>
            <CardContent className="p-0">
              <div className="p-3">
                <h6 className="mb-0 text-left font-AvenirBold">
                  Eventos por mes
                </h6>
              </div>
              <hr
                style={{
                  backgroundColor: "#DFDEE0",
                  height: "1px",
                  opacity: "1",
                }}
                className="m-0 p-0"
              />
              <div lassName="p-3 m-3">
                <Row className="p-1 m-2">
                  <Col>
                    <ChartBarMes Totalmeses={graficas["evento6mes"]} />
                  </Col>
                </Row>
              </div>
            </CardContent>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Dashboard;
