import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

 
function CircularProgressWithLabel(
 {  Iniciales  , value}
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress thickness={6} variant="determinate" value={value} color="primary"   />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          thickness: 4,
          color: "#3ABE88"
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{Iniciales }%</Typography>
      </Box>
    </Box>
  );
}

const CircularStatic = ({Iniciales , porcentaje}) => {
  // const [progress, setProgress] = React.useState(10); 
  return <CircularProgressWithLabel Iniciales={porcentaje} value={porcentaje} />;
}

export default  CircularStatic 