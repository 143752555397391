import React from "react";
import Chart from "react-apexcharts";
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';

const ChartBarMes = ({ Totalmeses }) => {

  if (Totalmeses == undefined) {
    return (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
    </Box>
    )
}

Totalmeses = JSON.parse(Totalmeses)

Totalmeses = Totalmeses ? Totalmeses : []
let Grafica = []


if (Totalmeses[0]) {
  if(Totalmeses[0].MES){
    Grafica.push({x: Totalmeses[0].MES, y: Totalmeses[0].TOTAL, fillColor:'#FE8D27'})
  }
  // return
}

if (Totalmeses[1]) {
  if(Totalmeses[1].MES){
    Grafica.push({x: Totalmeses[1].MES, y: Totalmeses[1].TOTAL, fillColor:'#100D1C'})
  }
  // return
}

if (Totalmeses[2]) {
  if(Totalmeses[2].MES){
    Grafica.push({x: Totalmeses[2].MES, y: Totalmeses[2].TOTAL, fillColor:'#FBB303'})
  }
  // return
}

if (Totalmeses[3]) {
  if(Totalmeses[3].MES){
    Grafica.push({x: Totalmeses[3].MES, y: Totalmeses[3].TOTAL, fillColor:'#65748B'})
  }
  // return
}

if (Totalmeses[4]) {
  if(Totalmeses[4].MES){
    Grafica.push({x: Totalmeses[4].MES, y: Totalmeses[4].TOTAL, fillColor:'#FE8D27'})
  }
  // return
}

if (Totalmeses[5]) {
  if(Totalmeses[5].MES){
    Grafica.push({x: Totalmeses[5].MES, y: Totalmeses[5].TOTAL})
  } 
} 
  let options = {
    chart: {
      type: "bar",
    },
    series: [
      {
        name: "Eventos",
        data: Grafica,  
        colors:    ['#FE8D27','#65748B','#65748B','#FBB303' ],
      },
      
    ],  
    fill: {
      colors:    ['#FE8D27','#65748B','#65748B','#FBB303' ],
    } 
  };
  return (

    <>
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={300}
      />
    </>
  );
};
export default ChartBarMes;
