import { postdData } from "../../../Context/backend";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const CargarImagen = async (ID, TIPO, IMG64) => {
    let data = [];
    const PRUEBAS = process.env.NODE_ENV === "production" ? 0 : 1
    const body = { ID, TIPO, IMG64, PRUEBAS };
    const url = "img/upload"
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = res.data;
            resolve(data);
        } else {
            reject(res);
        }
    });
};