import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { guardarHoras } from "../Funciones/CapturaAvences";

import {
    format2decimales
} from "../../../Lib/Funciones Generales/Generales";


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, TextField } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { LoadingButton } from "@mui/lab";
const MySwal = withReactContent(Swal);



const Nominas = ({ infoNominas, infoHorarios, ID }) => {

    const [infoEmpleados, setInfoEmpleados] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEnable, setIsEnable] = useState(true);

    useEffect(() => {
        setInfoEmpleados(infoNominas)
    }, []
    );

    const handlInputChange = ({ target }) => {
        let Value = target.value;
        const Filter = infoEmpleados.filter((item) => {
            if (item.ID == target.name) {
                item.HORAS_EXTRAS = parseInt(Value);
            }
            setIsEnable(false)
            return item;
        });

        setInfoEmpleados(Filter);
    };

    const GuardarHoras = () => {
        setIsLoading(true);
        setIsEnable(true);
        guardarHoras(infoEmpleados, ID)
            .then((data) => {
                setIsLoading(false);
                setIsEnable(true);
                if (data.codigo == "200") {
                    MySwal.fire({
                        title: "Correcto",
                        html: data.mensaje,
                        icon: "success",
                        confirmButtoColor: "#3ABE88",
                        showConfirmButton: false,
                        timer: 1200,
                    }).then((result) => {


                    });
                } else {
                    MySwal.fire({
                        title: "Error",
                        html: "Ha ocurrido un error al actualizar",
                        icon: "error",
                        confirmButtoColor: "#3ABE88",
                        showConfirmButton: false,
                        timer: 1200,
                    }).then((result) => {


                    });
                }
            })
            .catch((data) => {
                MySwal.fire({
                    title: "Error",
                    html: "Ha ocurrido un error al actualizar",
                    icon: "error",
                    confirmButtoColor: "#3ABE88",
                    showConfirmButton: false,
                    timer: 1200,
                }).then((result) => {


                });
            });
    };

    let totales = [];
    let TOTAL = 0;
    let TOTAL_EXTRAS = 0;


    console.log("Renderizando...")
    return (
        <>

            <Accordion className="m-5   mt-3 border" component={Paper} sx={{}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Nóminas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer sx={{}} >
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="right">Horarios</TableCell>
                                    {infoHorarios.map((row) => (
                                        <TableCell align="left">{row.DIA}</TableCell>
                                    ))}
                                    <TableCell align="left">  Hrs extras </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="right">Inicio</TableCell>
                                    {infoHorarios.map((row) => (
                                        <TableCell align="left">{row.INICIO}</TableCell>
                                    ))}
                                    <TableCell />
                                </TableRow>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="right">Fin</TableCell>
                                    {infoHorarios.map((row) => (
                                        <TableCell align="left">{row.FIN}</TableCell>
                                    ))}
                                    <TableCell />
                                </TableRow>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="right">Horas</TableCell>
                                    {infoHorarios.map((row) => (
                                        <TableCell align="left">{row.TIEMPO}</TableCell>
                                    ))}
                                    <TableCell />
                                </TableRow>
                                {infoEmpleados.map((row) => {
                                    return <TableRow>
                                        <TableCell align="left">${format2decimales(row.SUELDO_DIARIO)} </TableCell>
                                        <TableCell align="left">{row.NOMBRE} </TableCell>
                                        {infoHorarios.map((row2) => {
                                            return (
                                                <>
                                                    <TableCell align="left">{row.NUM_EMPLEADOS}</TableCell>
                                                </>
                                            )
                                        })
                                        }
                                        <TableCell align="left">
                                            <TextField
                                                label="Horas extra"
                                                id="outlined-size-small"
                                                size="small"
                                                name={row.ID}
                                                value={row.HORAS_EXTRAS}
                                                onChange={handlInputChange}
                                                style={{ maxWidth: "120px" }}
                                            />
                                        </TableCell>
                                    </TableRow>

                                }
                                )}
                                {
                                    infoEmpleados.map((row) => {
                                        TOTAL_EXTRAS += parseFloat(row.SUELDO_HORAS_EXTRA) * parseFloat(row.HORAS_EXTRAS);
                                        let contador = 0;
                                        return <TableRow>
                                            <TableCell align="left"> </TableCell>
                                            <TableCell align="left"> </TableCell>
                                            {
                                                infoHorarios.map((row2) => {
                                                    if (totales[contador] == undefined) {
                                                        totales[contador] = 0;
                                                    }
                                                    totales[contador] += row.SUELDO_DIARIO * row.NUM_EMPLEADOS;
                                                    TOTAL += row.SUELDO_DIARIO * row.NUM_EMPLEADOS;
                                                    contador++;
                                                    return <TableCell align="left"> ${format2decimales(parseFloat(row.SUELDO_DIARIO) * parseFloat(row.NUM_EMPLEADOS))} </TableCell>
                                                })
                                            }
                                            <TableCell align="left">
                                                {
                                                    "$" + format2decimales(parseFloat(row.SUELDO_HORAS_EXTRA) * parseFloat(row.HORAS_EXTRAS))
                                                }
                                            </TableCell>

                                        </TableRow>
                                    }
                                    )
                                }
                                <TableRow>
                                    <TableCell align="right"> </TableCell>
                                    <TableCell align="left"> </TableCell>
                                    {
                                        totales.map((row) => {
                                            return <TableCell className="fw-bold" align="left"> ${format2decimales(row)} </TableCell>

                                        }
                                        )
                                    }
                                    <TableCell align="left" className="fw-bold">
                                        {
                                            "$" + format2decimales(TOTAL_EXTRAS)
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right"> </TableCell>
                                    <TableCell align="left"> </TableCell>
                                    <TableCell align="left" className="fw-bold" colSpan={totales.length}> PROYECTADO</TableCell>
                                    <TableCell align="left" className="fw-bold">    ${format2decimales(TOTAL + TOTAL_EXTRAS)} </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody></TableBody>
                        </Table>
                    </TableContainer>
                    <div className="row">
   <div class="col-12 text-end">
  <LoadingButton
                        loading={isLoading}
                        loadingPosition="start"
                        disabled={isEnable}
                        onClick={GuardarHoras}
                        className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 mt-3"
                        variant="contained" >
                        <span className={isLoading ? "px-4" : "px-2"}>
                            {isLoading ? "Guardando..." : " Guardar cambios"}
                        </span>
                    </LoadingButton> 
                    </div>
                    </div>
                 
                  
                </AccordionDetails>
            </Accordion>


        </>
    );
};

export default Nominas;
