import {  Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import Perfil from "./Componentes/Perfil";
import UpdatePassword from './Componentes/UpdatePassword'


 const routerPerfil =()=> {
  return (
    <> 
     <Routes>     
     <Route index element={<Perfil />} /> 
     <Route path="/update" element={< UpdatePassword />} />
     <Route path="*" element={<Error404 />} />
    </Routes>
    </> 
  );
}

export default routerPerfil;