import { stringify } from "query-string";
import { getData, postdData } from "../../../Context/backend";


export const getCliente = async (query="") => {
    let data = [];
    let queryParamsObj = {
        "query": query
    } 
    const url = `clientes/getOpciones?${stringify(queryParamsObj)}`
    const res = await getData(url);
    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = res.data.respuesta;
            resolve(data);
        } else {
            reject(res);
        }
    });
}
export const getInfoCliente = async id => {
    let data = [];
    let queryParamsObj = {
        id: id,
    };
    const url = `clientes/getOpciones?${stringify(queryParamsObj)}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = JSON.parse(res.data.respuesta.data)[0];
            let Values = {
                id: String(data.ID),
                alias: String(data.ALIAS),
            };
            resolve(Values);
        } else {
            reject(res);
        }
    });
}
