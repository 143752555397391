import { stringify } from "query-string";
import validator from "validator";

import { getData, postdData, postUrl } from "../../../Context/backend";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getPuestos = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  const url = `puestos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpciones = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `puestos/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarPuesto = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.sueldo || Values.sueldo == "" || Values.sueldo == null) {
    Errores.sueldo = true;
    setErrores(Errores);
    error = true;
  }
  if (!validator.isNumeric(Values.sueldo)) {
    Errores.sueldo = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.descripcion || Values.descripcion == "") {
    Errores.descripcion = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.jornada || Values.jornada == "" || Values.jornada == null) {
    Errores.jornada = true;
    setErrores(Errores);
    error = true;
  }
  if (!validator.isNumeric(Values.jornada)) {
    Errores.jornada = true;
    setErrores(Errores);
    error = true;
  }


  if (Values.jornada.length != 0) {
    if (Values.jornada < 0) {
      Errores.jornada = true;
      setErrores(Errores);
      error = true;

      return new Promise((resolve, reject) => {
        resolve({
          mensaje: "El campo jornada laboral en horas no puede se menor a 0",
        });
      });
    }
  }

  if (Values.sueldo.length != 0) {
    if (Values.sueldo < 0) {
      Errores.sueldo = true;
      setErrores(Errores);
      error = true;

      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El campo sueldo no puede ser menor a 0" });
      });
    }
  }

  if (Values.extras.length != 0) {
    if (Values.extras < 0) {
      Errores.extras = true;
      setErrores(Errores);
      error = true;

      return new Promise((resolve, reject) => {
        resolve({
          mensaje: "El campo sueldo horas extras no puede ser menor a 0",
        });
      });
    }
  }

  if (!error) {
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "puestos/Insertar"
        : "puestos/Editar";

    const body = {
      id: Values.id,
      nombre: Values.nombre,
      sueldO_DIARIO: Values.sueldo,
      descripcion: Values.descripcion,
      jornadA_LABORAL: Values.jornada,
      sueldO_HORAS_EXTRA: Values.extras,
    };
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
    // if (
    //   !validator.isNumeric(Values.sueldo) ||
    //   !validator.isNumeric(Values.jornada) ||
    //   !validator.isNumeric(Values.extras)
    // ) {
    //   return new Promise((resolve, reject) => {
    //     resolve({ mensaje: "Ingresa un numero valido" });
    //   });
    // } else {
    //   return new Promise((resolve, reject) => {
    //     resolve({ mensaje: "Completa todos los campos requeridos" });
    //   });
    // }
  }
};

export const eliminarPuesto = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `puestos/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfPuesto = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `puestos/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este puesto",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          id: String(data.ID),
          nombre: String(data.NOMBRE),
          sueldo: String(data.SUELDO_DIARIO),
          extras: String(data.SUELDO_HORAS_EXTRA),
          jornada: String(data.JORNADA_LABORAL),
          descripcion: String(data.DESCRIPCION),
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const Desactivar = async (id, checked) => {
  //return "ok";
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `puestos/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
