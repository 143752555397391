import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
 
///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (id) => { 
  let data = [];
  let queryParamsObj = {
    ID_EVENTO: id,
  };
  const url = `eventos/ListarAvances?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data =  res.data.respuesta;  
      resolve(data);
    } else {
      reject(res);
    }
  });
};
  
////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, ID_EVENTO) => {
  let error = false; 
  if (!error) {

    const filtrado = Values.filter((item) => { 
      return {
        id:item.ID,
        id_concepto: item.ID_CONCEPTO,
        porcentaje: item.PORCENTAJE,
      };
    });
    let data = [];

  const  body ={
    id_evento : ID_EVENTO,
    avances : filtrado
  } 
 
    const url =  "eventos/ActualizarAvances";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};


export const guardarHoras = async (Values, ID_EVENTO) => {
  let error = false; 
  if (!error) {


    let filtrado1 = Values.filter((item) => { 
      if  (parseInt( item.HORAS_EXTRAS)!=0)
      {
         return item;
      }
     
    });

    let filtrado = filtrado1.map((item) => { 
      return   {
        id_evento:ID_EVENTO,
        id_puesto: item.ID,
        horas: item.HORAS_EXTRAS,
      };
    });
    let data = [];

  const  body ={
    id_evento : ID_EVENTO,
    horas : filtrado
  } 

 
    const url =  "eventos/ActualizarHorasExtras";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};