import { stringify } from "query-string";
import { getData, postdData } from "../../../Context/backend";


export const getPlanner = async (query="") => {
    let data = [];
    let queryParamsObj = {
        "query": query
    } 
    const url = `planners/getOpciones?${stringify(queryParamsObj)}`
    const res = await getData(url);
    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = res.data.respuesta;
            resolve(data);
        } else {
            reject(res);
        }
    });
}

export const getInfoPlanner = async clave => {
    let data = [];
    let queryParamsObj = {
        clave: clave,
    };
    const url = `planners/getOpciones?${stringify(queryParamsObj)}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = JSON.parse(res.data.respuesta.data)[0];
            let Values = {
                id: String(data.ID),
                nombre: String(data.NOMBRE),
                apellido_paterno:String(data.APELLIDO_PATERNO),
                apellido_materno:String(data.APELLIDO_MATERNO),
            };
            resolve(Values);
        } else {
            reject(res);
        }
    });
}
