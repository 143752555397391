String.prototype.toDecimal = function () {
  let NumericString = this.valueOf().replace(/[^0-9\.]/g, '')
  const puntos = NumericString.split(".")
  if (puntos.length >= 3) {
    NumericString = puntos[0] + '.'
    puntos.map((numero, index) => {
      if (index > 0) {
        NumericString += numero
      }
    })
  }
  return NumericString;
}

String.prototype.toNumber = function () {
  return this.valueOf().replace(/\D/g, '')
}