import React from 'react'
import { useEffect } from 'react';
import { Mapas, Pin } from '../Funciones/Mapa'

const Mapa = ({ Center, Zoom, Conf, Height = "500px" }) => {

  const CDMX = Pin(19.434815, -99.132186, false)
  const clickMap = (posicion) => {
    // Funcion implementada para que no tuene al darle click en el mapa
  }
  const draggableMarker = (posicion) => {
    // Funcion implementada para que no tuene al arrastrar un marker
  }

  useEffect(() => {
    
    //Iniciar mapa
    window.ClsMapa = new Mapas();
    window.ClsMapa.Iniciar({
      id: 'mapa',
      lat: Center ? (Center.lat ? Center.lat : CDMX.lat) : CDMX.lat,
      lng: Center ? (Center.lng ? Center.lng : CDMX.lng) : CDMX.lng,
      zoom: Zoom ? Zoom : 13
    })
    window.ClsMapa.InsertaMarker({
      lat: Center ? (Center.lat ? Center.lat : CDMX.lat) : CDMX.lat,
      lng: Center ? (Center.lng ? Center.lng : CDMX.lng) : CDMX.lng,
      draggable: Center ? Center.draggable : false,
      unique: Center ? Center.unique : false
    })

    //Agregar lista de pins
    if (Conf) {
      if (Conf.pins) {
        if (Array.isArray(Conf.pins)) {
          Conf.pins.map(pin => {
            window.ClsMapa.InsertaMarker({
              lat: pin.lat,
              lng: pin.lng,
            })
          })
        }
      }
    }


  }, [])

  if (window.ClsMapa) {
    window.clickMap = Conf ? (Conf.clickMap ? Conf.clickMap : clickMap) : clickMap;
    window.draggableMarker = Conf ? (Conf.draggableMarker ? Conf.draggableMarker : draggableMarker) : draggableMarker;
  }



  return (
    <div id="mapa" style={{ width: "100%", height: Height }}></div>
  )
}

export default Mapa