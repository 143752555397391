import { Autocomplete, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { getRegimenfiscal } from "../Funciones/SAT";


const RegimenFiscal = ({ Value, Error, handlInputChange, Name = "regimen_fiscal" }) => {
    const opcionInicial = { CLAVE: 0, DESCRIPCION: "Régimen fiscal" }
    const [ValorFiltrado, setFiltro] = useState()
    const [RegimenFiscal, setRegimenFiscal] = useState({ cargado: false, lista: [opcionInicial] });

    useEffect(() => {
        getRegimenfiscal()
            .then((resp) => {
                let list = JSON.parse(resp.data);
                if (list.length != 0) {
                    setRegimenFiscal({ cargado: true, lista: list });
                }
                else {
                    setRegimenFiscal({ cargado: true, lista: [{ CLAVE: 0, DESCRIPCION: "Sin régimen fiscal disponibles" }] });
                }
            })
            .catch((resp) => {
                setRegimenFiscal({ cargado: true, lista: [{ CLAVE: 0, DESCRIPCION: "Sin régimen fiscal disponibles" }] });
            });
    }, [])

    const getOpcion = () => {
        const opcion = RegimenFiscal.lista.find(x => x.CLAVE == Value)
        if (opcion) {
            return opcion
        } else {
            return null
        }
    }

    return (
        RegimenFiscal.cargado ? (
            <Autocomplete
                fullWidth
                defaultValue={getOpcion}
                isOptionEqualToValue={(option, value) => {
                    return option.CLAVE == value.CLAVE
                }}
                inputValue={ValorFiltrado}
                onChange={(e, value) => {
                    if (value) {
                        handlInputChange({ target: { name: Name, value: value.CLAVE } })
                    }
                    else {
                        handlInputChange({ target: { name: Name, value: "" } })
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setFiltro(newInputValue);
                }}
                options={RegimenFiscal.lista}
                getOptionLabel={(option) => {
                    return option.DESCRIPCION
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            error={Error}
                            helperText={Error ? "Selecciona un Régimen fiscal" : ""}
                            label="Régimen fiscal"
                        />
                    )
                }}
            />
        ) : (
            <TextField
                fullWidth
                disabled={true}
                value={"Régimen fiscal"}
            />
        )
    )
}

export default RegimenFiscal