import { stringify } from "query-string";
import { ENTORNO_DE_PRUEBAS, getData, postdData, postUrl } from "../../../Context/backend";
import validator from "validator";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `locaciones/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpciones = async (query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `locaciones/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoLocacion = async (id, imgDefault) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `locaciones/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta locacion",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Values = {
          id: String(data.ID),
          activo: String(data.ACTIVO),
          nombre: String(data.NOMBRE),
          calle: String(data.CALLE),
          numero_exterior: String(data.NUMERO_EXTERIOR),
          numero_interior: String(data.NUMERO_INTERIOR),
          colonia: String(data.COLONIA),
          codigo_postal: String(data.CODIGO_POSTAL),
          id_municipio: String(data.ID_MUNICIPIO),
          id_estado: String(data.ID_ESTADO),
          ubicacion_lat: String(data.UBICACION_LAT),
          ubicacion_long: String(data.UBICACION_LONG),
          descripcion: String(data.DESCRIPCION),

          tipo_de_locacion: String(data.TIPO_DE_LOCACION),
          nombre_contacto: String(data.NOMBRE_CONTACTO),
          telefono_contacto: String(
            data.TELEFONO_CONTACTO == 0 ? "" : data.TELEFONO_CONTACTO
          ),
          referencia: String(data.REFERENCIA),
          img:
            String(data.IMG) == ""
              ? imgDefault
              : String(data.IMG)
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;
  let errorUbicacion = false;
  console.log(Values);

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.calle || Values.calle == "") {
    Errores.calle = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.numero_exterior || Values.numero_exterior == "") {
    Errores.numero_exterior = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.colonia || Values.colonia == "") {
    Errores.colonia = true;
    setErrores(Errores);
    error = true;
  }

  if (Values.codigo_postal.length > 5 || Values.codigo_postal.length == 0) {
    Errores.codigo_postal = true;
    setErrores(Errores);
    error = true;
  }
  // if (!validator.isNumeric(Values.codigo_postal)) {
  //     Errores.codigo_postal = true;
  //     setErrores(Errores);
  //     error = true;
  // }
  if (
    !Values.tipo_de_locacion ||
    Values.tipo_de_locacion == "" ||
    Values.tipo_de_locacion == "0"
  ) {
    Errores.tipo_de_locacion = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.id_estado || Values.id_estado == "" || Values.id_estado == "0") {
    Errores.id_estado = true;
    setErrores(Errores);
    error = true;
  }

  if (
    !Values.id_municipio ||
    Values.id_municipio == "" ||
    Values.id_municipio == "0"
  ) {
    Errores.id_municipio = true;
    error = true;
  }

  if (
    !Values.ubicacion_lat ||
    Values.ubicacion_lat == "" ||
    Values.ubicacion_lat == "0" ||
    Values.ubicacion_lat == "undefined"
  ) {
    errorUbicacion = true;
  }

  if (
    !Values.ubicacion_long ||
    Values.ubicacion_long == "" ||
    Values.ubicacion_long == "0" ||
    Values.ubicacion_long == "undefined"
  ) {
    errorUbicacion = true;
  }

  if (Values.telefono_contacto != "") {
    if (!validator.isNumeric(Values.telefono_contacto)) {
      Errores.telefono_contacto = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono_contacto.length != 10) {
      Errores.telefono_contacto = true;
      setErrores(Errores);
      error = true;
    }
  }

  Values.id_estado = String(Values.id_estado);
  Values.tipo_de_locacion = String(Values.tipo_de_locacion);
  Values.id_municipio = String(Values.id_municipio);
  Values.codigo_postal = String(Values.codigo_postal);
  Values.ubicacion_lat = String(Values.ubicacion_lat);
  Values.ubicacion_long = String(Values.ubicacion_long);


  

  if (!error) {
    if (errorUbicacion) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Falta definir la ubicación geografica" });
      });
    }

    if (Values.img.includes("base64")) {
      Values.img = Values.img.split("base64,")[1];
    }
    else {
      Values.img = "";
    }

    if (Values.id == "0" || Values.id == 0 || Values.id == "" && Values.img != "") {
      Values.img_nueva = {
        id: "",
        tipo: IMG.TIPO_LOCACION,
        img64: Values.img,
        pruebas: ENTORNO_DE_PRUEBAS
      }
      Values.img = "";
    }


    let data = [];
    const body = Values;
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "locaciones/Insertar"
        : "locaciones/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `locaciones/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `locaciones/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
