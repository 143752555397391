import { Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404"; 
import Ubicacion from "../../Lib/Mapa/Componentes/Ubicacion";
import Locacion from './Componentes/Locacion'
import RepLocaciones from './Componentes/RepLocaciones'

const routerLocaciones = () => {
    return (
        <Routes>
            <Route index element={<RepLocaciones />} />
            <Route path="/add" element={<Locacion />} />
            <Route path="/edit/:id" element={<Locacion />} />
            <Route path="/ubicacion/:poscion" element={<Ubicacion />} />
            <Route path="*" element={<Error404 />} />
        </Routes>
    )
}

export default routerLocaciones