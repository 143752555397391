import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoLocacion } from "../Funciones/Locaciones";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Estado from "../../../Lib/Direcciones/Componentes/Estado";
import Municipio from "../../../Lib/Direcciones/Componentes/Municipio";
import Mapa from "../../../Lib/Mapa/Componentes/Mapa";
import { Pin } from "../../../Lib/Mapa/Funciones/Mapa";
import { TiposLocaciones } from "../../../Lib/Funciones Generales/Constantes";

import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import "../../../Lib/Funciones Generales/Prototipos";

const MySwal = withReactContent(Swal);

const Locacion = () => {
  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {

    console.log("IMAGEN: ", img)

    setValues({ ...Values, img: img });
  };

  //Definicion de variables para el formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    activo: "1",
    nombre: "",
    calle: "",
    numero_exterior: "",
    numero_interior: "",
    colonia: "",
    codigo_postal: "",
    id_estado: "0",
    id_municipio: "0",
    descripcion: "1",
    ubicacion_lat: "0",
    ubicacion_long: "0",
    tipo_de_locacion: "0",
    nombre_contacto: "",
    telefono_contacto: "",
    referencia: "",
    img: AccEditarImagen,
  });
  const ErroresDomicilio = {
    calle: false,
    numero_exterior: false,
    numero_interior: false,
    colonia: false,
    codigo_postal: false,
    id_estado: false,
    id_municipio: false,
    descripcion: false,
    ubicacion_lat: false,
    ubicacion_long: false,
  };
  const [Errores, setErrores] = useState({
    nombre: false,
    tipo_de_locacion: false,
    nombre_contacto: false,
    telefono_contacto: false,
    referencia: false,
    ...ErroresDomicilio,
  });

  const PIN_CDMX = Pin(19.434815, -99.132186, true, true);
  const CONF_MAPA = {
    pins: null,
    clickMap: (posicion) => {
      actualizarUbicacion(posicion);
    },
    draggableMarker: (posicion) => {
      actualizarUbicacion(posicion);
    },
  };

  const actualizarUbicacion = (posicion) => {
    const { lat, lng } = posicion;
    setValues({ ...Values, ubicacion_lat: lat, ubicacion_long: lng });
    window.ClsMapa.InsertaMarker(Pin(lat, lng, true, true));
  };

  const buscarDomicilio = () => {
    let domicilio =
      Values.calle +
      (Values.numero_exterior.trim() != ""
        ? " #" + Values.numero_exterior
        : "") +
      (Values.colonia.trim() != "" ? " Col." + Values.colonia : "");

    if (domicilio.trim() == "") {
      domicilio =
        (Values.id_municipio != 0 &&
          (window.MUNICIPIO ? " " + window.MUNICIPIO : "")) +
        (Values.id_estado != 0 && (window.ESTADO ? " " + window.ESTADO : ""));
    }
    window.ClsMapa.BuscarCoordenadasPorDomicilio(
      domicilio.trim(),
      (results, lat, lng) => {
        if (results) {
          setValues({ ...Values, ubicacion_lat: lat, ubicacion_long: lng });
          window.ClsMapa.InsertaMarker(Pin(lat, lng, true, true));
        } else {
          setOpen(true);
          setMensaje("La dirección ingresada no fue encontrada");
        }
      }
    );
  };

  const handlInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };
    if (Name == "id_estado") {
      NewValue = { ...NewValue, id_municipio: 0 };
    } else if (Name == "codigo_postal" || Name == "telefono_contacto") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }
    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (id != undefined) {
      getInfoLocacion(id, AccEditarImagen)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues({ ...resp, init: true });

            setIsLoading(false);
          }
          /*setIsLoading(false);
          setValues({ ...resp, init: true });*/
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarLocacion = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  let disabledBtnUbicar = true;
  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;
  if (
    Values.calle.trim() != "" &&
    Values.numero_exterior.trim() != "" &&
    Values.colonia.trim() != "" &&
    Values.codigo_postal.trim() &&
    Values.id_estado != "0" &&
    Values.id_estado != 0 &&
    Values.id_estado != ""
  ) {
    disabledBtnUbicar = false;
  }

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Locaciones
      </Button>

      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar locación" : "Nueva locación"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="p-3">
                <Col sm={12} className="px-3">
                  <h4 className="text-mutted"> Datos generales </h4>
                </Col>
                <Col sm={12} className="mt-2">
                  <Row>
                    <Col
                      sm={12}
                      md={12}
                      lg={4}
                      className="d-flex flex-wrap align-items-center justify-content-center"
                    >
                      <Avatar
                        className="shadow"
                        sx={{ width: 120, height: 120 }}
                        src={img_url}
                      />
                      <strong
                        className="w-100 text-center px-0 pt-3"
                        style={{ color: "#3ABE88", cursor: "pointer" }}
                        onClick={handleOpenModal}
                      >
                        Editar foto
                      </strong>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                      <Row>
                        <Col sm={12} md={4} lg={6} className="p-3">
                          <TextField
                            fullWidth
                            name="nombre"
                            label="Nombre"
                            variant="outlined"
                            inputProps={{ maxLength: 200 }}
                            helperText={
                              Errores.nombre ? "Nombre no valido" : ""
                            }
                            value={Values.nombre}
                            error={Errores.nombre}
                            onChange={handlInputChange}
                          />
                        </Col>

                        <Col sm={12} md={4} lg={6} className="p-3">
                          <TextField
                            fullWidth
                            select
                            name="tipo_de_locacion"
                            label="Tipo de locación"
                            variant="outlined"
                            inputProps={{ maxLength: 200 }}
                            helperText={
                              Errores.tipo_de_locacion
                                ? "Tipo de locación no valido"
                                : ""
                            }
                            value={Values.tipo_de_locacion}
                            error={Errores.tipo_de_locacion}
                            onChange={handlInputChange}
                          >
                            {TiposLocaciones.map((tipo) => (
                              <MenuItem value={tipo.ID}>
                                {tipo.DESCRIPCION}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Col>

                        <Col sm={12} md={4} lg={6} className="p-3">
                          <TextField
                            fullWidth
                            name="nombre_contacto"
                            label="Nombre de contacto"
                            variant="outlined"
                            inputProps={{ maxLength: 200 }}
                            helperText={
                              Errores.nombre_contacto
                                ? "Nombre de contacto no valido"
                                : ""
                            }
                            value={Values.nombre_contacto}
                            error={Errores.nombre_contacto}
                            onChange={handlInputChange}
                          />
                        </Col>

                        <Col sm={12} md={4} lg={6} className="p-3">
                          <TextField
                            fullWidth
                            name="telefono_contacto"
                            label="Teléfono de contacto"
                            variant="outlined"
                            inputProps={{ maxLength: 10 }}
                            helperText={
                              Errores.telefono_contacto
                                ? "Teléfono de contacto no valido"
                                : ""
                            }
                            value={
                              Values.telefono_contacto == 0
                                ? ""
                                : Values.telefono_contacto
                            }
                            error={Errores.telefono_contacto}
                            onChange={handlInputChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col sm={12} className="mt-2">
                  <Row>
                    <Col sm={12} className="px-3 mt-3">
                      <h4 className="text-mutted"> Ubicación </h4>
                    </Col>

                    <Col sm={12} md={4} className="p-3">
                      <TextField
                        fullWidth
                        label="Calle"
                        variant="outlined"
                        inputProps={{ maxLength: 200 }}
                        name="calle"
                        helperText={Errores.calle ? "Calle no valida" : ""}
                        value={Values.calle}
                        error={Errores.calle}
                        onChange={handlInputChange}
                      />
                    </Col>
                    <Col sm={12} md={4} className="p-3">
                      <TextField
                        fullWidth
                        name="numero_exterior"
                        label="Número exterior"
                        variant="outlined"
                        inputProps={{ maxLength: 20 }}
                        helperText={
                          Errores.numero_exterior
                            ? "Número exterior no valido"
                            : ""
                        }
                        value={Values.numero_exterior}
                        error={Errores.numero_exterior}
                        onChange={handlInputChange}
                      />
                    </Col>
                    <Col sm={12} md={4} className="p-3">
                      <TextField
                        fullWidth
                        name="numero_interior"
                        label="Número interior"
                        variant="outlined"
                        inputProps={{ maxLength: 20 }}
                        helperText={
                          Errores.numero_interior
                            ? "Número interior no valido"
                            : ""
                        }
                        value={Values.numero_interior}
                        error={Errores.numero_interior}
                        onChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} md={4} className="p-3">
                      <TextField
                        fullWidth
                        name="colonia"
                        label="Colonia"
                        variant="outlined"
                        inputProps={{ maxLength: 200 }}
                        helperText={Errores.colonia ? "Colonia no valida" : ""}
                        value={Values.colonia}
                        error={Errores.colonia}
                        onChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} md={4} className="p-3">
                      <TextField
                        fullWidth
                        name="codigo_postal"
                        label="Código postal"
                        variant="outlined"
                        inputProps={{ maxLength: 5 }}
                        helperText={
                          Errores.codigo_postal ? "Código postal no valido" : ""
                        }
                        value={
                          Values.codigo_postal == 0 ? "" : Values.codigo_postal
                        }
                        error={Errores.codigo_postal}
                        onChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} md={4} className="p-3">
                      <Estado
                        Value={Values.id_estado}
                        Error={Errores.id_estado}
                        handlInputChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} md={4} className="p-3">
                      <Municipio
                        Estado={Values.id_estado}
                        Value={Values.id_municipio}
                        Error={Errores.id_municipio}
                        init={Values.init}
                        handlInputChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} md={8} className="p-3">
                      <TextField
                        fullWidth
                        name="referencia"
                        label="Referencias"
                        variant="outlined"
                        inputProps={{ maxLength: 200 }}
                        helperText={
                          Errores.referencia ? "Referencias no validas" : ""
                        }
                        value={Values.referencia}
                        error={Errores.referencia}
                        onChange={handlInputChange}
                      />
                    </Col>

                    <Col
                      sm={12}
                      className="p-3 d-flex justify-content-end pb-0"
                    >
                      <Button
                        className="btn btn-exp-Excel font-AvenirMedium px-3"
                        variant="text"
                        onClick={() => buscarDomicilio()}
                        disabled={disabledBtnUbicar}
                      >
                        {/* <img className="me-2" src={ExportarSucess} /> */}
                        Ubicar dirección en el mapa
                      </Button>
                    </Col>

                    <Col sm={12} className="pt-3">
                      {Values.id == "0" ? (
                        <Mapa
                        Zoom={18}
                          Center={PIN_CDMX}
                          Conf={CONF_MAPA}
                          MarKer={false}
                        />
                      ) : (
                        <Mapa
                          Zoom={18}
                          Center={Pin(
                            Values.ubicacion_lat,
                            Values.ubicacion_long,
                            true,
                            true
                          )}
                          Conf={CONF_MAPA}
                          MarKer={true}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarLocacion()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_LOCACION}
          Titulo="Cargar imagen de locación"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Locacion;
