import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
//import Textarea from '@mui/joy/Textarea';
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { guardarCategoria, getInfoCategoria } from "../Funciones/Categorias";

const MySwal = withReactContent(Swal);

const Categoria = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    descripcion: "",
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    descripcion: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    setValues({
      ...Values,
      [target.name]: target.value,
    });
    setErrores({
      ...Errores,
      [target.name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoCategoria(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarCategoria = () => {
    setIsGuardando(true);
    guardarCategoria(Values, Errores, setErrores)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Categorías
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar categoría" : "Nueva categoría"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  helperText={Errores.nombre ? "Nombre no valido" : ""}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={6} className="p-3">
                <TextField
                  multiline
                  fullWidth
                  name="descripcion"
                  label="Descripción"
                  maxRows={3}
                  inputProps={{ maxLength: 249 }}
                  value={Values.descripcion}
                  error={Errores.descripcion}
                  placeholder="Descripción"
                  helperText={
                    Errores.descripcion ? "Descripción no valida" : ""
                  }
                  onChange={handlInputChange}
                  style={{ width: "100%", height: "80px" }}
                />
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarCategoria()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Categoria;
