import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ExportarDanger from "./../../../assets/ExportarDanger.svg";
import ExportarSucess from "./../../../assets/ExportarSucess.svg";
import { Col, Row } from "react-bootstrap";
import TableCliente from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../Includes/SelectOrdenamiento";
import Buscador from "../../../Includes/Buscador";
import { getLista } from "../Funciones/Clientes";

const Clientes = () => {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  useEffect(() => {
    getListaCliente();
  }, [ValueBusqueda, ValueSelect]);

  const getListaCliente = () => {
    setIsLoading(true);
    getLista(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Clientes`;
  }, []);

  return (
    <>
      <div class="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Clientes</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            <Button
              className="btn btn-exp-Excel font-AvenirMedium"
              variant="text"
            >
              <img className="me-2" src={ExportarSucess} />
              Exportar Excel
            </Button>
            <Button
              className="btn  btn-exp-PDF  font-AvenirMedium"
              variant="text"
            >
              <img className="me-2" src={ExportarDanger} />
              Exportar PDF
            </Button>
            <Link to="./add" style={{ textDecoration: "none" }}>
              <Button className="btn btn-Crexendo px-4 " variant="contained">
                Crear nuevo
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar clientes por alias"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableCliente
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              ValueBusqueda={ValueBusqueda}
            ></TableCliente>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Clientes;
