import React, { useState, useEffect } from "react";
import { Alert, Snackbar, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, Tooltip } from "@mui/material";
import AccEditar from "./../../../assets/AccEditar.svg";
import AccEliminar from "./../../../assets/AccEliminar.svg";
import { Link } from "react-router-dom";
import Loading from "./../../../Includes/Loading";
import { Eliminar } from "../Funciones/Conceptos";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
//import { Tooltip } from "@mui/material";
import Switch from "./../../../Includes/Switch";
import { styled } from "@mui/material/styles";



const MySwal = withReactContent(Swal);

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function TableCliente({ Lista, IsLoading, setLista }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const [cantidad, setCantidad] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSliderChange = (name, value, i) => {
    // const filteredLibraries = Lista.filter((item, i) => {
    //     if (i == target.name) {
    //       item.CANTIDAD = Number(target.value);
    //       item.ERROR = false;
    //     }
    //   return item;
    // });
    // setLista(filteredLibraries);

    Lista[i].ERROR = false;
    if (name == "ACTUALIZAR") {
      Lista[i][name] = value;
    }
    else if (name == "CANTIDAD") {
      Lista[i][name] = Number(value);
    }
    else if (name == "COSTO_DE_COMPRA" || name == "PRECIO_DE_VENTA") {
      Lista[i][name] = value.toDecimal()
    }
    setLista([...Lista])
  };

  const EliminarRegistro = (id) => {
    new Swal({
      title: "¿Estas seguro de eliminar este producto?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          new Swal({
            title: "Exito!",
            text: "Eliminado correctamente",
            icon: "success",
          }).then(function () {
            eliminarClienteDeTabla(id);
          });
          /*Eliminar(id)
            .then((resp) => { 
              new Swal({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                eliminarClienteDeTabla(id);
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });*/
        }
      }
    });
  };

  // se elimina de la tabla en memoria para no consultar nuevamente toda la lista
  const eliminarClienteDeTabla = (id) => {
    const filteredLibraries = Lista.filter((item) => {
      return item.ID_PRODUCTO !== id;
    });
    setLista(filteredLibraries);
  };

  const onChange = (checked, id) => {
    //ActivarOrDesactivar(id, checked);
  };
  /*const ActivarOrDesactivar = (id, checked) => {
    if (id != undefined) {
      Desactivar(id, checked)
        .then((resp) => {
          console.log(resp);
          setSeverity("success");
          setMensaje("Cambios guardados correctamente");
          setOpen(true);
        })
        .catch((resp) => {
          setSeverity("error");
          setMensaje("Error al guardar los cambios");
          setOpen(true);
        });
    }
  };*/

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <TableContainer
        component={Paper}
        className="shadow-none"
        style={{ marginBottom: "35px" }}
      >
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                PRODUCTO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                CANTIDAD
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                COSTO DE COMPRA
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                PRECIO DE VENTA
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">

              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                COMISIÓN
              </TableCell>

              <TableCell className="font-Avenir font-AvenirBold" align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={4}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : Lista
              ).map((row, i) => (
                <TableRow key={row.ID}>
                  <TableCell component="th" scope="row">
                    {row.DESCRIPCION}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <TextField
                      name={"CANTIDAD"}
                      size="small"
                      variant="outlined"
                      type="number"
                      error={Lista[i].ERROR}
                      helperText={Lista[i].ERROR ? "Error" : ""}
                      value={Lista[i].CANTIDAD}
                      style={{ width: "78px", height: "5%" }}
                      onChange={({ target }) => {
                        handleSliderChange(target.name, target.value, i)
                      }}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <TextField
                      disabled={!Lista[i].ACTUALIZAR}
                      size="small"
                      name={"COSTO_DE_COMPRA"}
                      variant="outlined"
                      error={Lista[i].ERROR}
                      helperText={Lista[i].ERROR ? "Error" : ""}
                      value={Lista[i].COSTO_DE_COMPRA}
                      style={{ width: "128px", height: "5%" }}
                      onChange={({ target }) => {
                        handleSliderChange(target.name, target.value, i)
                      }}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <TextField
                      disabled={!Lista[i].ACTUALIZAR}
                      name={"PRECIO_DE_VENTA"}
                      size="small"
                      variant="outlined"
                      error={Lista[i].ERROR}
                      helperText={Lista[i].ERROR ? "Error" : ""}
                      value={Lista[i].PRECIO_DE_VENTA}
                      style={{ width: "128px", height: "5%" }}
                      onChange={({ target }) => {
                        handleSliderChange(target.name, target.value, i)
                      }}
                    />
                  </TableCell>


                  

                  <TableCell component="th" scope="row">
                    <Switch
                      checked={Lista[i].ACTUALIZAR}
                      onChange={(checked) => {
                        handleSliderChange("ACTUALIZAR", checked, i)
                      }}
                      tituloActivo="Actualizar "
                      tituloInactivo="No actualizar"
                      on
                    />
                    Actualizar precios
                  </TableCell>

                  <TableCell component="th" scope="row">
                    -
                  </TableCell>

                  <TableCell align="right">
                    <Tooltip title="Eliminar" placement="top">
                      <IconButton
                        onClick={() => EliminarRegistro(row.ID_PRODUCTO)}
                      >
                        <img src={AccEliminar} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
