import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Tooltip
} from "@mui/material"
import { Row } from "react-bootstrap"
import { useState } from "react";
import { EliminarProducto } from "../Funciones/Conceptos";

import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import AccEliminar from "./../../../../../assets/AccEliminar.svg";
import ModalProducto from "../../../../Productos/Componentes/ModalProducto";


const RepProductos = ({ IndexConcepto, Productos, Conceptos, SetConceptos, ProductosModificados, SetProductosModificados }) => {

    //Modal de productos
    const [openModalProductos, setOpenModalProductos] = useState(false);
    const handleOpenModalProductos = () => setOpenModalProductos(true);
    const handleCloseModalProductos = () => setOpenModalProductos(false);

    const handleChange = ({ name, value, IndexProducto }) => {
        const PRODUCTO = Conceptos[IndexConcepto].PRODUCTOS[IndexProducto]
        const NumeroActual = value.toDecimal()
        PRODUCTO[name] = isNaN(parseFloat(NumeroActual)) ? 0 : parseFloat(NumeroActual)

        if (name == "COSTO_DE_COMPRA" || name == "CANTIDAD") {
            let costo = 0
            Conceptos[IndexConcepto].PRODUCTOS.map(concepto => costo += (isNaN(parseFloat(concepto.COSTO_DE_COMPRA)) ? 0 : concepto.COSTO_DE_COMPRA) * concepto.CANTIDAD)
            Conceptos[IndexConcepto]["COSTO_DE_COMPRA"] = costo
        }
        if (name == "PRECIO_DE_VENTA" || name == "CANTIDAD") {
            let precio = 0
            Conceptos[IndexConcepto].PRODUCTOS.map(concepto => precio += (isNaN(parseFloat(concepto.PRECIO_DE_VENTA)) ? 0 : concepto.PRECIO_DE_VENTA) * concepto.CANTIDAD)
            Conceptos[IndexConcepto]["PRECIO_DE_VENTA"] = precio
        }
        SetConceptos([...Conceptos]);
    };

    const handleChangeCheck = ({ name, checked, IndexProducto }) => {
        const PRODUCTO = Conceptos[IndexConcepto].PRODUCTOS[IndexProducto];
        PRODUCTO.ACTUALIZAR = !PRODUCTO.ACTUALIZAR
        // Agregar a la lista de productos a modificar precio
        if (checked) {
            SetProductosModificados([...ProductosModificados, {
                ID: PRODUCTO.ID_PRODUCTO,
                PRECIO_DE_VENTA: isNaN(parseFloat(PRODUCTO.PRECIO_DE_VENTA)) ? 0 : parseFloat(PRODUCTO.PRECIO_DE_VENTA),
                COSTO_DE_COMPRA: isNaN(parseFloat(PRODUCTO.COSTO_DE_COMPRA)) ? 0 : parseFloat(PRODUCTO.COSTO_DE_COMPRA),
                DESCRIPCION: PRODUCTO.DESCRIPCION
            }])
        }
        // Sacar de la lista de productos a modificar precio
        else {
            const newListaProductos = Productos.map(x => x.ID != PRODUCTO.ID_PRODUCTO)
            SetProductosModificados(newListaProductos)
        }
        Conceptos[IndexConcepto].PRODUCTOS[IndexProducto][name] = checked;
        SetConceptos([...Conceptos]);

    };

    const fnAgregarProducto = (id, desk, costo, precio) => {
        const producto = Conceptos[IndexConcepto].PRODUCTOS.find(x => x.ID_PRODUCTO == id)
        if (producto) {
            producto.CANTIDAD += 1
        } else {
            const newProducto = {
                ID: 0,
                ID_PRODUCTO: id,
                CANTIDAD: 1,
                ID_CONCEPTO: 0,
                ID_REL_CONCEPTO: Conceptos[IndexConcepto].ID,
                DESCRIPCION: desk,
                COSTO_DE_COMPRA: costo,
                PRECIO_DE_VENTA: precio
            }
            Conceptos[IndexConcepto].PRODUCTOS = [newProducto, ...Conceptos[IndexConcepto].PRODUCTOS]
        }
        SetConceptos([...Conceptos])
    }

    const eliminarProductoDeTabla = (ID_PRODUCTO) => {
        Conceptos[IndexConcepto].PRODUCTOS = Conceptos[IndexConcepto].PRODUCTOS.filter(x => x.ID_PRODUCTO != ID_PRODUCTO);
        SetConceptos([...Conceptos]);
    }

    const eliminarProducto = (ID, ID_PRODUCTO) => {
        new Swal({
            title: "¿Estas seguro de eliminar este producto?",
            text: "esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {
            if (isConfirm.isConfirmed) {
                if (ID != undefined) {

                    if (ID == 0) {
                        // Tadavia esta en memoria ram
                        eliminarProductoDeTabla(ID_PRODUCTO)
                    } else {
                        // Esta guardado en la base de datos
                        EliminarProducto(ID).then((resp) => {
                            new Swal({
                                title: "Exito!",
                                text: "Eliminado correctamente",
                                icon: "success",
                            }).then(function () {
                                eliminarProductoDeTabla(ID_PRODUCTO)
                            });
                        }).catch((resp) => {
                            alert("Error al eliminar");
                        });
                    }

                }
            }
        });
    }

    // console.log("PRODUCTOS: ", Conceptos[IndexConcepto].PRODUCTOS)


    return (
        <>
            <Box className="p-0">

                <Row className="d-flex justify-content-end mx-0 py-3 pe-4">
                    <Button style={{ color: "#191919", maxWidth: "200px" }} onClick={handleOpenModalProductos}
                        className="btn  btn-Crexendo font-AvenirMedium d-flex"
                        variant="text"
                    >
                        <AddIcon className="me-2" /> Agregar producto
                    </Button>
                </Row>

                {

                    Productos.map((producto, IndexProducto) => (
                        <Row className="d-flex align-items-center py-3 mx-1" style={{ borderBottom: "1px solid #ddd" }}>
                            <strong className="m-0 mr-auto" style={{ maxWidth: "calc(100% - 640px)" }} >
                                {producto.DESCRIPCION}
                            </strong>
                            <FormControl style={{ maxWidth: '120px' }} className="me-4 ">
                                <InputLabel htmlFor="outlined-adornment-amount">
                                    Cantidad
                                </InputLabel>
                                <OutlinedInput
                                    size="small"
                                    startAdornment={
                                        <InputAdornment position="start"></InputAdornment>
                                    }
                                    value={producto.CANTIDAD}
                                    // disabled={true}
                                    name="CANTIDAD"
                                    label="Cantidad"
                                    placeholder="Cantidad"
                                    onChange={({ target }) => {
                                        handleChange({
                                            name: target.name,
                                            value: target.value,
                                            IndexProducto: IndexProducto
                                        })
                                    }}
                                />
                            </FormControl>
                            <FormControl style={{ maxWidth: '120px' }} className="me-2 ">
                                <InputLabel htmlFor="outlined-adornment-amount">
                                    Costo de compra
                                </InputLabel>
                                <OutlinedInput
                                    // disabled={!producto.ACTUALIZAR}
                                    startAdornment={
                                        <InputAdornment position="start">$</InputAdornment>
                                    }
                                    value={producto.COSTO_DE_COMPRA}
                                    name="COSTO_DE_COMPRA"
                                    size="small"
                                    helperText={producto.ERROR ? "Costo de compra no valido" : ""}
                                    error={producto.ERROR}
                                    label="Costo de compra"
                                    placeholder="Costo de compra"
                                    onChange={({ target }) => {
                                        handleChange({
                                            name: target.name,
                                            value: target.value,
                                            IndexProducto: IndexProducto
                                        })
                                    }}
                                />
                            </FormControl>
                            <FormControl style={{ maxWidth: '120px' }} className="me-2 ">
                                <InputLabel htmlFor="outlined-adornment-amount">
                                    Precio de venta
                                </InputLabel>
                                <OutlinedInput
                                    // disabled={!producto.ACTUALIZAR}
                                    startAdornment={
                                        <InputAdornment position="start">$</InputAdornment>
                                    }
                                    value={producto.PRECIO_DE_VENTA}
                                    name="PRECIO_DE_VENTA"
                                    size="small"
                                    helperText={producto.ERROR ? "Precio de venta no valido" : ""}
                                    error={producto.ERROR}
                                    label="Precio de venta"
                                    placeholder="Precio de venta"
                                    onChange={({ target }) => {
                                        handleChange({
                                            name: target.name,
                                            value: target.value,
                                            IndexProducto: IndexProducto
                                        })
                                    }}
                                />
                            </FormControl>
                            <FormControlLabel
                                style={{ maxWidth: '195px' }}
                                control={
                                    <Checkbox
                                        className="m-0 p-0 ms-3 me-2"
                                        name="ACTUALIZAR"
                                        checked={producto.ACTUALIZAR}
                                        onChange={({ target }) => {
                                            handleChangeCheck({
                                                name: target.name,
                                                checked: target.checked,
                                                IndexProducto: IndexProducto
                                            })
                                        }}
                                    />
                                }
                                label="Actualizar precio"
                            />

                            <Tooltip title="Eliminar" placement="top" style={{ maxWidth: "25px", marginLeft: "-10px" }}>
                                <IconButton
                                    onClick={() => eliminarProducto(producto.ID, producto.ID_PRODUCTO)}
                                >
                                    <img src={AccEliminar} />
                                </IconButton>
                            </Tooltip>
                        </Row>
                    ))
                }
            </Box>
            {
                openModalProductos && (
                    <ModalProducto
                        fnAgregar={fnAgregarProducto}
                        mostrar={openModalProductos}
                        fnCerrar={handleCloseModalProductos}
                        Lista={Conceptos[IndexConcepto].PRODUCTOS}
                    />
                )}
        </>
    )
}

export default RepProductos