import React from "react"; 
import Chart from 'react-apexcharts' 
const RadilBar = ({height=300, width="300" ,font = "lg", porcentaje})=> {


  var options = {
    chart: {
      height: height,
      type: "radialBar"
    },
    
    series: [porcentaje],
    
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: font == "lg" ? "60%" : "50%"
        },
       
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -10,
            show: font == "lg" ? true : false,
            color: "#888",
            fontSize: font == "lg" ? "10px" : "10px"
          },
          value: {
            offsetY: 0,
            color: "#111",
            fontSize: font == "lg" ? "25px" : "15px",
            show: true
          }
        } 
        
      }
    },
    fill: {
      colors: ['#FE8D27']
    },
    chart: {
      width: '100px'
  },
  
    
    labels: font == "lg" ? ["Avance"] :  [""],
  };



  return (
<>
<Chart font={font} options={options} series={options.series} type="radialBar"  height={height} width={width} />
</>
  );
}
export default RadilBar
