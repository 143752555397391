import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';

export const BasicDatePicker = ({ fecha, setFecha, label, fechaMin = "", fechaMax = "", format = "DD/MM/YYYY", size = "" }) => {


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-MX">
            <DatePicker className='w-100'                     
                label={label}
                value={dayjs(fecha)}
                views={['year', 'month', 'day']}
                inputFormat={format}
                onChange={(inputFecha) => { setFecha(inputFecha) }}
                renderInput={(params) => <TextField size={size} {...params} />}
                minDate={fechaMin == "" ? dayjs('1900-01-01') : dayjs(fechaMin)}
                maxDate={fechaMax == "" ? dayjs('2050-12-31') : dayjs(fechaMax)}

            />
        </LocalizationProvider>
    );
}