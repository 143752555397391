import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";

function PruebaResponsive() {
  /* const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" }); */
  const isDesktopOrLaptop = useMediaQuery("(min-width: 1224px)");
  const isBigScreen = useMediaQuery("(min-width: 1824px)");
  const isTabletOrMobile = useMediaQuery("(max-width: 1224px)");
  const isPortrait = useMediaQuery("(orientation: portrait)");
  const isRetina = useMediaQuery("(min-resolution: 2dppx)");

  return (
    <div>
      {isDesktopOrLaptop && <p>You are a desktop or laptop</p>}
      {isBigScreen && <p>You have a huge screen</p>}
      {isTabletOrMobile && <p>You are a tablet or mobile phone</p>}
      <p>Your are in {isPortrait ? "portrait" : "landscape"} orientation</p>
      {isRetina && <p>You are retina</p>}

      {isTabletOrMobile ? "true" : "false"}
    </div>
  );
}

export default PruebaResponsive;
