import { Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import RepEventos from "./Componentes/RepEventos";
import Evento from "./Componentes/Evento";


const routerEventos = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepEventos />} />
        <Route path="/add" element={<Evento />} />
        <Route path="/edit/:id" element={<Evento />} />
        {/* <Route path="/reporteevento/:id" element={<PDFEventos />} /> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default routerEventos