import {
  Alert,
  Button,
  Card,
  CardContent,
  FormLabel,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoCliente, Eliminar } from "../Funciones/Clientes";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Estado from "../../../Lib/Direcciones/Componentes/Estado";
import Municipio from "../../../Lib/Direcciones/Componentes/Municipio";
import RegimenFiscal from "../../../Lib/SAT/Componentes/RegimenFiscal";
import Switch from "./../../../Includes/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import "../../../Lib/Funciones Generales/Prototipos";

const MySwal = withReactContent(Swal);

const Cliente = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [activo, setActivo] = useState([]);
  const [severity, setSeverity] = useState("error");
  const [Values, setValues] = useState({
    id: id ?? "0",
    alias: "",
    correo: "",
    telefono: "",
    rfc: "",
    razon_social: "",
    cp: "",
    regimen_fiscal: "",
    domicilio: "",
    id_estado: "0",
    id_municipio: "0",
    requiere_factura: false,
    id_admin: "1",
    // activo: "1",
  });
  const [Errores, setErrores] = useState({
    alias: false,
    correo: false,
    telefono: false,
    rfc: false,
    razon_social: false,
    cp: false,
    regimen_fiscal: false,
    domicilio: false,
    id_estado: false,
    id_municipio: false,
    requiere_factura: false,
    id_admin: false,
    // activo: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "id_estado") {
      NewValue = { ...NewValue, id_municipio: 0 };
    } else if (Name == "cp" || Name == "telefono") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }
    if (Name == "requiere_factura") {
      NewValue = { ...NewValue, requiere_factura: Value };
    }

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoCliente(id, setValues)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarCliente = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Clientes
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar cliente" : "Nuevo cliente"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} className="p-3 d-flex justify-content-end">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Values.requiere_factura}
                        name="requiere_factura"
                        error={Errores.requiere_factura}
                        onChange={(checked) => {
                          handlInputChange({
                            target: {
                              name: "requiere_factura",
                              value: checked,
                            },
                          });
                        }}
                        tituloActivo="No requiere factura"
                        tituloInactivo="Requiere factura"
                        on
                      />
                    }
                    label="Requiere Factura"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="alias"
                  label="Nombre"
                  variant="outlined"
                  helperText={Errores.correo ? "Nombre invalido" : ""}
                  value={Values.alias}
                  error={Errores.alias}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="correo"
                  label="Correo electrónico"
                  variant="outlined"
                  error={Errores.correo}
                  helperText={Errores.correo ? "Correo incorrecto" : ""}
                  value={Values.correo}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="telefono"
                  label="Teléfono"
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  helperText={Errores.telefono ? "Teléfono inválido" : ""}
                  value={Values.telefono}
                  error={Errores.telefono}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="rfc"
                  label="RFC"
                  variant="outlined"
                  helperText={Errores.rfc ? "RFC invalido" : ""}
                  value={Values.rfc}
                  error={Errores.rfc}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="razon_social"
                  label="Razón social"
                  variant="outlined"
                  helperText={
                    Errores.razon_social ? "Razón social invalida" : ""
                  }
                  value={Values.razon_social}
                  error={Errores.razon_social}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="cp"
                  inputProps={{ maxLength: 5 }}
                  label="Código postal"
                  variant="outlined"
                  helperText={Errores.cp ? "Código postal invalido" : ""}
                  value={Values.cp}
                  error={Errores.cp}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <RegimenFiscal
                  Value={Values.regimen_fiscal}
                  Error={Errores.regimen_fiscal}
                  handlInputChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="domicilio"
                  label="Domicilio"
                  variant="outlined"
                  value={Values.domicilio}
                  error={Errores.domicilio}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <Estado
                  Value={Values.id_estado}
                  Error={Errores.id_estado}
                  handlInputChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <Municipio
                  Estado={Values.id_estado}
                  Value={Values.id_municipio}
                  Error={Errores.id_municipio}
                  init={Values.init}
                  handlInputChange={handlInputChange}
                />
              </Col>

              {/* <Col sm={12} md={4} className="p-3">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Requiere Factura
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="requiere_factura"
                        defaultChecked
                        error={Errores.requiere_factura}
                        checked={Values.requiere_factura}
                        onChange={handlInputChange}
                      />
                    }
                    label="Si requiere"
                  />
                </FormControl>
              </Col> */}
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarCliente()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Cliente;
