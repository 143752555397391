import { Avatar, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Eliminar } from "../Funciones/Personas"

import Loading from "../../../../../Includes/Loading";
import ModalEmpleado from "../../../../Empleados/Componentes/ModalEmpleados";
import AccEliminar from "./../../../../../assets/AccEliminar.svg";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";


const RepPersonas = ({ Empleados, SetEmpleados }) => {

    const fnCerrarModal = () => {
        setModalEmpleados(false)
    }
    const [openModalEmpleados, setModalEmpleados] = useState(false)

    const fnAgregar = (ID_EMPLEADO, IMG, DESCRIPCION, PUESTO) => {
        const existe = Empleados.find(x => x.ID_EMPLEADO == ID_EMPLEADO)
        const Empleado = {
            ID: 0,
            ID_EMPLEADO: ID_EMPLEADO,
            IMG: IMG,
            PUESTO: PUESTO,
            DESCRIPCION: DESCRIPCION
        }
        if (!existe) {
            SetEmpleados([...Empleados, Empleado])
        }
    }

    const eliminarEmpleadoDeTabla = (ID_EMPLEADO) => {
        const newLista = Empleados.filter(Empleado => Empleado.ID_EMPLEADO != ID_EMPLEADO);
        SetEmpleados(newLista);
    }

    const eliminarEmpleado = (ID, ID_EMPLEADO) => {
        new Swal({
            title: "¿Estas seguro de eliminar este empleado?",
            text: "esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {
            if (isConfirm.isConfirmed) {
                if (ID != undefined) {

                    if (ID == 0) {
                        // Tadavia esta en memoria ram
                        eliminarEmpleadoDeTabla(ID_EMPLEADO)
                    } else {
                        // Esta guardado en la base de datos
                        Eliminar(ID).then((resp) => {
                            new Swal({
                                title: "Exito!",
                                text: "Eliminado correctamente",
                                icon: "success",
                            }).then(function () {
                                eliminarEmpleadoDeTabla(ID_EMPLEADO)
                            });
                        }).catch((resp) => {
                            alert("Error al eliminar");
                        });
                    }

                }
            }
        });
    }


    return (
        <>
            <Row className="p-3">

                <Col m={12} md={12} justifyContent="flex-end" className="mb-4">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="baseline"
                    >
                        <Button
                            onClick={() => setModalEmpleados(true)}
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                            variant="contained"
                            al
                            style={{ width: "140px", height: "40px", align: "right" }}
                        >
                            <span className={"px-2"}>Agregar</span>
                            <AddIcon />
                        </Button>
                    </Grid>
                </Col>

                <Col sm={12} >
                    <TableContainer component={Paper} style={{ border: "0px", boxShadow: "none" }}>
                        <Table aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="font-Avenir font-AvenirBold" align="let">
                                        IMAGEN
                                    </TableCell>
                                    <TableCell className="font-Avenir font-AvenirBold" align="left">
                                        PERSONA
                                    </TableCell>
                                    <TableCell className="font-Avenir font-AvenirBold" align="left">
                                        PUESTO
                                    </TableCell>
                                    <TableCell className="font-Avenir font-AvenirBold" align="right">
                                        ACCIONES
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    Empleados.length != 0 ? (
                                        Empleados.map(Empleado => (
                                            <TableRow key={Empleado.ID_EMPLEADO}>
                                                <TableCell component="td" scope="row">
                                                    <Avatar className="shadow"
                                                        sx={{ width: 50, height: 50 }}
                                                        src={Empleado.IMG}
                                                    />
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    {Empleado.DESCRIPCION}
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    {Empleado.PUESTO}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Eliminar" placement="top">
                                                        <IconButton
                                                            onClick={() => eliminarEmpleado(Empleado.ID, Empleado.ID_EMPLEADO)}
                                                        >
                                                            <img src={AccEliminar} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={5} align="center">
                                                Sin empleados
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Col>
            </Row>

            {
                openModalEmpleados && (
                    <ModalEmpleado
                        mostrar={openModalEmpleados}
                        fnCerrar={fnCerrarModal}
                        fnAgregar={fnAgregar}
                        EmpleadosAgregados={Empleados} />
                )
            }

        </>
    )
}

export default RepPersonas
