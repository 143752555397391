import { Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import Ubicacion from "../../Lib/Mapa/Componentes/Ubicacion";
import Proveedor from './Componentes/Proveedor'
import RepProveedores from './Componentes/RepProveedores'

const routerProveedores = () => {
  return (
    <Routes>
      <Route index element={<RepProveedores />} />
      <Route path="/add" element={<Proveedor />} />
      <Route path="/edit/:id" element={<Proveedor />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}

export default routerProveedores