import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoProducto } from "../Funciones/Productos";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import UnidadMedidad from "../../../Lib/SAT/Componentes/UnidadMedidad";
import ProductoServicio from "../../../Lib/SAT/Componentes/ProductoServicio";
import Categoria from "../../Categorias/Componentes/SelectCategorias";
import {
  TiposProductos,
  Monedas,
  Impuestos,
} from "../../../Lib/Funciones Generales/Constantes";
import UnidadMedidaInterna from "./SelectUnidadMedidaInterna";
import "../../../Lib/Funciones Generales/Prototipos";
import HelperError from "../../../Includes/HelperError";

const MySwal = withReactContent(Swal);
const Producto = () => {
  //Variables del formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    activo: "1",
    codigo: "",
    descripcion: "",
    precio_de_venta: "",
    iva: "16",
    clave_unidad_medida_sat: "",
    clave_codigo_prod_sat: "",
    tipo_de_producto: "0",
    costo_de_compra: "",
    unidad_de_medida: "",
    activo: "",
    moneda: "MXN",
    descuento: "",
    nom_clave_producto_servicio: "",
    nom_clave_unidad_medida: "",
    id_categoria: "0",
  });
  const [Errores, setErrores] = useState({
    codigo: false,
    descripcion: false,
    precio_de_venta: false,
    iva: false,
    clave_unidad_medida_sat: false,
    clave_codigo_prod_sat: false,
    tipo_de_producto: false,
    costo_de_compra: false,
    unidad_de_medida: false,
    activo: false,
    moneda: false,
    descuento: false,
    nom_clave_producto_servicio: false,
    nom_clave_unidad_medida: false,
    id_categoria: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    if (
      Name == "costo_de_compra" ||
      Name == "precio_de_venta" ||
      Name == "descuento"
    ) {
      Value = Value.toDecimal();
    }

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoProducto(id)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarProducto = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            console.log("I was closed by the timer");
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Productos
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar producto" : "Nuevo producto"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} md={8} className="p-3">
                <TextField
                  fullWidth
                  label="Descripción del producto o servicio"
                  variant="outlined"
                  name="descripcion"
                  helperText={
                    Errores.descripcion ? "Descripción no valido" : ""
                  }
                  value={Values.descripcion}
                  error={Errores.descripcion}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  label="Código"
                  variant="outlined"
                  name="codigo"
                  helperText={Errores.codigo ? "Código no valido" : ""}
                  value={Values.codigo}
                  error={Errores.codigo}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <Categoria
                  Value={Values.id_categoria}
                  Error={Errores.id_categoria}
                  handlInputChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  select
                  label="Tipo de producto"
                  name="tipo_de_producto"
                  helperText={
                    Errores.tipo_de_producto ? "Tipo de producto no valido" : ""
                  }
                  value={Values.tipo_de_producto}
                  error={Errores.tipo_de_producto}
                  onChange={handlInputChange}
                >
                  {TiposProductos.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>

              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth helperText="ERROR">
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Costo de compra
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="costo_de_compra"
                    value={Values.costo_de_compra}
                    error={Errores.costo_de_compra}
                    label="Costo de compra"
                    placeholder="Costo de compra sin IVA"
                    onChange={handlInputChange}
                  />
                </FormControl>
                {
                  <HelperError Error={Errores.costo_de_compra} Mensaje={"Costo de compra no valido"} />
                }
              </Col>

              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Precio de venta
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="precio_de_venta"
                    value={Values.precio_de_venta}
                    error={Errores.precio_de_venta}
                    label="Precio de venta"
                    placeholder="Precio de venta sin IVA"
                    onChange={handlInputChange}
                  />
                </FormControl>
                {
                  <HelperError Error={Errores.precio_de_venta} Mensaje={"Precio de venta no valido"} />
                }
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  id="Moneda"
                  select
                  label="Moneda"
                  name="moneda"
                  helperText={Errores.moneda ? "Moneda no valida" : ""}
                  value={Values.moneda}
                  error={Errores.moneda}
                  onChange={handlInputChange}
                >
                  {Monedas.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  select
                  label="Impuestos"
                  name="iva"
                  helperText={Errores.iva ? "Impuesto no valido" : ""}
                  value={Values.iva}
                  error={Errores.iva}
                  onChange={handlInputChange}
                >
                  {Impuestos.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>

              <Col sm={12} md={4} className="p-3">
                <ProductoServicio
                  Value={Values.clave_codigo_prod_sat}
                  Error={Errores.clave_codigo_prod_sat}
                  handlInputChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <UnidadMedidad
                  Value={Values.clave_unidad_medida_sat}
                  Error={Errores.clave_unidad_medida_sat}
                  handlInputChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth error={Errores.calle}>
                  <InputLabel
                    htmlFor="outlined-adornment-amount"
                    error={Errores.calle}
                  >
                    Descuento
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">%</InputAdornment>
                    }
                    label="Descuento"
                    placeholder="Porcentaje de descuento"
                    inputProps={{ maxLength: 200 }}
                    name="descuento"
                    helperText={Errores.descuento ? "Descuento no valido" : ""}
                    value={Values.descuento}
                    error={Errores.descuento}
                    onChange={handlInputChange}
                  />
                </FormControl>
                {
                  <HelperError Error={Errores.descuento} Mensaje={"Descuento no valido"} />
                }
              </Col>

              <Col sm={12} md={4} className="p-3">
                <UnidadMedidaInterna
                  Value={Values.unidad_de_medida}
                  Error={Errores.unidad_de_medida}
                  handlInputChange={handlInputChange}
                />
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarProducto()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Producto;
