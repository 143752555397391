import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { IconButton, Stack } from "@mui/material";
import TableConceptos from "./Conceptos";
import { getLista, guardar } from "../Funciones/CapturaAvences";
import { getTiempoFromMinutos } from "../../../Lib/Funciones Generales/Generales";
import { getPorcentaje } from "../../../Lib/Funciones Generales/Generales";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Alert, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModalCaptura = ({ open, setOpen, id, changuePorcentaje }) => {
  const [fullWidth, setFullWidth] = useState(true);
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [avances, setAvances] = useState([]);
  const [avance, setAvance] = useState(0);
  const [evento, setEvento] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const maxWidth = "xl";
  const MySwal = withReactContent(Swal);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (id != 0) {
      setIsLoading(true);
      if (id != undefined) {
        getLista(id)
          .then((resp) => {
            const con = JSON.parse(resp.conceptos);
            let TotalAvence = 0;
            let Cont = 0;
            const ListaFiltrado = con.filter((item) => {
              item.MIN = item.PORCENTAJE;
              item.POR_CANTIDAD = item.CANT_AVANCE;
              item.CAMBIO = false;
    //  TotalAvence += item.PORCENTAJE; 
              TotalAvence +=  (item.PORCENTAJE * item.POR_PROD)/100 ;
           
              Cont++;
              return item;
            });
            setAvances(ListaFiltrado);
            setEvento(JSON.parse(resp.evento));
            setIsLoading(false);
            setAvance(parseFloat(TotalAvence ));
          })
          .catch((resp) => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  const GuardarAvances = () => {
    setIsGuardando(true);
    guardar(avances, id)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1200,
          }).then((result) => {
            setOpen(false);
            changuePorcentaje(id,avance)
          });
        } else {
          setMensaje(data.mensaje);
          setOpenAlert(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpenAlert(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <div className="position-absolute" style={{ right: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {IsLoading ? (
          <></>
        ) : (
          <DialogTitle id="scroll-dialog-title p-1">
            <Stack
              justifyContent="space-between"
              fullWidth
              flexWrap={"wrap"}
              direction={"row"}
              className="mb-4"
            >
              <div className="ms-0  ">
                <h5
                  className="mb-0 text-left font-AvenirBold"
                  style={{ color: "#151916" }}
                >
                  {IsLoading ? (
                    <h1 className="parpadea">...</h1>
                  ) : evento[0].NOMBRE_EVENTO ? (
                    evento[0].NOMBRE_EVENTO
                  ) : (
                    "--"
                  )}
                </h5>
                <h6
                  className="mb-0 text-left font-Avenir"
                  style={{ color: "#65748B" }}
                >
                  {IsLoading ? (
                    <h1 className="parpadea">...</h1>
                  ) : evento[0].NOMBRE_LOCACION ? (
                    evento[0].NOMBRE_LOCACION
                  ) : (
                    "--"
                  )}
                </h6>
              </div>

              <div className="ms-0  ">
                <h6
                  className="mb-0 text-center font-AvenirMedium"
                  style={{ color: "#151916" }}
                >
                  {IsLoading ? (
                    <h1 className="parpadea">...</h1>
                  ) : evento[0].TIEMPO_ULTIMA_CAPTURA ? (
                    evento[0].TIEMPO_ULTIMA_CAPTURA == "" ? (
                      "Aún no han capturado avances"
                    ) : (
                      "  Útima captura hace " +
                      getTiempoFromMinutos(evento[0].TIEMPO_ULTIMA_CAPTURA)
                    )
                  ) : (
                    "Aún no han capturado avances"
                  )}
                </h6>
                <h6
                  className="mb-0 text-center font-Avenir"
                  style={{ color: "#65748B" }}
                >
                  {IsLoading ? (
                    <h1 className="parpadea">...</h1>
                  ) : evento[0].ULTIMO_USUARIO ? (
                    evento[0].ULTIMO_USUARIO
                  ) : (
                    ""
                  )}
                </h6>
              </div>
              <div>
                <div
                  className=" progress position-relative me-5"
                  style={{ backgroundColor: "#c9c9c9", width: "200px" }}
                >
                  <div
                    className={
                      getPorcentaje(avance) +
                      " progress-bar progress-bar-striped progress-bar-animated"
                    }
                    role="progressbar"
                    style={{ width: avance + "%" }}
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <small className="justify-content-center d-flex position-absolute w-100 text-white fw-bold">
                    {avance.toFixed(2)}%
                  </small>
                </div>
              </div>
            </Stack>
          </DialogTitle>
        )}
        <DialogContent>
          <Box>
            <TableConceptos
              avances={avances}
              setAvance={setAvance}
              setAvances={setAvances}
              IsLoading={IsLoading}
            ></TableConceptos>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
             loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={GuardarAvances}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
              {IsGuardando ? "Guardando..." : " Guardar avances"}
               </span>
            </LoadingButton>
 

            <Button
              onClick={handleClose}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalCaptura;
