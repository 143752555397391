import { Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import RepConceptos from "./Componentes/RepConceptos";
import Concepto from "./Componentes/Concepto";

const routerConcepto = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepConceptos />} />
        <Route path="/add" element={<Concepto />} />
        <Route path="/edit/:id" element={<Concepto />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerConcepto;
