import { Autocomplete, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { getOpciones } from "../Funciones/Locaciones";


const Locacion = ({ Value, Error, handlInputChange, Name = "id_locacion" }) => {
  const opcionInicial = { ID: 0, DESCRIPCION: "Locación" }
  const [ValorFiltrado, setFiltro] = useState()
  const [Locaciones, setLocaciones] = useState({ cargado: false, lista: [opcionInicial] });

  useEffect(() => {
    getOpciones()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          setLocaciones({ cargado: true, lista: list });
        }
        else {
          setLocaciones({ cargado: true, lista: [{ ID: 0, DESCRIPCION: "Sin locaciones disponibles" }] });
        }
      })
      .catch((resp) => {
        setLocaciones({ cargado: true, lista: [{ ID: 0, DESCRIPCION: "Sin locaciones disponibles" }] });
      });
  }, [])

  const getOpcion = () => {
    const opcion = Locaciones.lista.find(x => x.ID == Value)
    if (opcion) {
      return opcion
    } else {
      return null
    }
  }

  return (
    Locaciones.cargado ? (
      <Autocomplete
        fullWidth
        defaultValue={getOpcion}
        isOptionEqualToValue={(option, value) => {
          return option.ID == value.ID
        }}
        inputValue={ValorFiltrado}
        onChange={(e, value) => {
          if (value) {
            handlInputChange({ target: { name: Name, value: value.ID } })
          }
          else {
            handlInputChange({ target: { name: Name, value: "" } })
          }
        }}
        onInputChange={(event, newInputValue) => {
          setFiltro(newInputValue);
        }}
        options={Locaciones.lista}
        getOptionLabel={(option) => {
          return option.DESCRIPCION
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={Error}
              helperText={Error ? "Selecciona una locación" : ""}
              label="Locación"
            />
          )
        }}
      />
    ) : (
      <TextField
        fullWidth
        disabled={true}
        value={"Locación"}
      />
    )
  )
}

export default Locacion