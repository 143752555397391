import {
    Alert,
    Button,
    Card,
    CardContent,
    Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import Mapa from "./Mapa";
import { Pin } from "../Funciones/Mapa";

const Ubicacion = () => {
    const LAT = 0, LNG = 1
    const { poscion } = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [mensaje] = useState("");
    const error = false

    if (!poscion) {
        // return <Error404 />
        error = true
    }

    const pin = poscion.split(",")
    if (!pin) {
        // return <Error404 />
        error = true
    }
    if (!pin[LAT] || !pin[LNG]) {
        // return <Error404 />
        error = true
    }

    const handleClose = () => {
        setOpen(false);
    };

    const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
        ({ theme }) => ({
            "& .MuiSnackbar-root": {
                top: theme.spacing(15),
            },
        })
    );

    return (
        <>
            <StyledSnackbar
                direction="right"
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                    {mensaje}
                </Alert>
            </StyledSnackbar>
            <Button
                onClick={() => navigate(-1)}
                className="btn font-AvenirBold my-4 text-star ps-0 pe-4  "
                variant="text"
            >
                <ArrowBackIcon className="me-3" />
                Locaciones
            </Button>
            <Card elevation={3}>
                <CardContent className="p-0">
                    {
                        error ? <Error404 /> : <Mapa Center={Pin(pin[LAT], pin[LNG], false)} Height="calc(100vh - 180px)" />
                    }
                </CardContent>
            </Card>
        </>
    );
}

export default Ubicacion