import { Autocomplete, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { getOpciones } from "../Funciones/Puestos";


const Puesto = ({ Value, Error, handlInputChange, Name = "id_puesto" }) => {
  const opcionInicial = { ID: 0, NOMBRE: "Puesto" }
  const [ValorFiltrado, setFiltro] = useState()
  const [Puesto, setPuesto] = useState({ cargado: false, lista: [opcionInicial] });

  useEffect(() => {
    getOpciones()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          setPuesto({ cargado: true, lista: list });
        }
        else {
          setPuesto({ cargado: true, lista: [{ ID: 0, NOMBRE: "Sin puestos disponibles" }] });
        }
      })
      .catch((resp) => {
        setPuesto({ cargado: true, lista: [{ ID: 0, NOMBRE: "Sin puestos disponibles" }] });
      });
  }, [])

  const getOpcion = () => {
    const opcion = Puesto.lista.find(x => x.ID == Value)
    if (opcion) {
      return opcion
    } else {
      return null
    }
  }

  return (
    Puesto.cargado ? (
      <Autocomplete
        fullWidth
        defaultValue={getOpcion}
        isOptionEqualToValue={(option, value) => {
          return option.ID == value.ID
        }}
        inputValue={ValorFiltrado}
        onChange={(e, value) => {
          if (value) {
            handlInputChange({ target: { name: Name, value: value.ID } })
          }
          else {
            handlInputChange({ target: { name: Name, value: "" } })
          }
        }}
        onInputChange={(event, newInputValue) => {
          setFiltro(newInputValue);
        }}
        options={Puesto.lista}
        getOptionLabel={(option) => {
          return option.NOMBRE
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={Error}
              helperText={Error ? "Selecciona un puesto" : ""}
              label="Puesto"
            />
          )
        }}
      />
    ) : (
      <TextField
        fullWidth
        disabled={true}
        value={"Puesto"}
      />
    )
  )
}

export default Puesto