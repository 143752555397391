import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tabs,
  Box,
  Tab,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardarEvento, getInfoEvento } from "../Funciones/Eventos";

import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Clientes from "../../Clientes/Componentes/SelectClientes";
import Locacion from "../../Locaciones/Componentes/SelectLocaciones";
import Planner from "../../Planners/Componentes/SelectPlanners"
import RepHorarios from "./Horarios/Componentes/RepHorarios";


import { BasicDatePicker } from '../../../Includes/DatePicker';
import { ConvertDate } from '../../../Lib/Funciones Generales/ObtenerFecha';
import RepConceptos from "./Conceptos/Componentes/RepConceptos";
import '../../../Lib/Funciones Generales/Prototipos'

import { getListaTemplateHorario } from "./Horarios/Funciones/Horarios";
import { getListaTemplateConcepto } from "./Conceptos/Funciones/Conceptos";
import Empleados from "../../Empleados/Componentes/SelectEmpleados";
import RepPersonas from "./Personas/Componentes/RepPersonas";
import HelperError from "../../../Includes/HelperError";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { GetDate } from '../../../Lib/Funciones Generales/ObtenerFecha';
import { getTiempoFromMinutos } from "../../../Lib/Funciones Generales/Generales";

const MySwal = withReactContent(Swal)

const Evento = () => {

  // const DIAS = 1000 * 60 * 60 * 24;  // No se ocupa en días  
  const HORAS = 1000 * 60 * 60;
  // const MINUTOS = 1000 * 60;         // No se ocupa en minutos
  // const SEGUNDOS = 1000;             // No se ocupa en segundos 
  const [tiempo, setTiempo] = useState({ texto: '00:00:00', horas: 0 });



  const [fecha_evento, setFechaEvento] = useState(GetDate());
  const [tab, setTab] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };



  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");


  const [Values, SetValues] = useState({
    id: id ?? "0",
    activo: "1",
    nombre: "",
    fecha_evento: GetDate(),
    presupuesto: "",
    id_cliente: "0",
    id_planner: "0",
    id_locacion: "0",
    id_encargado: "0"
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    fecha_evento: false,
    presupuesto: false,
    id_cliente: false,
    id_planner: false,
    id_locacion: false,
    id_encargado: false,
  });


  const [Horarios, SetHorarios] = useState(getListaTemplateHorario(fecha_evento))
  const [Conceptos, SetConceptos] = useState(getListaTemplateConcepto())
  const [EmpleadosInvolucrados, SetEmpleadosInvolucrados] = useState([])
  const [ProductosModificados, SetProductosModificados] = useState([])


  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name
    let Value = target.value
    if (Name == "presupuesto") {
      Value = Value.toDecimal();
    }
    SetValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoEvento(id)
        .then((res) => {
          let horarios = Horarios
          let personas = []
          let conceptos = []
          if (res.horarios.length != 0) {
            horarios = res.horarios
          }
          if (res.conceptos.length != 0) {
            conceptos = res.conceptos

            const newConceptos = conceptos.map(concepto => {
              concepto.PRODUCTOS = JSON.parse(concepto.PRODUCTOS);
              concepto.PERSONAL_REQ =  0;
              return concepto
            })
            conceptos = newConceptos

          }

          // console.log("CONCEPTOS: ", conceptos)

          if (res.personas.length != 0) {
            personas = res.personas
          }





          setFechaEvento(res.evento.fecha_evento)
          SetValues(res.evento)
          SetHorarios(horarios)
          SetConceptos(conceptos)
          SetEmpleadosInvolucrados(personas)
          setIsLoading(false);
        })
        .catch((resp) => {
          setIsLoading(false);
        });
    }
    else {
      setIsLoading(false);
    }
  }, []);


  useEffect(() => {
    if (!IsLoading) {
      if (fecha_evento != "") {
        SetValues({ ...Values, fecha_evento: ConvertDate(fecha_evento) })
        setErrores({ ...Errores, fecha_evento: false })
      }
    }
  }, [fecha_evento]);

  const GuardarEvento = () => {
    setIsGuardando(true);
    guardarEvento(Values, Horarios, Conceptos, EmpleadosInvolucrados, ProductosModificados, Errores, setErrores, SetHorarios)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: 'success',
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700
          })
            .then((result) => {
              //navigate(-1);
              if (window.location.href.includes("add")) {
                window.location.href = "../eventos"
              } else if (window.location.href.includes("edit")) {
                window.location.href = "../"
              }

            })

        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };


  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );


  // CALCULAR TIEMPO DEL EVENTO
  useEffect(() => {
    let totalTiempoHrs = 0;
    Horarios.map(horario => {
      const fin = new Date(horario.FECHA_HORARIO + ' ' + horario.FIN + ':00')
      const inicio = new Date(horario.FECHA_HORARIO + ' ' + horario.INICIO + ':00')
      totalTiempoHrs += (fin - inicio) / HORAS
    })
    setTiempo({
      texto: getTiempoFromMinutos(totalTiempoHrs * 60),
      horas: totalTiempoHrs
    })
  }, [Horarios])

  const ErrorFecha = (Errores.fecha_evento || Values.fecha_evento == "" || Values.fecha_evento == null) ? true : false;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Eventos
      </Button>

      <Box className=" mb-2">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#FE8D27",
            style: {
              backgroundColor: "#FE8D27",
              height: "5px",
              borderRadius:"2px"
            }
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"

          indicatorColor=""
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos generales" className="font-AvenirBold" />
          <Tab label="Horarios" className="font-AvenirBold" />
          <Tab label="Conceptos" className="font-AvenirBold" />
          <Tab label="Personas involucradas" className="font-AvenirBold" />
        </Tabs>
      </Box>

      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
        <div className="p-3 d-flex justify-content-between">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar evento" : "Nuevo evento"}
            </h6> 
            <div>
            <h6 className="ms-3 font-font-AvenirMedium"> Tiempo total para el montaje: {tiempo.horas.toFixed(2)} Horas</h6>
         
            </div>
        
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>


              <Row className="p-3">

                {
                  tab == 0 ? (
                    <>
                      <Col sm={12} md={4} className="p-3">
                        <TextField
                          fullWidth
                          name="nombre"
                          label="Nombre del evento"
                          variant="outlined"
                          value={Values.nombre}
                          error={Errores.nombre}
                          helperText={Errores.nombre ? "Nombre del evento no valido" : ""}
                          onChange={handlInputChange}
                        />
                      </Col>

                      <Col sm={12} md={4} className="p-3">
                        <BasicDatePicker
                          format="DD/MM/YYYY"
                          label="Fecha del evento"
                          fecha={fecha_evento}
                          setFecha={setFechaEvento}
                        />
                        {
                          <HelperError Error={ErrorFecha} Mensaje="Fecha del evento invalida" />
                        }
                      </Col>

                      <Col sm={12} md={4} className="p-3">
                        <Empleados
                          IsEncargado={true}
                          Value={Values.id_encargado}
                          Name="id_encargado"
                          Error={Errores.id_encargado}
                          handlInputChange={handlInputChange} />
                      </Col>

                      <Col sm={12} md={4} className="p-3">
                        <Planner Value={Values.id_planner}
                          Name="id_planner"
                          Error={Errores.id_planner}
                          handlInputChange={handlInputChange} />
                      </Col>

                      <Col sm={12} md={4} className="p-3">
                        <Locacion Value={Values.id_locacion}
                          Name="id_locacion"
                          Error={Errores.id_locacion}
                          handlInputChange={handlInputChange} />
                      </Col>

                      <Col sm={12} md={4} className="p-3">
                        <Clientes Value={Values.id_cliente}
                          Name="id_cliente"
                          handlInputChange={handlInputChange} />
                      </Col>

                      <Col sm={12} md={4} className="p-3">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="outlined-adornment-amount">
                            Presupuesto
                          </InputLabel>
                          <OutlinedInput
                            startAdornment={
                              <InputAdornment position="start">$</InputAdornment>
                            }
                            name="presupuesto"
                            value={Values.presupuesto}
                            error={Errores.presupuesto}
                            label="Presupuesto"
                            placeholder="Presupuesto"
                            onChange={handlInputChange}
                          />
                        </FormControl>
                        {
                          <HelperError Error={Errores.presupuesto} Mensaje="Presupuesto invalido" />
                        }
                      </Col>
                    </>
                  ) : (
                    tab == 1 ? (

                      <RepHorarios
                        FechaEvento={fecha_evento}
                        Horarios={Horarios}
                        SetHorarios={SetHorarios}
                        Evento={id} />

                    ) : tab == 2 ? (

                      <RepConceptos
                      tiempo ={tiempo}
                      Horarios ={Horarios }
                        Conceptos={Conceptos}
                        SetConceptos={SetConceptos}
                        ProductosModificados={ProductosModificados}
                        SetProductosModificados={SetProductosModificados}
                        Evento={id} />

                    ) : (

                      <RepPersonas
                        placeholder
                        Empleados={EmpleadosInvolucrados}
                        SetEmpleados={SetEmpleadosInvolucrados}
                        Evento={id} />

                    )
                  )
                }

              </Row>

            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarEvento()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>

            <Button
              onClick={() => {
                const nextTab = (tab - 1) < 0 ? 0 : (tab - 1);
                setTab(nextTab)
              }}
              className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-auto"
              variant="text"
            >
              <ArrowBackIosNewIcon style={{ fontSize: '20px' }} />
              Anterior
            </Button>

            <Button
              onClick={() => {
                const nextTab = (tab + 1) > 3 ? 3 : (tab + 1);
                setTab(nextTab)
              }}
              className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-n2"
              variant="text"
            >
              Siguiente
              <ArrowForwardIosIcon style={{ fontSize: '20px' }} />
            </Button>
          </Stack>
        </CardContent>
      </Card>



      {/* 
      <h6> Formulario </h6>
      <code>
        <pre>
          {
            JSON.stringify(Values, null, 2)
          }
        </pre>
      </code>

      <h6> Horarios </h6>
      <code>
        <pre>
          {
            JSON.stringify(Horarios, null, 2)
          }
        </pre>
      </code>

      <h6> Conceptos </h6>
      <code>
        <pre>
          {
            JSON.stringify(Conceptos, null, 2)
          }
        </pre>
      </code>

      <h6> Personas </h6>
      <code>
        <pre>
          {
            JSON.stringify(EmpleadosInvolucrados, null, 2)
          }
        </pre>
      </code> 
      */}
    </>
  );
};

export default Evento;
