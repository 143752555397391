import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Button, Col, Table } from 'react-bootstrap';
import { getOpciones } from "../Funciones/Empleados";

import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import Loading from "../../../Includes/Loading";
import CloseIcon from '@mui/icons-material/Close';

const ModalEmpleado = ({
    mostrar = false,
    fnAgregar = (id, img, nombre, puesto) => { },
    fnCerrar = () => { },
    EmpleadosAgregados
}) => {

    const [{ lista, cargando, query }, setEmpleados] = useState({
        lista: [],
        cargando: false,
        query: "",
    })

    const consultar = (valor, consultarTop, animar) => {
        if(animar){
            setEmpleados({ lista: lista, cargando: true, query: query })
        }
        setTimeout(() => {
            getOpciones(valor, consultarTop ? 10 : 0).then((resp => {
                let list = JSON.parse(resp.data);
                setEmpleados({ cargando: false, lista: list })
            }))
                .catch(resp => {
                    setEmpleados({ cargando: false, lista: [] })
                });
        }, 1000)

    }

    const escribir = ({ target }) => {
        setEmpleados({ lista: lista, cargando: true, query: query })
        clearInterval(window.intervalo)
        window.intervalo = setInterval(function () {
            consultar(target.value, target.value == "" ? true : false, false)
            clearInterval(window.intervalo)
        }, 1000)
    }

    useEffect(() => {
        consultar("", true, true)
    }, [])


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={mostrar}
            onClose={fnCerrar}
        >

            <div className="position-absolute" style={{ "right": 0 }} >
                <IconButton onClick={fnCerrar} >
                    <CloseIcon />
                </IconButton>
            </div>

            <DialogTitle>
                <div className="mt-4">
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-amount">
                            Empleado
                        </InputLabel>
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            label="Empleado"
                            placeholder="Buscar empleado por nombre y apellidos..."
                            onChange={escribir}
                        />
                    </FormControl>
                </div>
            </DialogTitle>

            <DialogContent className="p-0 m-0" id="ModalConceptos">
                <Col sm={12} className="px-3" >
                    <Table aria-label="custom table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    IMG
                                </TableCell>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    EMPLEADO
                                </TableCell>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    PUESTO
                                </TableCell>
                                <TableCell className="font-Avenir font-AvenirBold" align="right">
                                    <div style={{ width: "120px" }}></div>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody >
                            {
                                !cargando ? (
                                    <>
                                        {
                                            lista && lista.length == 0 && (
                                                <TableRow>
                                                    <TableCell component="th" scope="row" colSpan={4} className="py-4">
                                                        Empleados no encontrados
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                        {
                                            lista && lista.map(row => {
                                                const siEmpleadoAgregado = EmpleadosAgregados ? EmpleadosAgregados.find(x => x.ID_EMPLEADO == row.ID) : false

                                                if (!siEmpleadoAgregado) {
                                                    return (
                                                        <TableRow key={row.ID}>
                                                            <TableCell component="td" scope="row" align="left">
                                                                <Avatar className="shadow"
                                                                    sx={{ width: 50, height: 50 }}
                                                                    src={row.IMG}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                {row.DESCRIPCION}
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                {row.PUESTO}
                                                            </TableCell>
                                                            <TableCell component="td" scope="row" align="right">
                                                                <Button
                                                                    size="sm"
                                                                    onClick={() => fnAgregar(row.ID, row.IMG, row.DESCRIPCION, row.PUESTO)}
                                                                    className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                                                                    variant="outlined"
                                                                >
                                                                    Seleccionar
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            })
                                        }
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell component="th" scope="row" colSpan={4} className="py-4">
                                            <Loading />
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </Col>
            </DialogContent>
            <DialogActions>
                <Stack
                    className="p-3"
                    spacing={2}
                    direction={{ xs: "column", sm: "row" }}
                >
                    <Button
                        onClick={fnCerrar}
                        className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                        variant="outlined"
                    >
                        Cerrar
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

export default ModalEmpleado