export const IMG = {
    TIPO_LOCACION: "locaciones",
    TIPO_EMPLEADO: "empleados",
    TIPO_PLANNER: "planners",
    TIPO_PERFIL: "admins",
}

//Listas estaticas para los select del modulo de productos
export const TiposProductos = [
    { label: "Selecciona un tipo de producto", value: "0" },
    { label: "Insumo", value: "1" },
    { label: "Materia prima", value: "2" },
    { label: "Producto terminado", value: "3" },
    { label: "Servicio", value: "4" },
]
export const Monedas = [
    { label: "MXN", value: "MXN" },
    { label: "USD", value: "USD" }
]
export const Impuestos = [
    { label: "I.V.A. 16%", value: "16" },
    { label: "I.V.A. 8%", value: "8" },
    { label: "No genera I.V.A.", value: "0" },
    { label: "Excento de I.V.A.", value: "-1" },
    { label: "Tasa cero", value: "-2" }
]

//Listas estaticas para los select del modulo de locaciones
export const TiposLocaciones = [
    { ID: "0", DESCRIPCION: "Tipo de locación" },
    { ID: "1", DESCRIPCION: "Hacienda" },
    { ID: "2", DESCRIPCION: "Salón" },
    { ID: "3", DESCRIPCION: "Jardín" },
    { ID: "4", DESCRIPCION: "Playa" },
    { ID: "5", DESCRIPCION: "Bosque" },
    { ID: "6", DESCRIPCION: "Rancho" }
]