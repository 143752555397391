import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
import { isValidDate } from "../../../Lib/Funciones Generales/ObtenerFecha";
import { validarHorarios } from "../Componentes/Horarios/Funciones/Horarios";
import { validarConceptos } from "../Componentes/Conceptos/Funciones/Conceptos";
import { ExitToApp } from "@mui/icons-material";


///////////////////
/* FUNCIONES GET */
///////////////////

export const getPdf = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `fichaevento/file?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `eventos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getEventoConceptos = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `eventos/ConsultarEventoConceptos?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
}

export const getInfoEvento = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `eventos/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta
      const evento = JSON.parse(data.evento)[0]
      const horarios = JSON.parse(data.horarios);
      const conceptos = JSON.parse(data.conceptos);
      const personas = JSON.parse(data.personas);

      // console.log("EVENTO: ", evento, horarios, conceptos, personas)

      const Values = {
        evento: {
          id: String(evento.ID),
          activo: String(evento.ACTIVO),
          nombre: String(evento.NOMBRE),
          fecha_evento: evento.FECHA_EVENTO == null ? "" : String(evento.FECHA_EVENTO),
          id_encargado: String(evento.ID_ENCARGADO),
          id_planner: String(evento.ID_PLANNER),
          id_cliente: String(evento.ID_CLIENTE),
          id_locacion: String(evento.ID_LOCACION),
          presupuesto: String(evento.PRESUPUESTO),
        },
        horarios: horarios,
        personas: personas,
        conceptos: conceptos
      }

      // console.log("EVENTO: ", evento, horarios, conceptos, personas)
      // console.log("VALUES: ", Values)

      resolve(Values);
    } else {
      reject(res);
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardarEvento = async (Values, Horarios, Conceptos, Personas, ProductosModificados, Errores, setErrores, setHorarios) => {

  // let ErrorConcepto = false;
  // validarConceptos(Conceptos, (error, lista) => {
  //   ErrorConcepto = error;
  // })

  // if (ErrorConcepto) {
  //   return new Promise((resolve, reject) => {
  //     resolve({ mensaje: "La lista de conceptos no es valida" });
  //   });
  // }

  // return;


  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.fecha_evento || Values.fecha_evento == "" || Values.fecha_evento == null || !isValidDate(Values.fecha_evento)) {
    Errores.fecha_evento = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.id_encargado || Values.id_encargado == "" || Values.id_encargado == "0") {
    Errores.id_encargado = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.id_planner || Values.id_planner == "" || Values.id_planner == "0") {
    Errores.id_planner = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.id_locacion || Values.id_locacion == "" || Values.id_locacion == "0") {
    Errores.id_locacion = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.presupuesto || Values.presupuesto == "" || Values.presupuesto == "0") {
    Errores.presupuesto = true;
    setErrores(Errores);
    error = true;
  }


  Values.id_planner = String(Values.id_planner);
  Values.id_locacion = String(Values.id_locacion);
  Values.id_cliente = String(Values.id_cliente);
  Values.id_encargado = String(Values.id_encargado);
  Values.presupuesto = String(Values.presupuesto);


  if (!error) {


    let ErrorHorario = false;
    validarHorarios(Horarios, Values.fecha_evento, (error, lista) => {
      ErrorHorario = error;
    })

    if (ErrorHorario) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "La lista de horarios no es valida" });
      });
    }


    let ErrorConcepto = false;
    validarConceptos(Conceptos, (error, lista) => {
      ErrorConcepto = error;
    })

    if (ErrorConcepto) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "La lista de conceptos no es valida" });
      });
    }

 

    let data = [];
    let conceptos = {
      nuevos: [],
      modificados: []
    }
    let personas = {
      nuevos: [],
      modificados: []
    }

    Horarios = Horarios ? Horarios : []
    Conceptos = Conceptos ? Conceptos : []
    Personas = Personas ? Personas : []
    ProductosModificados = ProductosModificados ? ProductosModificados : []

    if (Array.isArray(Conceptos)) {
      conceptos.nuevos = Conceptos.filter(x => x.ID == 0)
      conceptos.modificados = Conceptos.filter(x => x.ID != 0)
    }

    if (Array.isArray(Personas)) {
      personas.nuevos = Personas.filter(x => x.ID == 0)
      personas.modificados = Personas.filter(x => x.ID != 0)
    }


    const body = {
      ...Values,
      horarios: Horarios,
      conceptos: conceptos,
      personas: personas,
      ProductosModificados: ProductosModificados
    };




    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "eventos/Insertar"
        : "eventos/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminarevento = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `eventos/EliminarEvento?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};


export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `eventos/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const GetEventos = async () => {
  let data = [];
  const url = 'eventos/Listar';
  const res = await getData(url);

  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
}
export const GetEventosConceptos = async () => {
  let data = [];
  const url = 'eventos/listarEventosConceptos';
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
}