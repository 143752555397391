import { Autocomplete, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { getOpciones } from "../Funciones/Empleados";


const Empleados = ({ Value, Error, handlInputChange, Name = "id_empleado", IsEncargado = false }) => {
    const opcionInicial = { ID: 0, DESCRIPCION: "Empleado" }
    const [ValorFiltrado, setFiltro] = useState()
    const [Empleado, setEmpleado] = useState({ cargado: false, lista: [opcionInicial] });

    const SingularMayus = !IsEncargado ? "Empleado" : "Encargado";
    const SingularMinus = !IsEncargado ? "empleado" : "encargado";
    const PluralMinus = !IsEncargado ? "empleados" : "encargados";

    useEffect(() => {
        getOpciones()
            .then((resp) => {
                let list = JSON.parse(resp.data);
                if (list.length != 0) {
                    setEmpleado({ cargado: true, lista: list });
                }
                else {
                    setEmpleado({ cargado: true, lista: [{ ID: 0, DESCRIPCION: "Sin " + PluralMinus +" disponibles" }] });
                }
            })
            .catch((resp) => {
                setEmpleado({ cargado: true, lista: [{ ID: 0, DESCRIPCION: "Sin " + PluralMinus +" disponibles" }] });
            });
    }, [])

    const getOpcion = () => {
        // console.log("Empleado: ", Value)
        const opcion = Empleado.lista.find(x => x.ID == Value)
        if (opcion) {
            return opcion
        } else {
            return null
        }
    }

    return (
        Empleado.cargado ? (
            <Autocomplete
                fullWidth
                defaultValue={getOpcion}
                isOptionEqualToValue={(option, value) => {
                    return option.ID == value.ID
                }}
                inputValue={ValorFiltrado}
                onChange={(e, value) => {
                    if (value) {
                        handlInputChange({ target: { name: Name, value: value.ID } })
                    }
                    else {
                        handlInputChange({ target: { name: Name, value: "" } })
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setFiltro(newInputValue);
                }}
                options={Empleado.lista}
                getOptionLabel={(option) => {
                    return option.DESCRIPCION
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            error={Error}
                            helperText={Error ? "Selecciona un " + SingularMinus +"" : ""}
                            label={SingularMayus}
                        />
                    )
                }}
            />
        ) : (
            <TextField
                fullWidth
                disabled={true}
                value={SingularMayus}
            />
        )
    )
}

export default Empleados