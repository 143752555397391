import { getData } from "../../Context/backend";


export const GetdataDashboard = async() => {
    let data = [];
    const url = 'Dashboard/Getdata';
    const res = await getData(url);

    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = res.data.respuesta;
            resolve(data);
        }else{
          reject(res);
        }
    });
  };
