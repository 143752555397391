import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoEmpleado } from "../Funciones/Empleados";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SelectPuestos from "../../Puestos/Componentes/SelectPuestos";
import { BasicDatePicker } from "../../../Includes/DatePicker";
import {
  ConvertDate,
  GetDate,
} from "../../../Lib/Funciones Generales/ObtenerFecha";
import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import "../../../Lib/Funciones Generales/Prototipos";

const MySwal = withReactContent(Swal);

const Empleado = () => {
  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img });
  };

  //Definicion de variables para el formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [Puestos, setPuestos] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [fecha, setFecha] = useState(GetDate());
  const [severity, setSeverity] = useState("error");
  const [activo, setActivo] = useState([]);
  const [Values, setValues] = useState({
    id: id ?? "0",
    // activo: "1",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    id_puesto: "0",
    correo: "",
    telefono: "",
    id_tipo_empleado: "1",
    acceso_app: true,
    img: AccEditarImagen,
    fecha_ingreso: "",
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    apellido_paterno: false,
    apellido_materno: false,
    id_puesto: false,
    correo: false,
    telefono: false,
    id_tipo_empleado: false,
    acceso_app: false,
    fecha_ingreso: false,
  });

  useEffect(() => {
    setValues({ ...Values, fecha_ingreso: ConvertDate(fecha) });
  }, [fecha]);

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    if (Name == "acceso_app") {
      Value = target.checked;
    } else if (Name == "telefono") {
      Value = Value.toNumber();
    }

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoEmpleado(id, AccEditarImagen)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  // useEffect(()=>{
  //   ActivarOrDesactivar(setActivo)
  //    },[activo])

  const GuardarEmpledo = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  // const onChange = (checked) => {
  //   ActivarOrDesactivar(checked);
  // };
  // const ActivarOrDesactivar = (checked) => {
  //   if (checked == true) {
  //       checked = true;
  //       Values.acceso_app = checked;
  //       console.log(checked);
  //       setSeverity("success");
  //       setMensaje("Acceso a la app");
  //       setOpen(true);
  //   }
  //   else{
  //     checked = false;
  //     Values.acceso_app = checked;
  //     console.log(checked);
  //     setSeverity("error");
  //     setMensaje("No acceso a la app");
  //     setOpen(true);
  //   }
  // };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Empleados
      </Button>
      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar empleado" : "Nuevo empleado"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col
                sm={12}
                md={12}
                lg={4}
                className="d-flex flex-wrap align-items-center justify-content-center"
              >
                <Avatar
                  className="shadow"
                  sx={{ width: 120, height: 120 }}
                  src={img_url}
                />
                <strong
                  className="w-100 text-center px-0 pt-3"
                  style={{ color: "#3ABE88", cursor: "pointer" }}
                  onClick={handleOpenModal}
                >
                  Editar foto
                </strong>
              </Col>
              <Col sm={12} md={4} className="p-3 mt-5">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  helperText={Errores.nombre ? "Nombre no valido" : ""}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3 mt-5">
                <TextField
                  fullWidth
                  name="apellido_paterno"
                  label="Apellido paterno"
                  variant="outlined"
                  value={Values.apellido_paterno}
                  error={Errores.apellido_paterno}
                  helperText={Errores.apellido_paterno ? "Apellido paterno no valido" : ""}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="apellido_materno"
                  label="Apellido materno"
                  variant="outlined"
                  value={Values.apellido_materno}
                  error={Errores.apellido_materno}
                  helperText={Errores.apellido_materno ? "Apellido materno no valido" : ""}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <SelectPuestos
                  Value={Values.id_puesto}
                  Error={Errores.id_puesto}
                  handlInputChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="correo"
                  label="Correo electrónico"
                  variant="outlined"
                  helperText={Errores.correo ? "Correo incorrecto" : ""}
                  value={Values.correo}
                  error={Errores.correo}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="telefono"
                  label="Teléfono"
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  helperText={Errores.telefono ? "Teléfono inválido" : ""}
                  value={Values.telefono}
                  error={Errores.telefono}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <BasicDatePicker
                  label="Fecha de ingreso"
                  name="fecha_ingreso"
                  value={Values.fecha_ingreso}
                  error={Errores.fecha_ingreso}
                  fecha={Values.fecha_ingreso}
                  setFecha={setFecha}
                  fechaMin="2021-01-01"
                  handlInputChange={handlInputChange}
                />
              </Col>
              <Col sm={12}></Col>
              <Col sm={12} md={4} className="p-3">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Tipo de empleado
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={Values.id_tipo_empleado}
                    error={Errores.id_tipo_empleado}
                  >
                    <FormControlLabel
                      value="1"
                      name="id_tipo_empleado"
                      onChange={handlInputChange}
                      control={<Radio />}
                      label="Base"
                    />
                    <FormControlLabel
                      value="2"
                      name="id_tipo_empleado"
                      onChange={handlInputChange}
                      control={<Radio />}
                      label="Eventual"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>

              <Col sm={12} md={4} className="p-3">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Acceso App Móvil
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="acceso_app"
                        defaultChecked
                        error={Errores.acceso_app}
                        checked={Values.acceso_app}
                        onChange={handlInputChange}
                      />
                    }
                    label="Activar acceso"
                  />
                </FormControl>
              </Col>


              <Col sm={12} md={4} className="p-3">
                {

Values.id ==0 ? <>
<Alert severity="info">
  <AlertTitle>Mensaje</AlertTitle>
 Se enviará por correo una contraseña temporal 
</Alert>
</>: <></>
                }
              </Col>
              
              {/* <Col sm={12} md={4} className="p-3">
                <FormGroup>
                <FormControlLabel control={  <Switch
                  checked={Values.acceso_app}
                  defaultChecked
                  name="acceso_app"
                  error={Errores.acceso_app}
                  onChange={onChange}
                  tituloActivo="No acceso a la app"
                  tituloInactivo="Acceso a la app"
                  on
                />} label="Acceso a la app"/>
                </FormGroup>
              </Col> */}
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarEmpledo()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_EMPLEADO}
          Titulo="Cargar imagen de empleado"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Empleado;
{
  /* <code>
<pre>
  {
    JSON.stringify(Values, null, 2)
  }
</pre>
</code> */
}
