function ValidadorWebMovile () {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      //console.log("Estás usando un dispositivo móvil!!");
      //console.log(navigator.userAgent);
      return false;
    } else {
      //console.log("No estás usando un móvil");
      //console.log(navigator.userAgent);
      return true;
    }
}

export default ValidadorWebMovile;