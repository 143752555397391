import { stringify } from "query-string";
import { getData } from "../../../Context/backend";


export const getEstados = async (query="") => {
    let data = [];
    let queryParamsObj = {
        "query": query
    } 
    const url = `direcciones/ListarEstados?${stringify(queryParamsObj)}`
    const res = await getData(url);
    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = res.data.respuesta;
            resolve(data);
        } else {
            reject(res);
        }
    });
}

export const getMunicipios = async (id_estado, query="") => {
    let data = [];
    let queryParamsObj = {
        "estado": id_estado,
        "query": query
    }
    const url = `direcciones/ListarMunicipios?${stringify(queryParamsObj)}`
    const res = await getData(url);
    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = res.data.respuesta;
            resolve(data);
        } else {
            reject(res);
        }
    });
}

export const getInfoEstado = async id => {
    let data = [];
    let queryParamsObj = {
        id: id,
    };
    const url = `direcciones/ConsultarEstado?${stringify(queryParamsObj)}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = JSON.parse(res.data.respuesta.data)[0];
            let Values = {
                id: String(data.ID),
                nombre: String(data.NOMBRE)
            };
            resolve(Values);
        } else {
            reject(res);
        }
    });
}

export const getInfoMunicipio = async id => {
    let data = [];
    let queryParamsObj = {
        id: id,
    };
    const url = `direcciones/ConsultarMunicipio?${stringify(queryParamsObj)}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = JSON.parse(res.data.respuesta.data)[0];
            let Values = {
                id: String(data.ID),
                nombre: String(data.NOMBRE)
            };
            resolve(Values);
        } else {
            reject(res);
        }
    });
}
