import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, Router } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import AccEditar from "./../../../assets/AccEditar.svg";
import AccEliminar from "./../../../assets/AccEliminar.svg";
import RadilBar from "../Charts/RadialBar";
import RadialBarTiempos from "../Charts/RadialBarTiempos";

import { Stack } from "@mui/material";
import Alert from "@mui/material/Alert";

import { Button } from "react-bootstrap";
import Chip from "@mui/material/Chip";
import ModalCaptura from "./ModalCaptura";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Loading from "./../../../Includes/Loading";

import {
  getEventoConceptos,
  getPdf,
  Eliminarevento,
} from "../Funciones/Eventos";
import { getPorcentaje } from "../../../Lib/Funciones Generales/Generales";

import MapIcon from "@mui/icons-material/Map";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import Nominas from "./Nominas";

import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getLocalStorage } from "../../../Context/Storage";
import BACKEND_URL from "../../../Context/backend";
 
const MySwal = withReactContent(Swal);  
let intervalos = null;
let i = 0;

function Row({ row, changuePorcentaje, Lista, setLista, ValueBusqueda }) {
  const [open, setOpen] = useState(false);
  const [ID, setID] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [MostrarBtnEliminar, setMostrarBtnEliminar] = useState(false);
  const [conceptos, setConceptos] = useState([]);
  const [infoEvento, setInfoEvento] = useState([] || null);
  const [infoHorarios, setInfoHorarios] = useState([] || null);
  const [infoEmpleados, setInfoEmpleados] = useState([] || null);
  const [IsLoading, setIsLoading] = useState(true);

  let TOTAL_PERSONAL_EXTRA = 0;

  const handleClickOpenModal = (ID) => {
    setOpenModal(true);
  };

  const getEventosConcepto = () => {
    getEventoConceptos(ID)
      .then((resp) => {
        setConceptos(JSON.parse(resp.data));
        setInfoHorarios(JSON.parse(resp.infoHorarios));
        setInfoEmpleados(JSON.parse(resp.infoEmpleados));
        let infoeve = JSON.parse(resp.infoevento);
        setInfoEvento(infoeve);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (ID != 0) {
      getEventosConcepto();
    }
  }, [ID]);

  const actualizaPorcentaje = (ID_EVENTO, PORCENTAJE) => {
    changuePorcentaje(ID_EVENTO, PORCENTAJE);
    getEventosConcepto();
  };

  const EliminarEvent = (id) => {
    new Swal({
      title: "¿Estas seguro de eliminar este evento?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          Eliminarevento(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                eliminarEventoDeTabla(id);
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };

  const eliminarEventoDeTabla = (id) => {
    const filteredLibraries = Lista.filter((item) => {
      return item.ID !== id;
    });
    setLista(filteredLibraries);
  };

  const childOnResponse = (data = "") => {};
  React.useEffect(() => {
    console.log("cambio de id: ", ID);
    if (intervalos == null && open == true) {
      intervalos = setInterval(() => {
        console.log(intervalos);
        if (ID != 0) {
          i += 1;
          getEventosConcepto();
        }
      }, 10000);
    }
    return () => {
      console.log("Limpiando...");
      clearInterval(intervalos);
      intervalos = null;
      i = 0;
    };
  }, [ID, open]);

  let Mensaje = (
    <span>
      <span>Parece que el tiempo es suficiente</span>
      <strong>&nbsp;Se logrará! </strong>
    </span>
  );
  let TipoNoti = "success";

  let TIEMPO_RESTANTE_BASE_PORCENTAJE =
    infoEvento.length != 0
      ? infoEvento[0].TIEMPO - (row.AVANCE * infoEvento[0].TIEMPO) / 100
      : 0;

  let TIEMPO_RESTANTE_MONTAJE =
    infoEvento.length != 0
      ? parseInt(infoEvento[0].TIEMPO) -
        parseInt(infoEvento[0].TIEMPO_TRANSCURRIDO)
      : 0;

  let HORAS_HOMBRE_BASE_PORCENTAJE =
    row.HORAS_HOMBRE - (row.AVANCE * row.HORAS_HOMBRE) / 100;

  if (row.AVANCE == 0) {
    HORAS_HOMBRE_BASE_PORCENTAJE = TIEMPO_RESTANTE_MONTAJE;
  } else {
    if (TIEMPO_RESTANTE_MONTAJE != 0) {
      TOTAL_PERSONAL_EXTRA =
        HORAS_HOMBRE_BASE_PORCENTAJE / (TIEMPO_RESTANTE_MONTAJE / 60);
    }
  }

  conceptos.map((rows) => {
    let TIEMPO_RESTANTE =
      parseInt(rows.TIEMPO_RESTANTE) == 0
        ? parseInt(rows.TIEMPO_TOTAL * 60)
        : parseInt(rows.TIEMPO_RESTANTE);

    if (TIEMPO_RESTANTE_MONTAJE < TIEMPO_RESTANTE) {
      let AVANCE = parseInt(rows.AVANCE);

      if (AVANCE == 100) {
        //  PERSONAL_EXTRA = 0;
      } else {
      }
    }
  });

  if (TIEMPO_RESTANTE_BASE_PORCENTAJE > TIEMPO_RESTANTE_MONTAJE) {
    TipoNoti = "error";
    Mensaje =
      TIEMPO_RESTANTE_MONTAJE != 0 ? (
        <>
          <span>
            <strong>¡Alerta!&nbsp;</strong>
            <span>Algunos conceptos requieren más personal</span>
          </span>
          <br />
          {TOTAL_PERSONAL_EXTRA != 0 && !isNaN(TOTAL_PERSONAL_EXTRA) ? (
            <>
              <span className="text-danger fw-bold">
                {"Aproximadamente " +
                  TOTAL_PERSONAL_EXTRA.toFixed(2) +
                  " personas para terminar a tiempo"}
              </span>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          <span>
            <strong>¡Alerta!&nbsp;</strong>
            <span>El tiempo se ha agotado</span>
          </span>
          <br />
        </>
      );
  }

  useEffect(() => {
    isAdminOrEmpelado().then((isAdmin) => {
      if (isAdmin) {
        setMostrarBtnEliminar(true);
      } else {
        setMostrarBtnEliminar(false);
      }
    });
  }, []);

  function isAdminOrEmpelado() {
    return Promise.resolve(getLocalStorage("tipo") == "ADMIN");
  }

  return (
    <React.Fragment>
      {openModal ? (
        <ModalCaptura
          open={openModal}
          setOpen={setOpenModal}
          id={ID}
          changuePorcentaje={actualizaPorcentaje}
        />
      ) : (
        <></>
      )}
      {}
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
              setID(row.ID);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.ID}</TableCell>
        <TableCell align="left">{row.FECHA_EVENTO103}</TableCell>
        <TableCell align="left">{row.NOMBRE_EVENTO}</TableCell>
        <TableCell align="left">{row.NOMBRE_LOCACION}</TableCell>

        <TableCell align="left">
          <div
            className=" progress position-relative"
            style={{ backgroundColor: "#c9c9c9" }}
          >
            <div
              className={
                getPorcentaje(row.AVANCE) +
                " progress-bar progress-bar-striped progress-bar-animated"
              }
              role="progressbar"
              style={{ width: row.AVANCE + "%" }}
              aria-valuenow="60"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            <small className="justify-content-center d-flex position-absolute w-100 text-white fw-bold">
              {parseInt(row.AVANCE)}%
            </small>
          </div>
        </TableCell>
        <TableCell align="center">{row.PERSONAL_REQ.toFixed(2)} </TableCell>
        <TableCell align="center">
          {TOTAL_PERSONAL_EXTRA == 0 &&
          TOTAL_PERSONAL_EXTRA - row.PERSONAL_REQ < 0
            ? ""
            : (TOTAL_PERSONAL_EXTRA - row.PERSONAL_REQ).toFixed(2)}{" "}
        </TableCell>
        <TableCell align="right">
          {parseInt(row.AVANCE) != 100 ? (
            <>
              <Link to={"./edit/" + row.ID} style={{ textDecoration: "none" }}>
                <Tooltip title="Editar" placement="top">
                  <IconButton>
                    <img src={AccEditar} />
                  </IconButton>
                </Tooltip>
              </Link>
              {MostrarBtnEliminar && (
                <Tooltip title="Eliminar" placement="top">
                  <IconButton onClick={() => EliminarEvent(row.ID)}>
                    <img src={AccEliminar} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            <></>
          )}
          <a
            target={"_blank"}
            href={
              "https://www.google.com/maps/dir/" +
              row.UBICACION_LAT +
              "," +
              row.UBICACION_LONG
            }
            style={{ textDecoration: "none" }}
          >
            <Tooltip title="Ver ubicación de la locación" placement="top">
              <IconButton>
                <MapIcon style={{ color: "#0f0f0f" }} />
              </IconButton>
            </Tooltip>
          </a>

          <a target={"_blank"} href={BACKEND_URL + "fichaevento/TymTrackingPDF?ID=" + row.ID}>
            <Tooltip title="Imprimir informe" placement="top">
              <IconButton>
                <PictureAsPdfIcon style={{ color: "#0f0f0f" }} />
              </IconButton>
            </Tooltip>
          </a>

        </TableCell>
      </TableRow>
      {
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={9}
            className="bg-gray "
          >
            {
              <Collapse in={open} timeout="auto" unmountOnExit to={row.ID}>
                <Box className="mt-3">
                  <div className="w-100 d-flex justify-content-between px-3  flex-wrap-reverse flex-md-wrap">
                    <div>
                      <Typography variant="h6" gutterBottom component="div">
                        Información del progreso del evento
                      </Typography>
                    </div>
                    <div>
                      <Stack
                        spacing={2}
                        alignItems="center"
                        alignContent="center"
                        justifyContent="center"
                        className="shadow"
                      >
                        {infoEvento.length == 0 ? (
                          <></>
                        ) : parseInt(row.AVANCE) != 100 ? (
                          <>
                            <Alert
                              className="w-100"
                              severity={TipoNoti}
                              fullWidth
                            >
                              {Mensaje}
                            </Alert>
                          </>
                        ) : (
                          <Alert className="w-100" severity="success" fullWidth>
                            Evento terminado
                          </Alert>
                        )}
                      </Stack>
                    </div>
                    <div>
                      <Button
                        className="btn btn-Crexendo font-AvenirMedium"
                        size="small"
                        variant="outlined"
                        disabled={parseInt(row.AVANCE) == 100 ? true : false}
                        onClick={handleClickOpenModal}
                      >
                        Capturar avance
                      </Button>
                    </div>
                  </div>

                  <div className="d-flex justify-content-around flex-wrap align-items-center">
                    {infoEvento.length == 0 ? (
                      <></>
                    ) : (
                      <RadialBarTiempos
                        label="Tiempo transcurrido"
                        tiempo={
                          infoEvento.length == 0
                            ? 0
                            : infoEvento[0].TIEMPO_TRANSCURRIDO == undefined
                            ? 0
                            : infoEvento[0].TIEMPO_TRANSCURRIDO
                        }
                        porcentaje={
                          infoEvento.length == 0
                            ? 0
                            : parseInt(
                                ((infoEvento[0].TIEMPO_TRANSCURRIDO == undefined
                                  ? 0
                                  : infoEvento[0].TIEMPO_TRANSCURRIDO) *
                                  100) /
                                  infoEvento[0].TIEMPO
                              )
                        }
                        color="#FBB303"
                      />
                    )}

                    {infoEvento.length == 0 ? (
                      <></>
                    ) : (
                      <RadialBarTiempos
                        className="align-self-end"
                        label="Hrs rest. Con %"
                        tiempo={
                          infoEvento.length == 0
                            ? 0
                            : TIEMPO_RESTANTE_BASE_PORCENTAJE
                        }
                        porcentaje={
                          infoEvento.length == 0
                            ? 0
                            : parseInt(100 - row.AVANCE)
                        }
                        color="#65748B"
                      />
                    )}

                    {infoEvento.length == 0 ? (
                      <></>
                    ) : (
                      <RadialBarTiempos
                        className="align-self-end"
                        label="Tiempo restante"
                        tiempo={
                          infoEvento.length == 0
                            ? 0
                            : infoEvento[0].TIEMPO -
                              infoEvento[0].TIEMPO_TRANSCURRIDO
                        }
                        porcentaje={
                          infoEvento.length == 0
                            ? 0
                            : parseInt(
                                100 -
                                  (infoEvento[0].TIEMPO_TRANSCURRIDO * 100) /
                                    infoEvento[0].TIEMPO
                              )
                        }
                        color="#100D1C"
                      />
                    )}

                    <RadilBar
                      height={240}
                      porcentaje={row.AVANCE.toFixed(2)}
                      className="align-self-end "
                    />
                  </div>

                  <Table
                    size="small"
                    aria-label="purchases"
                    className="mb-5 pb-3"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className="font-Avenir font-AvenirBold">
                          Capturó
                        </TableCell>
                        <TableCell
                          className="font-Avenir font-AvenirBold"
                          align="left"
                        >
                          Concepto
                        </TableCell>

                        <TableCell
                          className="font-Avenir font-AvenirBold"
                          align="left"
                        >
                          % Producción
                        </TableCell>

                        <TableCell
                          className="font-Avenir font-AvenirBold"
                          align="center"
                        >
                          %
                        </TableCell>

                        <TableCell
                          className="font-Avenir font-AvenirBold"
                          align="center"
                          style={{ minWidth: "120px" }}
                        >
                          Total
                        </TableCell>

                        <TableCell
                          className="font-Avenir font-AvenirBold"
                          align="center"
                          style={{ minWidth: "120px" }}
                        >
                          Personal req.
                        </TableCell>

                        <TableCell
                          className="font-Avenir font-AvenirBold"
                          align="center"
                          style={{ minWidth: "120px" }}
                        >
                          Personal extra.
                        </TableCell>

                        {/*
                        <TableCell
                          className="font-Avenir font-AvenirBold"
                          align="center"
                        >
                          Tiempo restante
                        </TableCell>

                        <TableCell
                          className="font-Avenir font-AvenirBold"
                          align="center"
                        >
                          Tiempo estimado finalización
                        </TableCell>
                      */}
                        <TableCell
                          className="font-Avenir font-AvenirBold"
                          align="right"
                        >
                          Estatus
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* <code> <pre> { JSON.stringify(graficas)} </pre> </code> */}

                      {conceptos == [] ? (
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={6}>
                            <Loading />
                          </TableCell>
                        </TableRow>
                      ) : (
                        conceptos.map((rows) => {
                          let TiempoRestante =
                            infoEvento[0].TIEMPO -
                            infoEvento[0].TIEMPO_TRANSCURRIDO;

                          let TIEMPO_RESTANTE_BASE_PORCENTAJE = 0;

                          let TIEMPO_RESTANTE =
                            parseInt(rows.TIEMPO_RESTANTE) == 0
                              ? parseInt(rows.TIEMPO_TOTAL * 60)
                              : parseInt(rows.TIEMPO_RESTANTE);

                          let HORAS_HOMBRE =
                            rows.CANTIDAD *
                            rows.PERSONAS_INVOLUCRADAS *
                            (rows.TIEMPO_DE_PRODUCCION / 60);

                          let aTiempo = true;

                          let TIEMPO_DE_PRODUCCION = parseFloat(
                            rows.TIEMPO_DE_PRODUCCION
                          );

                          let PERSONAL_REQ =
                            HORAS_HOMBRE / (infoEvento[0].TIEMPO / 60);
                          let PERSONAL_EXTRA = 0;

                          if (TiempoRestante < TIEMPO_RESTANTE) {
                            aTiempo = false;

                            let AVANCE = parseInt(rows.AVANCE);
                            if (AVANCE == 100) {
                              PERSONAL_EXTRA = 0;
                            } else {
                              let HORAS_HOMBRE_BASE_PORCENTAJE =
                                HORAS_HOMBRE -
                                (row.AVANCE * HORAS_HOMBRE) / 100;

                              if (row.AVANCE == 0) {
                                HORAS_HOMBRE_BASE_PORCENTAJE = TiempoRestante;
                              } else {
                                if (TiempoRestante != 0) {
                                  PERSONAL_EXTRA =
                                    HORAS_HOMBRE_BASE_PORCENTAJE /
                                    (TiempoRestante / 60);
                                }
                              }

                              /*
                              if (TiempoRestante != 0) 
                              {
                                TIEMPO_RESTANTE_BASE_PORCENTAJE =
                                  TIEMPO_DE_PRODUCCION -
                                  (AVANCE * TIEMPO_DE_PRODUCCION) / 100;
                                console.log("Tiempo en %", TIEMPO_RESTANTE_BASE_PORCENTAJE);
                                console.log("Tiempo en Min:", TiempoRestante);
                                if (TiempoRestante < TIEMPO_RESTANTE_BASE_PORCENTAJE) {

                                  PERSONAL_EXTRA =
                                    ((Math.ceil(PERSONAL_REQ) *
                                      (rows.TIEMPO_DE_PRODUCCION / 60)) /
                                      (TiempoRestante / 60)) -
                                    Math.ceil(PERSONAL_REQ);
                                  //console.log("Primer Dato:", ((Math.ceil(PERSONAL_REQ) *
                                   // (rows.TIEMPO_DE_PRODUCCION / 60)) /
                                   // (TiempoRestante / 60)));
                                  //console.log("Segundo Dato:", Math.ceil(PERSONAL_REQ));
                                  //console.log("TotalConcepto", PERSONAL_EXTRA);
                                  }}
                                  */
                            }
                            TOTAL_PERSONAL_EXTRA += PERSONAL_EXTRA;
                          }
                          return (
                            <>
                              <TableRow key={rows.ID_EVENTO}>
                                <TableCell component="th" scope="row">
                                  {rows.ENCARGADO}
                                  <br />
                                  {rows.FECHA_ULTIMA_CAPTURA}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {rows.CONCEPTO}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                  {rows.POR_PROD?.toFixed(2)}%
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {rows.TOTAL_AVANCE_CON?.toFixed(2)}%
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  <div
                                    className=" progress position-relative"
                                    style={{ backgroundColor: "#c9c9c9" }}
                                  >
                                    <div
                                      className={
                                        getPorcentaje(parseInt(rows.AVANCE)) +
                                        " progress-bar progress-bar-striped progress-bar-animated"
                                      }
                                      role="progressbar"
                                      style={{
                                        width: parseInt(rows.AVANCE) + "%",
                                      }}
                                      aria-valuenow="60"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                    <small className="justify-content-center d-flex position-absolute w-100 text-white fw-bold">
                                      {parseInt(rows.AVANCE)}%
                                    </small>
                                  </div>
                                  {/* <ProgressBar now={rows.PORCENTAJE}  clase={getPorcentaje(rows.PORCENTAJE)}  label={rows.PORCENTAJE} /> */}
                                  {/* <getPorcentaje>{rows.PORCENTAJE}</getPorcentaje> */}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  {parseInt(rows.AVANCE) == 100 ? (
                                    <></>
                                  ) : (
                                    <>{PERSONAL_REQ.toFixed(2)}</>
                                  )}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  {parseInt(rows.AVANCE) == 100 &&
                                  PERSONAL_EXTRA == 0 ? (
                                    <></>
                                  ) : (
                                    <>
                                      {(PERSONAL_EXTRA - PERSONAL_REQ < 0
                                        ? 0
                                        : PERSONAL_EXTRA - PERSONAL_REQ
                                      ).toFixed(2)}
                                    </>
                                  )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="right"
                                >
                                  {parseInt(rows.AVANCE) == 100 ? (
                                    <Chip
                                      label="Finalizado"
                                      style={{
                                        background: "#FF8D06",
                                        color: "white",
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      label={
                                        aTiempo ? "En proceso" : "Demorado"
                                      }
                                      style={{
                                        background: aTiempo
                                          ? "#2F84FB"
                                          : "#EFA500",
                                        color: "white",
                                      }}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>

                  {IsLoading ? (
                    <></>
                  ) : (
                    <Nominas
                      infoHorarios={infoHorarios}
                      infoNominas={infoEmpleados}
                      ID={ID}
                    ></Nominas>
                  )}
                </Box>
              </Collapse>
            }
          </TableCell>
        </TableRow>
      }
    </React.Fragment>
  );
}

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
export default function CollapsibleTable({
  Lista,
  setLista,
  IsLoading,
  changuePorcentaje,
  ValueBusqueda,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    console.log({ Lista, ValueBusqueda });
    if (ValueBusqueda != "") {
      setPage(0);
    }
  }, [Lista]);

  // console.log("Lista Eventos",Lista);
  // console.log("ARRAYS", Lista);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="font-Avenir font-AvenirBold" align="left">
              Folio
            </TableCell>
            <TableCell className="font-Avenir font-AvenirBold" align="left">
              Fecha
            </TableCell>
            <TableCell className="font-Avenir font-AvenirBold" align="left">
              Evento
            </TableCell>
            <TableCell className="font-Avenir font-AvenirBold" align="left">
              Locación
            </TableCell>
            <TableCell className="font-Avenir font-AvenirBold" align="left">
              Avance
            </TableCell>
            <TableCell className="font-Avenir font-AvenirBold" align="center">
              {" "}
              Personal Req.
            </TableCell>
            <TableCell className="font-Avenir font-AvenirBold" align="center">
              {" "}
              Personal Extra
            </TableCell>
            <TableCell className="font-Avenir font-AvenirBold" align="right">
              {" "}
              Acciones{" "}
            </TableCell>
          </TableRow>
        </TableHead>

        {IsLoading ? (
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" colSpan={9}>
                <Loading />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          (rowsPerPage > 0
            ? Lista.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : Lista
          ).map((row) => (
            <>
              <Row
                Lista={Lista ?? []}
                IsLoading={IsLoading}
                setLista={setLista}
                ValueBusqueda={ValueBusqueda}
                key={row.ID}
                row={row}
                changuePorcentaje={changuePorcentaje}
              ></Row>
            </>
          ))
        )}

        {emptyRows > 0 && (
          <TableBody>
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          </TableBody>
        )}

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[15, 30, 100, { label: "Todos", value: -1 }]}
              count={Lista.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Filas por pagína",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={"Registros por página"}
              labelDisplayedRows={({ from, to, count, page }) => {
                return `${from} - ${to} de ${count}`;
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

// striped animated variant="primary"
