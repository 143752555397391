import Switch_ from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const GreenSwitch = styled(Switch_)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#3ABE88",
    "&:hover": {
      backgroundColor: alpha("#3ABE88", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#3ABE88",
  },
}));

const Switch = ({
  checked,
  onChange,
  id,
  tituloActivo = "Activar registro",
  tituloInactivo = "Desactivar registro",
}) => {
  // console.log("cheked: " + checked )
 
  return (
    <>
      <Tooltip title={checked ? tituloActivo : tituloInactivo} placement="top">
        <GreenSwitch
          checked={checked}
          id={id}
          defaultChecked={checked}
          onChange={({ target }) => {
            //console.log(target);
            // console.log(target.checked);
            if (onChange) {
              onChange(target.checked, id);
            }
          }}
        />
      </Tooltip>
    </>
  );
};

export default Switch;
