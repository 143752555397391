import React, { useEffect, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Dashboard from "./../Inicio/Dashboard";
import Sidebar from "./Sidebar";
import AccountMenu from "./MenuPerfil";

import Error404 from "./Error404";
import MenuIcon from "@mui/icons-material/Menu";
import Productos from "../Catalogos/Productos/Productos";
import Empleados from "../Catalogos/Empleados/Empleados";
import Clientes from "../Catalogos/Clientes/Clientes";
import Locaciones from "../Catalogos/Locaciones/Locaciones";
import Puestos from "../Catalogos/Puestos/Puestos";
import Eventos from "../Catalogos/Eventos/Eventos";
import Planners from "../Catalogos/Planners/Planners";
import Conceptos from "../Catalogos/Conceptos/Conceptos";
import Categorias from "../Catalogos/Categorias/Categorias";
import Proveedores from "../Catalogos/Proveedores/Proveedores";
import ValidadorWebMovile from "../Lib/Funciones Generales/ValidadorWebMovile";
import Perfil from "../Catalogos/Perfil/Perfil";
import ConfigCorreos from "../Configuraciones/Correos/Correos";

import MenuNotificaciones from "./Notificaciones";
import { getLocalStorage } from "./../Context/Storage";
import { Chip } from "@mui/material";
import AccEditarImagen from "../assets/AvatarMenu.svg";

import { getInfoPerfil } from "../Catalogos/Perfil/Funciones/Perfiles";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 5,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Menu = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isTabletOrMobile = useMediaQuery("(max-width: 1224px)");

  useEffect(() => {
    setOpen(ValidadorWebMovile());
  }, []);

  useEffect(() => {
    setOpen(!isTabletOrMobile);
  }, [isTabletOrMobile]);

  const [Values, setValues] = useState({
    img: AccEditarImagen,
  });
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getInfoPerfil(AccEditarImagen, true)
      .then((resp) => {
        setValues(resp);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
    setIsLoading(false);
  }, []);
  return (
 
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          color="transparent"
          style={{ boxShadow: "none", zIndex: 10 }}
        >
          <Toolbar style={open ? { paddingLeft: "0px" } : {}}>
            <div className="d-flex justify-content-between w-100">
              <div>
                {open ? (
                  <DrawerHeader style={{ backgroundColor: "#FFFFFF" }}>
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === "rtl" ? <MoreVert /> : <MoreVert />}
                    </IconButton>
                  </DrawerHeader>
                ) : (
                  <IconButton onClick={handleDrawerOpen}>
                    <MenuIcon style={{ color: "white", zIndex: 10 }} />
                  </IconButton>
                )}
              </div>
              <div
                className="d-flex justify-content-end w-100 align-content-center"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div className="p-2 d-flex algin-content-center">
                  {
                    <Chip
                      className="align-self-center"
                      color="success"
                      label={
                        getLocalStorage("tipo") == "EMPLEADO"
                          ? "Empleado"
                          : "Admin"
                      }
                      size="small"
                    />
                  }
                  <MenuNotificaciones
                    Values={Values}
                    IsLoading={IsLoading}
                    className="align-self-center"
                  ></MenuNotificaciones>
                  <AccountMenu
                    Values={Values}
                    IsLoading={IsLoading}
                    className="align-self-center"
                  ></AccountMenu>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          style={{
            backgroundColor: "#101727",
            height: "100%",
            color: "white",
            zIndex: 2,
          }}
        >
          <Sidebar openMenu={open} />
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1 }}
          className="p-xsm-0 p-sm-3 "
          style={{ backgroundColor: "#F9FAFC", minHeight: "100vh" }}
        >
          <div className="mb-5"></div>
          <Routes>
            <Route
              path="inicio"
              element={<Dashboard Values={Values} />}
            ></Route>
            <Route path="/" element={<Dashboard Values={Values} />}></Route>
            <Route path="/productos/*" element={<Productos />}></Route>
            <Route path="/empleados/*" element={<Empleados />}></Route>
            <Route path="/clientes/*" element={<Clientes />}></Route>
            <Route path="/planners/*" element={<Planners />}></Route>
            <Route path="/categorias/*" element={<Categorias />}></Route>
            <Route path="/locaciones/*" element={<Locaciones />}></Route>
            <Route path="/puestos/*" element={<Puestos />}></Route>
            <Route path="/eventos/*" element={<Eventos />}></Route>
            <Route path="/proveedores/*" element={<Proveedores />}></Route>
            <Route path="/conceptos/*" element={<Conceptos />}></Route>
            <Route path="/perfil/*" element={<Perfil />}></Route>
            <Route path="/configCorreos/*" element={<ConfigCorreos />}></Route>
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Box>
      </Box>
 
  );
};

export default Menu;
