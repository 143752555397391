import { stringify } from "query-string";
import { getData, postdData, postUrl } from "./../../../Context/backend";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import validator from "validator";
import RFCValidator from "../../../Lib/Funciones Generales/ValidarRFC";

const MySwal = withReactContent(Swal);

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `clientes/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoCliente = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `clientes/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        console.log(data);
        let Values = {
          id: String(data.ID),
          alias: String(data.ALIAS),
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO),
          rfc: String(data.RFC),
          razon_social: String(data.RAZON_SOCIAL),
          cp: data.CP == "0" ? "" : String(data.CP),
          regimen_fiscal: String(data.REGIMEN_FISCAL),
          domicilio: String(data.DOMICILIO),
          id_municipio: String(data.ID_MUNICIPIO),
          id_estado: String(data.ID_ESTADO),
          requiere_factura: data.REQUIERE_FACTURA,
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;

  /* REQUERIDOS */
  if (!Values.alias || Values.alias == "") {
    let Errores2 = Errores;

    Errores2.alias = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }
  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  /*NO  REQUERIDOS */
  if (!Values.domicilio || Values.domicilio == "") {
    //  Errores.domicilio = true;
    //  setErrores(Errores);
    //  error = true;
  }

  if (!Values.id_estado || Values.id_estado == "" || Values.id_estado == "0") {
    Errores.id_estado = true;
    setErrores(Errores);
    error = true;
  }

  if (
    !Values.id_municipio ||
    Values.id_municipio == "" ||
    Values.id_municipio == "0"
  ) {
    Errores.id_municipio = true;
    // setErrores(Errores);
    error = true;
  }

  if (
    !Values.id_municipio ||
    Values.id_municipio == "" ||
    Values.id_municipio == "0"
  ) {
    // Errores.id_municipio = true;
    // setErrores(Errores);
    //error = true;
  }

  /* REQUERIDOS SI REQ FAC */
  if (Values.requiere_factura) {
    if (!Values.razon_social || Values.razon_social == "") {
      Errores.razon_social = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.cp || Values.cp == "" || Values.cp.length > 5) {
      Errores.cp = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.rfc || Values.rfc == "") {
      Errores.rfc = true;
      setErrores(Errores);
      error = true;
    } else {
      if (!RFCValidator(Values.rfc)) {
        Errores.rfc = true;
        setErrores(Errores);
        error = true;
      }
    }
    if (
      !Values.regimen_fiscal ||
      Values.regimen_fiscal == "" ||
      Values.regimen_fiscal == "0"
    ) {
      Errores.regimen_fiscal = true;
      setErrores(Errores);
      error = true;
    }
  } else if (Values.requiere_factura == "false") {
    /*
    if (Values.regimen_fiscal == null) {
      Errores.regimen_fiscal = true;
      
      error = true;
    }
    if (Values.rfc == null) {
      Errores.regimen_fiscal = true;
      // setErrores(Errores);
      error = true;
    }*/
  }

  Values.id_estado = String(Values.id_estado);
  Values.id_municipio = String(Values.id_municipio);
  Values.regimen_fiscal = String(Values.regimen_fiscal);

  if (!error) {
    let data = [];
    const body = Values;
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "clientes/Insertar"
        : "clientes/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `clientes/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `clientes/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
