import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import validator from "validator";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `productos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpciones = async (query, top = 10) => {
  let data = [];
  let queryParamsObj = {
    query: query,
    top: top
  };

  const url = `productos/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getOpcionesModal = async (query, top = 10, categoria = 0) => {
  let data = [];
  let queryParamsObj = {
    query: query,
    top: 10,
    categoria: categoria
  };

  const url = `productos/getOpcionesModal?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getUnidadesMedidasInternas = async (query) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sat/ConsultarUnidadMedidaInterna?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoProducto = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `productos/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este producto",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        //console.log(data);
        let Values = {
          id: String(data.ID),
          activo: String(data.ACTIVO),
          codigo: String(data.CODIGO),
          descripcion: String(data.DESCRIPCION),
          precio_de_venta: String(data.PRECIO_DE_VENTA),
          iva: String(data.IVA),
          clave_unidad_medida_sat: String(data.CLAVE_UNIDAD_MEDIDA_SAT),
          clave_codigo_prod_sat: String(data.CLAVE_CODIGO_PROD_SAT),
          tipo_de_producto: String(data.TIPO_DE_PRODUCTO),
          costo_de_compra: String(data.COSTO_DE_COMPRA),
          unidad_de_medida: String(data.UNIDAD_DE_MEDIDA),
          activo: String(data.ACTIVO),
          moneda: String(data.MONEDA),
          descuento: String(data.DESCUENTO),
          nom_clave_producto_servicio: String(data.NOM_CLAVE_PRODUCTO_SERVICIO),
          nom_clave_unidad_medida: String(data.NOM_CLAVE_UNIDAD_MEDIDA),
          id_categoria: String(data.ID_CATEGORIA),
          nom_categoria: String(data.NOM_CATEGORIA),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;
  // let error = false;
  // console.log(Values);

  // Values.iva = String(Values.iva)
  // Values.precio_de_venta = String(Values.precio_de_venta)

  if (!Values.descripcion || Values.descripcion == "") {
    Errores.descripcion = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.iva || Values.iva == "") {
    Errores.iva = true;
    setErrores(Errores);
    error = true;
  }

  if (
    !Values.costo_de_compra ||
    Values.costo_de_compra == "" ||
    Values.costo_de_compra == "0"
  ) {
    Errores.costo_de_compra = true;
    setErrores(Errores);
    error = true;
  }

  if (Values.costo_de_compra.length != 0) {
    if (Values.costo_de_compra < 0) {
      Errores.costo_de_compra = true;
      setErrores(Errores);
      error = true;

      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El campo costo de compra no puede ser menor a 0" });
      });
    }
  }

  if (Values.descuento.length != 0) {
    if (Values.descuento < 0) {
      Errores.descuento = true;
      setErrores(Errores);
      error = true;

      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El campo descuento no puede ser menor a 0" });
      });
    }
  }

  Values.id_categoria = String(Values.id_categoria);
  Values.tipo_de_producto = String(Values.tipo_de_producto);
  Values.clave_codigo_prod_sat = String(Values.clave_codigo_prod_sat);
  Values.clave_unidad_medida_sat = String(Values.clave_unidad_medida_sat);

  if (!error) {
    let data = [];
    const body = Values;
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "productos/Insertar"
        : "productos/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `productos/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `productos/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
