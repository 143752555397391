import Login from "./Login/componentes/login";
import { useEffect, useState } from "react";
import { getLocalStorageJWT } from "./Context/Storage";
import Menu from "./Includes/menu";
//import { Helmet } from "react-helmet";
import StartLoad from "./Includes/StartLoad";

const App = () => {
  const [isLoged, setIsLoged] = useState(false);
  const [load,setLoad] = useState(true)
  const getInfoUsuario = async () => {
    if (getLocalStorageJWT() != "") {
      setIsLoged(true);
    }
  };
  useEffect(()=>{
  setLoad(true);
  getInfoUsuario().then(
    setTimeout(()=>{

      setLoad(false)
    },1500)
  );
  }, []);
  return load?(
    <StartLoad />

  ) :isLoged ? (
    <>
      <Menu></Menu>
    </>
  ) : (
    <Login />
    
  );
};
export default App;


