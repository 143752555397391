import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoPlanners, Eliminar } from "../Funciones/Planners";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import "../../../Lib/Funciones Generales/Prototipos";
const MySwal = withReactContent(Swal);

const Planners = () => {
  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img  });
  };

  //Definicion de variables para el formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
    telefono: "",
    id_admin: "1",
    activo: "1",
    img: AccEditarImagen,
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    apellido_paterno: false,
    correo: false,
    telefono: false,
    id_admin: false,
    activo: false,
    img: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    if (Name == "telefono") {
      Value = Value.toNumber();
    }

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoPlanners(id, AccEditarImagen)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarPlanners = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            console.log("I was closed by the timer");
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Planners
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar planner" : "Nuevo planner"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col
                sm={12}
                md={12}
                lg={4}
                className="d-flex flex-wrap align-items-center justify-content-center"
              >
                <Avatar
                  className="shadow"
                  sx={{ width: 120, height: 120 }}
                  src={img_url}
                />
                <strong
                  className="w-100 text-center px-0 pt-3"
                  style={{ color: "#3ABE88", cursor: "pointer" }}
                  onClick={handleOpenModal}
                >
                  Editar foto
                </strong>
              </Col>

              <Col sm={12} md={4} className="p-3 mt-5">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  helperText={Errores.correo ? "Nombre incorrecto" : ""}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3 mt-5">
                <TextField
                  fullWidth
                  name="apellido_paterno"
                  label="Apellido paterno"
                  variant="outlined"
                  value={Values.apellido_paterno}
                  error={Errores.apellido_paterno}
                  helperText={Errores.correo ? "Apellido paterno incorrecto" : ""}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="apellido_materno"
                  label="Apellido materno"
                  variant="outlined"
                  value={Values.apellido_materno}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="correo"
                  label="Correo electrónico"
                  variant="outlined"
                  helperText={Errores.correo ? "Correo incorrecto" : ""}
                  value={Values.correo}
                  error={Errores.correo}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="telefono"
                  label="Teléfono"
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  helperText={Errores.telefono ? "Teléfono incorrecto" : ""}
                  value={Values.telefono}
                  error={Errores.telefono}
                  onChange={handlInputChange}
                />
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarPlanners()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_PLANNER}
          Titulo="Cargar imagen de planner"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Planners;
