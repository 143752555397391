import {  Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import Planners from "./Componentes/Planners";
import RepPlanners from "./Componentes/RepPlanners";

 

 const routerPlanners =()=> {
  return (
    <> 
     <Routes>     
        <Route index element={<RepPlanners/>} /> 
        <Route path="/add" element={<Planners/>} /> 
        <Route path="/edit/:id" element={<Planners/>} /> 
        <Route path="*" element={<Error404 />} />
    </Routes>
    </> 
  );
}

export default routerPlanners;